<template>
  <div class="progress-circle">
    <svg class="progress-circle__svg" viewBox="0 0 36 36">
      <circle class="progress-circle__bg" cx="18" cy="18" r="15.9155" />
      <circle class="progress-circle__progress" cx="18" cy="18" r="15.9155" :style="progressStyle" />
    </svg>
    <div v-if="showProgressLabel" class="progress-circle__infos">
      <span class="progress-circle__label">Progresso</span>
      <span class="progress-circle__label">
        <toggle-currency-visibility :value="actualValue" />
        ({{ currentProgress }})
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch } from 'vue';
import ToggleCurrencyVisibility from '../ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
  components: { ToggleCurrencyVisibility },
  props: {
    totalValue: [Number, String],
    currentValue:  [Number, String],
    showProgressLabel: Boolean
  },
  setup(props) {
    const state = reactive({
      currentValueModel: props.currentValue,
      totalValueModel: props.totalValue
    });

    watch(() => props.currentValue, (value) => state.currentValueModel = value);
    watch(() => props.totalValue, (value) => state.totalValueModel = value);

    const getPercentValue = computed({
      get() {
        return Number(state.totalValueModel) !== 0 ? (state.currentValueModel / state.totalValueModel * 100).toFixed(2) : 0
      }
    })

    const currentProgress = computed(() => {
      return `${getPercentValue.value}%`;
    });

    const progressStyle = computed(() => {
      let formattedPercent = getPercentValue.value;

      if(formattedPercent > 100) {
        formattedPercent = 100;
      }

      if(formattedPercent < 0) {
        formattedPercent = 0;
      }

      const circumference = 2 * Math.PI * 15.9155;
      const offset = circumference - (formattedPercent / 100) * circumference;
      return {
        strokeDasharray: circumference,
        strokeDashoffset: offset
      };
    });

    const actualValue = computed(() => state.currentValueModel);

    return {
      progressStyle,
      currentProgress,
      actualValue
    };
  }
}
</script>

<style lang="scss" src="./ProgressCircle.scss" />