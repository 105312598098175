<template>
    <td class="planning-transactions-list-row-mobile__column" id="description">
        {{ transactionData.description }}
        <br>
        <span class="planning-transactions-list-row-mobile__date">{{ formatDate(transactionData.date) }}</span>
    </td>
    <td class="planning-transactions-list-row-mobile__column" id="value">
        {{ formatCurrencyValue(Number(transactionData.value)) }}
    </td>
</template>

<script>
import { formatCurrency } from "@/services/currencyService";
import { formatFullDate } from "@/services/formatService";

export default {
    props: {
        transactionData: Object
    },
    setup() {
        const formatDate = (date) => {
            return formatFullDate(new Date(date))
        }

        const formatCurrencyValue = (value) => {
            return formatCurrency(value);
        };

        return {
            formatDate,
            formatCurrencyValue
        };
    }
}
</script>

<style lang="scss" src="./PlanningTransactionsListRowMobile.scss" />
