<template>
  <div class="box-information" :class="boxInformationType">
    <p class="box-information__title">
      {{ title }}
    </p>
    <div class="box-information__messages">
      <span
        v-for="(message) in messages"
        :key="message"
        class="box-information__message"
      >
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    type: String,
    title: String,
    messages: Array,
  },
  setup(props) {
    const boxInformationType = computed(() => {
      const type = props.type;

      if (type === "error") {
        return "box-information__error";
      }
      if (type === "warning") {
        return "box-information__warning";
      }
      if (type === "success") {
        return "box-information__success";
      }
      return "box-information__info";
    });

    return {
      boxInformationType,
    };
  },
};
</script>

<style lang="scss" src="./BoxInformation.scss" />
