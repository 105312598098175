import { defineStore } from 'pinia';

const currentDate = new Date();

export const usePlanningStore = defineStore({
    id: 'planningData',
    state: () => ({
        planningData: {
            type: 1,
            startDate: new Date(currentDate.getFullYear(), currentDate.getUTCMonth(), 1),
            endDate: new Date(currentDate.getFullYear(), currentDate.getUTCMonth() + 1, 0)
        }
    }),
    getters: {
        getPlanningData: (state) => {
            return state.planningData
        },
    },
    actions: {
        setPlanningData(data) {
            this.planningData = {
                ...this.planningData,
                ...data
            };
        },
    }
})
