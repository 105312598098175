<template>
  <div class="dropdown-button" ref="dropdownRef" v-tooltip="getDisabledTooltip">
    <button
      @click="onDropdownToggle"
      class="dropdown-button__btn btn"
      :class="btnClass"
      type="button"
      data-toggle="dropdown"
      :disabled="btnDisabled"
    >
      <div class="dropdown-button__label label">
        <fa-icon v-if="btnIconLabel" :icon="btnIconLabel" />
        {{ label }}
      </div>

      <fa-icon class="icon-toggle" icon="fa-solid fa-chevron-down" />
    </button>
    <ul
      class="dropdown-button__list"
      ref="dropdownOptionsRef"
      :class="{ 'dropdown-button__list--show': state.showList }"
      :style="floatingStyles"
    >
      <slot name="options"></slot>
    </ul>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useFloating, autoUpdate } from '@floating-ui/vue';
import { size, autoPlacement } from '@floating-ui/dom';

export default {
  props: {
    label: String,
    primary: Boolean,
    iconLabel: String,
    size: {
      type: String,
      default: null,
    },
    disabled: Boolean,
    disabledTooltip: String
  },
  setup(props) {
    const state = reactive({
      showList: false,
    });

    const dropdownRef = ref(null);
    const dropdownOptionsRef = ref(null);
    onClickOutside(dropdownRef, () => close());

    const { floatingStyles } = useFloating(dropdownRef, dropdownOptionsRef, {
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      placement: 'bottom',
      transform: true,
      middleware: [
        autoPlacement({
          allowedPlacements: ['bottom']
        }),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        })
      ]
    });

    const btnClass = computed({
      get() {
        const style = props.primary ? "button-primary" : "button-outline";
        const isOpen = state.showList ? 'opened' : ''
        return `${props.size} ${style} ${isOpen}`;
      },
    });

    const getDisabledTooltip = computed({
      get() {
        return props.disabled ? props.disabledTooltip : ''
      }
    })

    const btnDisabled = computed({
      get() {
        return props.disabled
      }
    })

    const btnIconLabel = computed({
      get() {
        return `fa-solid ${props.iconLabel}`
      }
    })

    const onDropdownToggle = () => {
      state.showList = !state.showList;
    };

    const close = () => {
      state.showList = false;
    };

    return {
      state,
      btnClass,
      dropdownRef,
      dropdownOptionsRef,
      btnIconLabel,
      btnDisabled,
      getDisabledTooltip,
      floatingStyles,
      onDropdownToggle,
    };
  },
};
</script>

<style lang="scss" src="./DropdownButton.scss" />
