<template>
  <div class="financial">
    <page-header title="Receitas">
      <transactions-page-filters :type="transactionsType"/>
      <button @click="openTransactionModal()" id="add-transaction" class="btn button-primary button-primary--success financial__add-button">
        <fa-icon icon="fa-solid fa-plus" />
        Nova receita
      </button>
    </page-header>
    <div class="financial__content">
      <box-page title="Balanço do período" id="revenues-balance">
        <template #title>
          <FiltersBadgeList />
        </template>
        <cards-resume-revenues/>
      </box-page>
      <box-page title="Resumo das transações" id="transactions-list">
        <template #title>
          <FiltersBadgeList />
        </template>
        <div class="financial__transactions-list">
          <transactions-table :type="transactionsType"/>
        </div>
      </box-page>
    </div>
  </div>
</template>

<script>
import { onBeforeMount } from 'vue';
import { useModal } from "vue-final-modal";
import { TRANSACTION_TYPES } from '../constants/transactionConstants';
import { eventBus } from "@/services/eventBusService";
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import TransactionsTable from "../components/TransactionsTable/TransactionsTable.vue";
import CardsResumeRevenues from "../components/CardsResumeRevenues/CardsResumeRevenues.vue";
import CreateTransactionModal from "../components/CreateTransactionModal/CreateTransactionModal.vue";
import TransactionsPageFilters from '../components/TransactionsPageFilters/TransactionsPageFilters.vue';
import FiltersBadgeList from '../components/FiltersBadgeList/FiltersBadgeList.vue';

export default {
  components: {
    BoxPage,
    PageHeader,
    TransactionsTable,
    CardsResumeRevenues,
    TransactionsPageFilters,
    FiltersBadgeList
  },
  setup() {
    const transactionsType = TRANSACTION_TYPES.REVENUE.code;
    const filtersStore = useTransactionsFiltersStore();

    onBeforeMount(() => {
      filtersStore.resetFilters();
    })

    const { open: openTransactionModal, close: closeTransactionModal } =
      useModal({
        component: CreateTransactionModal,
        attrs: {
          title: "Nova receita",
          type: TRANSACTION_TYPES.REVENUE.code,
          onClose() {
            closeTransactionModal();
          },
          onConfirm() {
            closeTransactionModal();
          },
          onCancel() {
            closeTransactionModal();
          },
          onSuccess() {
            eventBus.emit('reloadData')
          }
        },
      });

    return {
      transactionsType,
      openTransactionModal,
    };
  },
};
</script>

<style lang="scss" src="./styles/FinancialView.scss" />
