<template>
  <div class="register-form">
    <div class="register-form__header">
      <h3 class="register-form__title">Insira seus dados e crie sua conta</h3>
    </div>
    <div class="register-form__content">
      <form autocomplete="off" method="post" @submit.prevent>
        <input type="text" class="hidden-input" name="name" autocomplete="name">
        <input type="text" class="hidden-input" name="email" autocomplete="name">
        <input type="password" class="hidden-input" name="password" autocomplete="name">

        <custom-input label="Nome" v-model="state.name" required name="unique-name" autocomplete="off" />
        <custom-input label="E-mail" v-model="state.email" required name="unique-email"
          :custom-validations="state.emailValidations" autocomplete="off" />
        <password-input @setPassword="setPassword" />
        <div class="register-form__terms">
          <input v-model="state.acceptTerms" type="checkbox" id="terms" name="terms" required>
          <span>Li e aceito os <a @click="openTermsModal">termos de uso e políticas de privacidade</a> do site</span>
        </div>
        <div class="register-form__terms">
          <input v-model="state.allowsCommunication" type="checkbox" id="communications" name="communications">
          <span>Aceito receber comunicações de novidades e promoções</span>
        </div>
        <div class="register-form__actions">
          <request-button label="Registrar" :fetch-action="submitForm" @fetch-success="onRegisterSuccess"
            @fetch-error="onRegisterError" type="submit" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { register } from "../../services/userService";
import { emailValidator } from "@/services/validationsService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useModal } from "vue-final-modal";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { validateRecaptcha } from "@/modules/common/services/recaptcha/recaptchaService";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import PasswordInput from "@/components/PasswordInput/PasswordInput.vue";
import UseTermsModal from '../UseTermsModal/UseTermsModal.vue';
import RequestButton from '@/components/RequestButton/RequestButton.vue';

export default {
  components: { CustomInput, PasswordInput, RequestButton },
  props: {},
  setup(props, { emit }) {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    
    const state = reactive({
      name: null,
      email: null,
      password: null,
      confirmPassword: null,
      passwordError: null,
      acceptTerms: false,
      allowsCommunication: true,
      emailValidations: [
        {
          message: "E-mail inválido",
          valid: (email) => emailValidator(email),
        },
      ],
    });

    const isFormValid = computed({
      get() {
        return (
          state.name &&
          state.email &&
          state.password &&
          state.confirmPassword &&
          state.acceptTerms
        );
      },
    });

    const submitForm = () => {
      if (!isFormValid.value) {
        return;
      }

      return submitRecaptcha();
    }

    const submitRecaptcha = async () => {
      await recaptchaLoaded()
      const recaptchaAction = 'register';
      const token = await executeRecaptcha(recaptchaAction);
      const isValid = await validateRecaptcha({ token, recaptchaAction });

      if (isValid) {
        return handleRegister();
      }

      return Promise.reject();
    }

    const handleRegister = () => {
      if (!isFormValid.value) {
        return;
      }

      const formData = {
        name: state.name,
        email: state.email,
        password: state.password,
        passwordConfirmation: state.confirmPassword,
        allowsCommunication: state.allowsCommunication
      };

      register(formData);
    };

    const onRegisterSuccess = () => {
      sendPixelTrack("RegisterCompleted");
      showSuccessToast("Cadastro efetuado com sucesso, faça o login!");
      emit("show-login");
    };

    const onRegisterError = () => {
      showErrorToast("Ocorreu um erro ao se registrar. Tente novamente.");
    };

    const setPassword = (password) => {
      state.password = password;
      state.confirmPassword = password;
    };

    const { open: openTermsModal, close: closeTermsModal } = useModal({
      component: UseTermsModal,
      attrs: {
        onClose() {
          closeTermsModal()
        },
        onConfirm() {
          closeTermsModal()
        },
        onCancel() {
          closeTermsModal()
        },
      }
    });

    return {
      state,
      setPassword,
      openTermsModal,
      submitForm,
      onRegisterSuccess,
      onRegisterError
    };
  },
};
</script>

<style lang="scss" src="./RegisterForm.scss" />
