import requestService from '@services/requestService'

const ACCOUNT_URL = '/account'

export function register(userData) {
    return requestService.post(`${ACCOUNT_URL}/create-user`, userData);
}

export function getUserData() {
    return requestService.get(`${ACCOUNT_URL}/read-user`);
}

export function updateUser(userData) {
    return requestService.put(`${ACCOUNT_URL}/update-user`, userData);
}

export function changePassword(passwordData) {
    return requestService.post(`${ACCOUNT_URL}/change-password`, passwordData);
}

export function getRecoveryPasswordCode(userEmail) {
    return requestService.post(`${ACCOUNT_URL}/forgotten-password`, userEmail);
}

export function checkPasswordToken(data) {
    return requestService.post(`${ACCOUNT_URL}/check-token`, data);
}

export function resetPassword(data) {
    return requestService.post(`${ACCOUNT_URL}/reset-password`, data);
}
