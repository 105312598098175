import { setLocalStorageItem } from "@/services/localStorageService";
import { HAS_TOUR_LIST, TOURS_MAP } from "../constants/toursStepsConstants";
import { driver } from "driver.js";
import { isMobile } from "@/services/deviceService";

export function initTour(steps, storageKey) {
    const driverObj = driver({
        showProgress: true,
        nextBtnText: 'Próximo',
        prevBtnText: 'Anterior',
        doneBtnText: 'Fnializar',
        progressText: '{{current}} de {{total}}',
        steps: steps,
        onDestroyed: function () {
            if (storageKey) {
                setLocalStorageItem(storageKey, true)
            }
        },
        onHighlightStarted: (element, step) => {
            if (isMobile() && (step.element === '.side-menu' || step.element === '.install-app-banner')) {
                const menuButton = document.querySelector('.navbar__hamburguer');
                if (menuButton) {
                    menuButton.click();
                }
            }
        }
    });

    return driverObj
}

export function verifyTourRoute(route) {
    return HAS_TOUR_LIST.includes(route)
}

export function getTourData(route) {
    return TOURS_MAP[route]
}
