export const PLANNING_TRANSACTIONS_LIST_COLUMNS = Object.freeze([
    {
        name: "Descrição",
        key: "description",
        sortable: true,
    },
    {
        name: "Data",
        key: "date",
        sortable: true,
    },
    {
        name: "Valor (R$)",
        key: "value",
        sortable: true,
    },
]);

export const PLANNING_TRANSACTIONS_LIST_COLUMNS_MOBILE = Object.freeze([
    {
        name: "Descrição",
        key: "description",
        sortable: true,
    },
    {
        name: "Valor (R$)",
        key: "value",
        sortable: true,
    },
]);

export const PLANNING_LIMIT_REACHED = Object.freeze({
    title: 'Você atingiu o limite de planejamentos em sua conta gratuita.',
    description: 'Adquira nosso plano Premium, crie planejamentos ilimitados e tenha diversas outras vantagens exclusivas.'
})

export const PLANNING_DISABLED_CREATE_CUSTOM = Object.freeze({
    title: 'Os planejamentos personalizados estão disponíveis exclusivamente para usuários Premium.',
    description: 'Adquira nosso plano Premium, crie planejamentos ilimitados e tenha diversas outras vantagens exclusivas.'
})