<template>
  <div class="collapse">
    <div class="collapse__header" @click="toggleCollapse">
      <p class="collapse__title">{{ title }}</p>
      <fa-icon :icon="toggleIcon" />
    </div>
    <div
      class="collapse__body"
      :class="{ 'collapse__body--opened': state.openCollapse }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
export default {
  props: {
    title: String,
    opened: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      openCollapse: false,
    });

    const toggleCollapse = () => {
      state.openCollapse = !state.openCollapse;
      emit('toggle-collapse', state.openCollapse);
    };
    
    const toggleIcon = computed({
      get() {
        return state.openCollapse
          ? "solid fa-chevron-up"
          : "solid fa-chevron-down";
      },
    });

    return {
      state,
      toggleIcon,
      toggleCollapse,
    };
  },
};
</script>

<style lang="scss" src="./Collapse.scss" />
