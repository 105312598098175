<template>
  <div class="transaction-category">
    <fa-icon :style="iconColor" :icon="`solid ${category.icon}`"/>
    {{ category.name }}
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: {
        category: Object
    },
    setup(props) {
      const iconColor = computed(() => ({
        color: props.category.color,
      }));

      return {
        iconColor
      }
    }
}
</script>

<style lang="scss" src="./TransactionCategory.scss" />
