<template>
  <div class="user-settings-view">
    <div class="user-settings-view__content">
      <BoxPage title="Dados da conta">
        <AccountDataForm />
      </BoxPage>
      <BoxPage title="Alterar senha">
        <ChangePasswordForm />
      </BoxPage>
    </div>
  </div>
</template>

<script>
import AccountDataForm from "../components/AccountDataForm/AccountDataForm.vue";
import ChangePasswordForm from '../components/ChangePasswordForm/ChangePasswordForm.vue';
import BoxPage from "@/components/BoxPage/BoxPage"

export default {
  components: { BoxPage, AccountDataForm, ChangePasswordForm },
};
</script>

<style lang="scss" src="./styles/UserSettingsView.scss" />
