<template>
    <div
      class="currency-input form-group"
      :class="[
        hasError ? 'currency-input__with-error' : '',
        disabled ? 'currency-input__disabled' : '',
      ]"
    >
      <label class="currency-input__label" :for="name"
        >{{ label }}
        <span v-if="required" class="currency-input__required">*</span>
      </label>
      <money3
        class="currency-input__input form-control"
        v-bind="state.config"
        :id="name"
        :name="name"
        v-model="state.modelValue"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        @blur="onBlur"
        @change="onChange"
      />
      <span
        v-if="state.errorMessage || messageError"
        class="currency-input__error"
        >{{ state.errorMessage || messageError }}</span
      >
    </div>
  </template>
  
  <script>
  import { computed, reactive, watch } from "vue";
  
  export default {
    props: {
      label: String,
      name: String,
      value: [String, Number, Object, Array],
      type: {
        type: String,
        default: "text",
      },
      required: Boolean,
      disabled: Boolean,
      customValidations: Array,
      messageError: String,
      precision: {
        type: Number,
        default: 2
      },
      autocomplete: {
        type: String,
        default: 'on'
      },
      placeholder: {
        type: String,
        default: '0,00'
      }
    },
    setup(props, { emit }) {
      const state = reactive({
        modelValue: props.value,
        errorMessage: null,
        config: {
            masked: false,
            thousands: '.',
            decimal: ',',
            precision: props.precision,
            shouldRound: false,
            disableNegative: true,
            disabled: props.disabled,
            allowBlank: true
        }
      });
  
      const onBlur = () => {
        if (props.required && !state.modelValue) {
          state.errorMessage = "Este campo é obrigatório.";
        } else {
          validate();
        }
  
        emit("blur");
      };
  
      watch(
        () => props.value,
        (newValue) => {
          state.modelValue = newValue;
        }
      );
  
      const onChange = () => {
        emit("update:modelValue", Number(state.modelValue));
      };
  
      const validate = () => {
        state.errorMessage = null;
  
        const validations = props.customValidations;
        if (!validations) {
          return;
        }
  
        for (const validation of validations) {
          const validationResult = validation.valid(state.modelValue);
  
          if (validationResult !== true) {
            state.errorMessage = validation.message;
            break;
          }
        }
      };
  
      const hasError = computed(() => {
        return state.errorMessage || props.messageError;
      });
  
      return {
        state,
        onBlur,
        onChange,
        hasError,
      };
    },
  };
  </script>
  
  <style lang="scss" src="./CurrencyInput.scss" />
  