<template>
    <div class="edit-goal-form">
        <div class="edit-goal-form__content">
            <form action="#" @submit.prevent>
                <custom-input :value="state.goal.name" label="Nome" v-model="state.goal.name" required name="name"
                    autocomplete="off" maxlength="100"/>
                <currency-input label="Valor total do objetivo (R$)" :value="state.goal.value"
                    v-model="state.goal.value" required name="goaValue" />
                <div class="form-group">
                    <label for="data-input">Data final</label>
                    <datepicker v-model="state.goal.date" :value="state.goal.date" name="data-input"/>
                </div>
                <div class="edit-goal-form__goal-styles">
                    <div class="form-group">
                      <label for="color-picker">Cor</label>
                      <color-picker name="color-picker" :color="state.goal.color" v-model="state.goal.color"/>
                    </div>
                    <select-icon-modal @select-icon="onSelectIcon" :icon="state.goal.icon"/>
                </div>
                <div class="edit-goal-form__actions">
                    <request-button class="edit-goal-form__submit" label="Salvar" :fetch-action="submitForm"
                        @fetch-success="onUpdateGoalSuccess" @fetch-error="onUpdateGoalError" type="submit" />
                    <button class="btn button-primary edit-goal-form__action"
                        @click="openCreateGoalDepositModal">
                        <fa-icon v-if="isMobileDevice" icon="fa-solid fa-plus" />
                        <span v-else>Aplicar</span>
                    </button>
                    <button class="btn button-primary edit-goal-form__action" @click="openCreateGoalWithdrawModal">
                        <fa-icon v-if="isMobileDevice" icon="fa-solid fa-minus" />
                        <span v-else>Resgatar</span>
                    </button>
                    <button class="btn button-primary button-primary--alert edit-goal-form__action edit-goal-form__delete"
                        @click="openDeletionConfirmModal">
                        <fa-icon icon="fa-solid fa-trash" />
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed } from "vue";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { updateGoal, removeGoal } from "../../services/goal/goalService";
import { useModal } from "vue-final-modal";
import { isMobile } from '@/services/deviceService';
import { useRouter } from "vue-router";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import CurrencyInput from "@/components/CurrencyInput/CurrencyInput.vue";
import RequestButton from "@/components/RequestButton/RequestButton.vue";
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';
import CreateGoalDepositModal from '../CreateGoalDepositModal/CreateGoalDepositModal.vue';
import CreateGoalWithdrawModal from '../CreateGoalWithdrawModal/CreateGoalWithdrawModal.vue';
import SelectIconModal from '@/components/SelectIconModal/SelectIconModal.vue';
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";
import ColorPicker from "@/components/ColorPicker/ColorPicker.vue";

export default {
    components: {
        CustomInput,
        CurrencyInput,
        RequestButton,
        SelectIconModal,
        Datepicker,
        ColorPicker
    },
    props: {
        goalData: Object,
    },
    setup(props, { emit }) {
        const router = useRouter();
        const state = reactive({
            goal: props.goalData,
        });

        const isFormValid = computed({
            get() {
                return state.goal.name && state.goal.value && state.goal.date;
            },
        });

        const submitForm = () => {
            if (!isFormValid.value) {
                return;
            }

            return updateGoal(state.goal.id, state.goal);
        };

        const onUpdateGoalSuccess = () => {
            showSuccessToast(`Alterações salvas com sucesso!`);
            emit("success");
            onClose();
        };

        const onUpdateGoalError = () => {
            showErrorToast("Ocorreu um erro ao salvar o objetivo. Tente novamente.");
        };

        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const onSelectIcon = (icon) => {
            state.goal.icon = icon;
        };

        const deleteGoal = () => {
            const id = props.goalData?.id;
            removeGoal(id)
                .then(onDeleteGoalSuccess)
                .catch(onDeleteGoalError);
        }

        const onDeleteGoalSuccess = () => {
            showSuccessToast("Objetivo excluído com sucesso!");
            router.push("/finance/goals");
        }

        const onDeleteGoalError = () => {
            showErrorToast('Ocorreu um erro ao excluir o objetivo. Tente novamente.');
        }

        const { open: openDeletionConfirmModal, close: closeDeletionConfirmModal } = useModal({
            component: ConfirmationModal,
            attrs: {
                buttonType: 'alert',
                title: 'Excluir objetivo',
                description: `Tem certeza de que deseja exluir o objetivo ${props.goalData?.name}?`,
                confirmLabel: 'Excluir',
                onClose() {
                    closeDeletionConfirmModal()
                },
                onConfirmAction() {
                    deleteGoal()
                    closeDeletionConfirmModal()
                },
                onCancel() {
                    closeDeletionConfirmModal()
                },
            }
        });

        const { open: openCreateGoalDepositModal, close: closeCreateGoalDepositModal } = useModal({
            component: CreateGoalDepositModal,
            attrs: {
                goalId: computed(() => props.goalData.id),
                onClose() {
                    closeCreateGoalDepositModal()
                },
                onConfirm() {
                    closeCreateGoalDepositModal()
                },
                onSuccess() {
                    emit('refresh-data')
                },
                onCancel() {
                    closeCreateGoalDepositModal()
                },
            }
        });

        const { open: openCreateGoalWithdrawModal, close: closeCreateGoalWithdrawModal } = useModal({
            component: CreateGoalWithdrawModal,
            attrs: {
                goalId: computed(() => props.goalData.id),
                onClose() {
                    closeCreateGoalWithdrawModal()
                },
                onConfirm() {
                    closeCreateGoalWithdrawModal()
                },
                onSuccess() {
                    emit('refresh-data')
                },
                onCancel() {
                    closeCreateGoalWithdrawModal()
                },
            }
        });

        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        return {
            state,
            isFormValid,
            isMobileDevice,
            onCancel,
            onClose,
            onSelectIcon,
            submitForm,
            onUpdateGoalSuccess,
            onUpdateGoalError,
            openDeletionConfirmModal,
            openCreateGoalDepositModal,
            openCreateGoalWithdrawModal
        };
    },
};
</script>

<style lang="scss" src="./EditGoalForm.scss" />
