import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faAward,
    faBars,
    faBellConcierge,
    faBolt,
    faBriefcase,
    faBuilding,
    faBullseye,
    faBurger,
    faBus,
    faCalendar,
    faCar,
    faCarrot,
    faCartShopping,
    faCashRegister,
    faCat,
    faChartLine,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleCheck,
    faClipboardList,
    faCoffee,
    faCoins,
    faCreditCard,
    faCrown,
    faDatabase,
    faDog,
    faDollarSign,
    faEquals,
    faEye,
    faEyeSlash,
    faFerry,
    faFilter,
    faGamepad,
    faGasPump,
    faGift,
    faGraduationCap,
    faHandHoldingDollar,
    faHeadset,
    faHouse,
    faInfinity,
    faKey,
    faMagnifyingGlass,
    faMinus,
    faMoneyBill,
    faMoneyBillTrendUp,
    faMoneyCheck,
    faMotorcycle,
    faNotesMedical,
    faPenToSquare,
    faPhone,
    faPiggyBank,
    faPlane,
    faPlus,
    faQuestion,
    faReceipt,
    faRotate,
    faSchool,
    faShip,
    faShop,
    faStar,
    faStore,
    faSuitcaseMedical,
    faTicket,
    faTrash,
    faTv,
    faUmbrellaBeach,
    faUserDoctor,
    faUserGraduate,
    faUtensils,
    faVideo,
    faWallet,
    faWifi,
    faXmark,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faArrowLeft,
    faArrowRight,
    faArrowRightFromBracket,
    faArrowRightToBracket,
    faAward,
    faBars,
    faBellConcierge,
    faBolt,
    faBriefcase,
    faBuilding,
    faBullseye,
    faBurger,
    faBus,
    faCalendar,
    faCar,
    faCarrot,
    faCartShopping,
    faCashRegister,
    faCat,
    faChartLine,
    faChartPie,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleCheck,
    faClipboardList,
    faCoffee,
    faCoins,
    faCreditCard,
    faCrown,
    faDatabase,
    faDog,
    faDollarSign,
    faEquals,
    faEye,
    faEyeSlash,
    faFerry,
    faFilter,
    faGamepad,
    faGasPump,
    faGift,
    faGraduationCap,
    faHandHoldingDollar,
    faHeadset,
    faHouse,
    faInfinity,
    faKey,
    faMagnifyingGlass,
    faMinus,
    faMoneyBill,
    faMoneyBillTrendUp,
    faMoneyCheck,
    faMotorcycle,
    faNotesMedical,
    faPenToSquare,
    faPhone,
    faPiggyBank,
    faPlane,
    faPlus,
    faQuestion,
    faReceipt,
    faRotate,
    faSchool,
    faShip,
    faShop,
    faStar,
    faStore,
    faSuitcaseMedical,
    faTicket,
    faTrash,
    faTv,
    faUmbrellaBeach,
    faUserDoctor,
    faUserGraduate,
    faUtensils,
    faVideo,
    faWallet,
    faWifi,
    faXmark,
)
