import { useToast } from "vue-toastification";

export function showSuccessToast(message, options = {}) {
    const toast = useToast();
    const defaultOptions = {
        duration: 5000, // 5 seconds
        // outras opções padrão aqui, se necessário
    };
    toast.success(message, { ...defaultOptions, ...options });
}

export function showErrorToast(message, options = {}) {
    const toast = useToast();
    const defaultOptions = {
        duration: 5000, // 5 seconds
        // outras opções padrão aqui, se necessário
    };
    toast.error(message, { ...defaultOptions, ...options });
}