<template>
    <div class="privacy-policy terms">
        <p>Esta Política de Privacidade (“Política”), juntamente com os Termos de Uso, descrevem as políticas e
            condições aplicáveis aos serviços prestados pela Calculando Riquezas por meio da Plataforma (conforme abaixo
            definido) e seu aplicativo.</p>
        <p>Esta política descreve as formas como coletamos, armazenamos e protegemos os Dados Pessoais dos usuários da
            Plataforma e do aplicativo.</p>
        <p>A presente Política abrange informações relevantes sobre o serviço prestados pela Calculando Riquezas, como o
            pertinente detalhamento sobre a proteção de Dados Pessoais (conforme abaixo definido).</p>
        <p>O usuário dos nossos serviços pode a qualquer momento deixar de receber comunicações da nossa Plataforma.
            Para tanto, simplesmente clicar nos links “remover” ou “descadastramento”, contido ao final de cada e-mail
            enviado aos Usuários ou enviar um e-mail para o nosso suporte contato@calculandoriquezas.com.br indicando o
            seu desejo de não mais receber comunicações.
        </p>
        <h4>Definições</h4>
        <p>Quando usados na presente Política, os referidos termos terão os seguintes significados:</p>
        <ul>
            <li>“Dados Pessoais” – significa toda informação relacionada a pessoa natural identificada ou identificável.
                Tais dados podem incluir, entre outros, nome, endereço, número de telefone, CPF, número da carteira de
                habilitação e detalhes de transações comerciais pessoais. Os dados pessoais englobam a categoria de
                Dados Pessoais Sensíveis.
            </li>
            <li>“LGPD” – significa a Lei nº 13.709, de 14 de agosto de 2018, conforme alterada.
            </li>
            <li>“Plataforma” – significa o Site, bem como os seus diversos ambientes que o Usuário terá acesso após o
                cadastro ou contratação de um Plano.</li>
            <li>“Política de Privacidade” – significa a presente política de privacidade da Calculando Riquezas,
                disponível em seu Site.</li>
            <li>“Site” – significa o endereço da rede mundial de computadores denominado
                https://calculandoriquezas.com.br/.</li>
            <li>“Usuário” – significa toda pessoa física que acessa a Plataforma, a quem os Dados Pessoais se referem.
            </li>
        </ul>
        <h4>Como coletamos informações</h4>
        <p>Para prestarmos nossos Serviços, coletamos o seu endereço IP e as informações padrão de acesso à web, como o
            tipo do seu navegador e as páginas que acessou em nosso Site. Ao realizar o seu cadastro em nossa
            Plataforma, coletaremos os seguintes tipos de informações: informações de contato – como o seu nome,
            endereço, telefone e e-mail.</p>
        <p>Ao se cadastrar em nossa Plataforma, e selecionar o aceite para receber comunicações e promoções, você
            concordará em receber periodicamente e-mails com conteúdo de qualidade sobre o tema e eventuais ofertas e
            promoções de produtos e serviços. Lembrando que você poderá sair a qualquer instante que desejar ao clicar
            no link no final do seu e-mail onde ocorrerá o cancelamento da sua inscrição na lista e você não mais
            receberá estes e-mails.
        </p>
        <p>Por fim, podemos coletar informações adicionais por meio do contrato realizado pela nossa equipe de suporte
            ao cliente.
        </p>
        <h4>Coleta Automática de Dados - Cookies</h4>
        <p>Quando você acessa nosso Site, incluindo as empresas subcontratadas que realizam o monitoramento dos acessos
            do nosso Site, podemos armazenar ou recuperar informações em seu navegador, seja na forma de cookies e de
            outras tecnologias semelhantes.</p>
        <p>Os cookies permitem a coleta de informações tais como o tipo de navegador, o tempo dispendido no site, as
            páginas visitadas, as preferências de idioma e outros dados de tráfego anônimos. Usamos o termo cookies
            nesta Política de Privacidade para nos referir a todos os arquivos e tecnologias que recolhem informações
            desta forma, incluindo, sem limitação, pixel tags, web beacons e identificadores de dispositivo móvel.
        </p>
        <p>Nosso Site possui os seguintes cookies, de acordo com suas funcionalidades:</p>
        <ul>
            <li>“cookie da sessão”: é aplicável quando você entra em sua conta da Plataforma ou usa os serviços da
                Calculando Riquezas, nos ajudando a reconhecê-lo se visitar várias páginas em nosso Site durante a mesma
                sessão, para que não precisemos solicitar a sua senha em todas as páginas. Depois que você sair ou
                fechar o seu navegador, esse cookie irá expirar e deixará de ter efeito;</li>
            <li>“cookies de segurança”: classificação ampla, os cookies de segurança são responsáveis por coletar
                exclusivamente informações essenciais para a autenticação de Usuários, proteger demais dados, evitar
                acessos indesejados de potenciais malwares e garantir a navegação esperada pela Plataforma;
            </li>
            <li>“cookies de funcionalidade”: essa categoria objetiva aprimorar a experiência do Usuário na Plataforma, e
                são usados exclusivamente para obter informações de uso, como configurações de senha/login, respostas a
                mensagens publicitárias ou de anuência aos termos e qualquer alteração em layout de página, visando não
                ser necessário responder ou alterá-la a cada acesso;</li>
            <li>“cookies para publicidade”: visa extrair informações não sensíveis e de mercado com o objetivo de melhor
                personalizar e direcionar futuras campanhas publicitárias aos Usuários, com o objetivo de entregar
                produtos e serviços alinhados com as expectativas e hábitos de cada um; e
            </li>
            <li>“cookies de desempenho”: categoria muito relevante para manutenção da Plataforma, os cookies voltados
                para desempenho agregam informações codificadas e com anonimato relacionadas à Plataforma, como o
                histórico de acesso do Usuário, origem primária de visitas e número de acessos – tais dados são
                utilizados em estrito cumprimento dos deveres de melhoria e aprimoramento da plataforma, com foco na
                coleta de dados limpa para crescimento.</li>
        </ul>
        <p>Codificamos todos os nossos cookies para que as informações não possam ser interpretadas por quaisquer
            terceiros. Caso não deseje que suas informações sejam coletadas por meio de cookies, há um procedimento
            simples na maior parte dos navegadores, que permite que os cookies sejam automaticamente rejeitados ou
            oferece a opção de aceitar ou rejeitar a transferência de um cookie (ou cookies) específico(s) de um site
            determinado para o seu computador.</p>
        <p>As definições escolhidas pelos Usuários podem afetar a sua experiência de navegação e o funcionamento do
            Site. A Calculando Riquezas não se responsabiliza pelas consequências resultantes do funcionamento limitado
            do Site e/ou da Plataforma, caso isso seja provocado pela desativação de cookies no seu dispositivo.</p>
        <p>O uso de cookies permite a coleta de informações a respeito do tipo de navegador, tempo empreendido no site,
            páginas que foram visitadas, preferências elencadas pelo usuário e outros dados de tráfego, que serão sempre
            mantidos em estrito sigilo. Utilizaremos tais informações de modo a não só aprimorar a experiência do
            usuário, mas também para melhoria de segurança e individualização de eventuais condutas que precisem ser
            apuradas. Todas as informações estatísticas coletadas por meio de cookies servirão, também, para mapear o
            uso e constantemente aprimorar as funcionalidades oferecidas aos Usuários.</p>
        <h4>Armazenamento</h4>
        <p>A Calculando Riquezas armazenará as informações: (i) pelo tempo exigido por lei, observados os prazos
            prescricionais; (ii) pelo tempo necessário a preservar o legítimo interesse da Calculando Riquezas ou
            resguardar o exercício regular de seus direitos em eventual processo judicial, administrativo ou arbitral;
            ou (iii) até o término do tratamento dos Dados Pessoais, que pode ocorrer nas seguintes situações:</p>
        <p>(a) Quando a finalidade pela qual os Dados Pessoais foram coletados for alcançada e/ou quando deixarem de ser
            necessários ou pertinentes ao alcance de tal finalidade;</p>
        <p>(b) Quando solicitado o término do tratamento ou a exclusão do Dado Pessoal pelo Usuário; e
        </p>
        <p>(c) Por determinação legal ou regulatória.</p>
        <p>Ao término do tratamento, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente
            Política de Privacidade, os Dados Pessoais serão eliminados.</p>
        <h4>Segurança</h4>
        <p>Temos o compromisso de manter a segurança dos seus Dados Pessoais. Por isso, a Calculando Riquezas adota
            medidas técnicas, físicas e organizacionais adequadas para evitar incidentes. Por exemplo, adotamos
            criptografia de dados, firewalls de Internet, detecção de intrusões e monitoramento de rede, além de buscar
            contratar apenas prestadores de serviço que se comprometam a atender de forma total e irrestrita as
            exigências legais de proteção de Dados Pessoais.</p>
        <p>Todos os funcionários e prestadores de serviços são obrigados a manter sigilo com relação às informações a
            que tenham acesso em razão dos serviços desempenhados para a Calculando Riquezas, além de cumprir com
            políticas e procedimentos internos de segurança. Ainda, os terceiros com os quais compartilhamos os seus
            Dados Pessoais também são obrigados a manter medidas que garantam a segurança dos seus dados, e estão também
            obrigados a esta Política de Privacidade.</p>

        <h4>Finalidades</h4>
        <p>Nossa finalidade principal ao coletar Dados Pessoais é fornecer a você uma experiência segura, tranquila,
            eficiente e personalizada. Para isso, tratamos os Dados Pessoais para que possamos:</p>
        <ol>
            <li>Fornecer os serviços oferecidos pela Calculando Riquezas e o suporte ao cliente solicitados;</li>
            <li>Possibilitar o contato com o cliente para a tratativa de assuntos relacionados à prestação dos serviços;
            </li>
            <li>Personalizar, avaliar e melhorar nossos serviços, além do conteúdo e do layout do nosso Site;</li>
            <li>Enviar materiais de marketing direcionados, avisos de atualização no serviço e ofertas promocionais com
                base nas suas preferências de comunicação;</li>
            <li>Realizar pesquisa de satisfação e feedback sobre os nossos Serviços e iniciativas;</li>
            <li>Possibilitar o faturamento e a cobrança do cliente; e</li>
            <li>Impedir atividades potencialmente proibidas ou ilegais;</li>
        </ol>
        <p>Por fim, a Calculando Riquezas poderá tratar Dados Pessoais de seus clientes na forma que julgar necessária
            ou adequada, sempre nos termos da LGPD, nos seguintes casos: (a) cumprir com obrigações legais ou
            regulatórias a que a Calculando Riquezas estiver sujeito; (b) cumprir ordem judicial, regulatória ou de
            autoridades competentes, incluindo autoridades fora do país de residência do Usuário; (c) proteger interesse
            da Calculando Riquezas, de seus integrantes e demais clientes; (d) detectar e prevenir fraudes; (e) exercer
            direitos da Calculando Riquezas em processo judicial, administrativo ou arbitral; e (f) outros modos
            permitidos por lei.
        </p>
        <h4>Compartilhamento</h4>
        <p>Sempre em estrita observância à esta Política de Privacidade, a Calculando Riquezas poderá compartilhar Dados
            Pessoais, conforme necessário, nas seguintes circunstâncias:</p>
        <ul>
            <li>Provedores de serviços de sistemas: compartilhamos Dados Pessoais com prestadores de serviços que nos
                oferecem suporte em processos relacionados à gerência e à administração de nossos serviços, inclusive
                para a realização dos tratamentos aqui elencados, como, por exemplo, para fins de gestão de cadastro. As
                categorias de destinatários dos dados incluem provedores de softwares e outras tecnologias da
                informação;</li>
            <li>Demais sociedades pertencentes ao grupo econômico da Calculando Riquezas, bem como sociedades sob
                controle comum, nos limites das finalidades estabelecida nesta Política de Privacidade, as quais poderão
                lhe enviar comunicações de marketing de acordo com os seus serviços; e</li>
            <li>Prestadores de serviços administrativos, financeiros, legais, como prevenção contra fraudes, atividades
                de cobrança, marketing e serviços de tecnologia, entre outros;</li>
            <li>Autoridades administrativas e judiciais: Para cumprir nossas obrigações legais ou regulatórias,
                responder a um processo judicial ou administrativo, auditoria ou solicitações legais por autoridades
                públicas e policiais - em conexão com os serviços contratados.</li>
        </ul>
        <p>Eventuais prestadores de serviços que realizam trabalhos para a Calculando Riquezas, ainda que de forma
            temporária, e tenham acesso a informações dos Usuários estão igualmente obrigados a observar a LGPD e a
            presente Política de Privacidade, sendo que poderão ser responsabilizados caso seja constatada qualquer
            violação a esses normativos.</p>
        <p>Note que esses terceiros podem estar em países nos quais a legislação sobre o processamento de informações
            pessoais seja diferente da existente no Brasil. Nos casos em que nossa empresa e um parceiro promovem juntos
            nossos serviços, nós podemos divulgar ao parceiro algumas informações pessoais tais como nome, endereço e
            nome de usuário das pessoas que se inscreveram nos nossos serviços com resultado da promoção conjunta com o
            único propósito de permitir a nós e ao parceiro avaliar os resultados da promoção. Nesse caso, informações
            pessoais não podem ser usadas pelo parceiro para nenhum outro propósito.</p>

        <h4>Links e Redirecionamentos</h4>
        <p>Nossa Plataforma poderá oferecer hiperlinks de conexão que redirecionarão o Usuário que deliberadamente
            acessá-lo à páginas sob a responsabilidade de terceiros, sem qualquer gerência da Calculando Riquezas. Em
            nenhuma hipótese o acesso a tais páginas e/ou links constitui extensão da responsabilidade da Calculando
            Riquezas nos termos da presente Política de Privacidade, devendo o Usuário acessar o conteúdo das políticas
            do novo site e/ou plataforma antes de compartilhar seus Dados Pessoais.</p>
        <p>De nenhuma forma também a Calculando Riquezas se responsabiliza por quaisquer políticas, termos e/ou práticas
            de coleta e proteção de dados pessoais perpetradas por outras organizações, públicas ou não, e/ou qualquer
            desenvolvedor de software ou provedor de aplicativo que assim o faça, incluindo, sem limitações, a
            divulgação de Dados Pessoais que tais organizações tenham acesso por força exclusiva do Usuário.</p>
        <h4>Software Update e Garantias</h4>
        <p>O Usuário se compromete, por meio da aceitação desta Política, a manter todos os softwares que de alguma
            forma se relacionem à utilização da Plataforma devidamente atualizados, bem como a manter sua versão do
            aplicativo da Calculando Riquezas sempre atualizada, a fim de possibilitar a correção de eventuais erros da
            Plataforma e/ou do aplicativo, otimização de performance, entre outros.</p>
        <p>A presente Plataforma terá seu uso fornecido aos Usuários considerado “o atual estado em que se encontra”,
            considerando que estará sempre em contínuo desenvolvimento visando melhorar a experiência do Usuário, bem
            como a redução de eventuais erros. Assim, a Plataforma poderá conter erros ocasionais e imprecisões, e não
            nos responsabilizamos por qualquer garantia ou suporte, dado que não garante que a operação da Plataforma
            seja ininterrupta ou que não possua períodos de instabilidade.</p>

        <h4>Veiculação</h4>
        <p>O Usuário concorda com a possibilidade de divulgarmos na Plataforma via web ou no aplicativo, via qualquer
            funcionalidade e software pertinente, materiais produzidos com foco em publicidade e serviços eventualmente
            oferecidos por parceiros e/ou disponíveis no mercado financeiro. A divulgação de tais serviços e produtos
            não nos exime de qualquer responsabilidade assumida por esta Política, nem autoriza o compartilhamento de
            dados para além das hipóteses já permitidas e anuídas. Não nos responsabilizamos por quaisquer contratações,
            negócios, compras e dados compartilhados por qualquer eventual contratação dos serviços divulgados, ficando
            a encargo do Usuário decidir sobre a contratação ou utilização do que eventualmente lhe for divulgado ou
            acessado via funcionalidade e direcionamento pela Plataforma e/ou aplicativo.</p>
        <h4>Alterações à Política de Privacidade</h4>
        <p>Esta Política de Privacidade poderá ser revisada e atualizada periodicamente para refletir as alterações em
            nossas práticas de privacidade e proteção de dados. Ao atualizarmos esta Política de Privacidade,
            indicaremos a data da revisão mais recente acima, no preâmbulo. Se fizermos alterações significativas à
            Política de Privacidade, informaremos a você, de forma consistente, sobre as alterações realizadas.
            Recomendamos que você leia a Política de Privacidade frequentemente, para manter-se informado(a) sobre como
            a Calculando Riquezas trata e protege os Dados Pessoais.</p>
        <h4>Canais de Atendimento</h4>
        <p>Todas as eventuais dúvidas ou esclarecimentos relacionados à presente Política de Privacidade poderão ser
            realizadas através do e-mail <a
                href="mailto:contato@calculandoriquezas.com.br">contato@calculandoriquezas.com.br</a>. Nossas equipes
            internas estão prontamente preparadas para resolver qualquer reclamação, sanar dúvidas e absorver quaisquer
            sugestões, de modo que estimulamos e estamos sempre à disposição para resolução consensual entre usuário e
            plataforma.</p>
    </div>
</template>

<style lang="scss" src="./Terms.scss" />
