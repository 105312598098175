<template>
  <div class="planning-categories-list">
    <div class="planning-categories-list__header">
      <h3>Orçamentos</h3>
      <div v-if="allowAdd && !emptyData" class="planning-categories-list__actions">
        <add-button @click="openCreatePlanningCategoryModal" class="btn button-outline" label="Adicionar orçamento" />
      </div>
    </div>
    <div v-if="emptyData" class="planning-categories-list__add-new">
      <p>Você ainda não definiu nenhum orçamento.</p>
      <p>{{ descriptionText }}</p>
      <button v-if="allowAdd" @click="openCreatePlanningCategoryModal" class="btn button-outline">Adicionar
        orçamento</button>
    </div>
    <div v-else class="planning-categories-list__content">
      <div class="planning-categories-list__category-list">
        <planning-category-card v-for="budget in state.budgetsList" :budget-data="budget" :key="budget.categoryId"
          :show-actions="allowAdd" :show-transactions="showTransactions" :category-options="categoryOptions"
          :available-value="currentValueSelected" @delete-budget="onDeleteBudget" @edit-budget="onEditBudget" />
      </div>
    </div>
    <div class="planning-categories-list__"></div>
  </div>
</template>

<script>
import { useModal } from 'vue-final-modal';
import PlanningCategoryCard from '../PlanningCategoryCard/PlanningCategoryCard.vue'
import CreatePlanningCategoryModal from '../CreatePlanningCategoryModal/CreatePlanningCategoryModal.vue';
import { computed, reactive, watch } from 'vue';
import AddButton from '@/components/AddButton/AddButton.vue';

export default {
  components: { PlanningCategoryCard, AddButton },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    categoryOptions: {
      type: Object,
      default: () => { }
    },
    availableCategories: {
      type: Object,
      default: () => { }
    },
    budgetsData: {
      type: Array,
      default: () => []
    },
    planningBudget: [String, Number],
    savingsValue: Number,
    showTransactions: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      budgetsList: props.budgetsData
    })

    watch(() => props.budgetsData, (value) => state.budgetsList = value, { deep: true })

    const emptyData = computed({
      get() {
        return !state.budgetsList?.length
      }
    })

    const descriptionText = computed({
      get() {
        return props.allowAdd ? 'Crie o primeiro orçamento e comece a planejar!' : 'Edite seu planejamento e defina seus orçamentos.'
      }
    })

    const currentValueSelected = computed({
      get() {
        if (!props.planningBudget) {
          return 0;
        }

        const currentValue = state.budgetsList.reduce((acumulador, item) => acumulador + Number(item.value), 0);
        return (props.planningBudget - props.savingsValue) - currentValue;
      }
    });

    const getCategoryData = (id) => {
      return props.availableCategories?.length && props.availableCategories.find(cat => cat.id === id)
    }

    const emitUpdateBudgets = (data) => {
      const budgets = [
        ...state.budgetsList,
        {
          ...data,
          categoryData: {
            ...getCategoryData(data?.categoryId)
          }
        }
      ]

      emit('budgets-updated', budgets);
    }

    const onDeleteBudget = (categoryId) => {
      state.budgetsList = state.budgetsList.filter(budget => budget.categoryId !== categoryId);

      emit('budgets-updated', state.budgetsList);
    };

    const onEditBudget = (data) => {
      state.budgetsList = state.budgetsList.map(budget => {
        if (budget.categoryId === data.categoryId) {
          return { ...budget, ...data };
        }
        return budget;
      });

      emit('budgets-updated', state.budgetsList);
    };

    const { open: openCreatePlanningCategoryModal, close: closeCreatePlanningCategoryModal } = useModal({
      component: CreatePlanningCategoryModal,
      attrs: {
        categoryOptions: computed(() => props.availableCategories),
        availableValue: computed(() => currentValueSelected.value),
        onClose() {
          closeCreatePlanningCategoryModal()
        },
        onAddBudget(data) {
          emitUpdateBudgets(data)
          closeCreatePlanningCategoryModal()
        },
        onCancel() {
          closeCreatePlanningCategoryModal()
        }
      }
    });

    return {
      state,
      emptyData,
      currentValueSelected,
      descriptionText,
      openCreatePlanningCategoryModal,
      onDeleteBudget,
      onEditBudget
    }
  }
}
</script>

<style lang="scss" src="./PlanningCategoriesList.scss" />
