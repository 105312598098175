<template>
  <div class="plans-details-view">
    <box-page>
      <div>
        <div class="plans-details-view__header">
          <h1 class="plans-details-view__title">Aproveite mais com Premium</h1>
          <p class="plans-details-view__subtitle">Assinantes podem desfrutar de diversas vantagens.</p>
        </div>
        <div class="plans-details-view__cards">
          <plan-card />
        </div>
      </div>
    </box-page>
    <box-page>
      <div class="plans-details-view__header">
        <h1 class="plans-details-view__title">Conheça os detalhes dos nossos planos</h1>
      </div>
      <plans-details-carrousel v-if="isMobileDevice" />
      <plans-details-table v-else />
    </box-page>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted } from 'vue';
import { isMobile } from '@/services/deviceService'
import { useUserDataStore } from "@/store/userData";
import { useRouter } from "vue-router";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import BoxPage from '@/components/BoxPage/BoxPage.vue';
import PlansDetailsTable from '../components/PlansDetailsTable.vue';
import PlanCard from '../components/PlanCard.vue';
import PlansDetailsCarrousel from '../components/PlansDetailsCarrousel.vue';

export default {
  components: { BoxPage, PlansDetailsTable, PlanCard, PlansDetailsCarrousel },
  setup() {
    const userStore = useUserDataStore();
    const router = useRouter();

    const isMobileDevice = computed({
      get() {
        return isMobile();
      }
    })
    
    onMounted(() => {
      sendPixelTrack('PlansPageView');
    })

    onBeforeMount(() => {
      if (userStore.isPremiumUser) {
          router.push("/user/my-plan")
      }
    })

    return {
      isMobileDevice
    }
  }
}
</script>

<style lang="scss" src="./styles/PlansDetailsView.scss" />
