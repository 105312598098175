<template>
  <div class="transaction-situation-badge">
    <badge :value="getBadgeProps.text" :type="getBadgeProps.type" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import Badge from '@/components/Badge/Badge.vue';

export default {
  components: { Badge },
  props: {
    type: Number,
    situation: Boolean
  },
  setup(props) {
    const getBadgeProps = computed({
      get() {
        if (props.type === TRANSACTION_TYPES.REVENUE.code) {
          return props.situation ? {
            text: 'Recebida',
            type: 'success'
          } : {
            text: 'Pendente',
            type: 'warning'
          }
        }

        return props.situation ? {
          text: 'Paga',
          type: ''
        } : {
          text: 'Pendente',
          type: 'warning'
        }
      }
    })

    return {
      getBadgeProps
    }
  }
}
</script>

