<template>
  <div class="custom-select form-group" @click="toggleDropdown"
    :class="{ 'custom-select--disabled': state.isDisabled, 'custom-select--required': isRequired }">
    <label for="custom-select" v-if="label">
      {{ label }}
      <span v-if="required" class="custom-input__required">*</span>
    </label>
    <div class="custom-select__select" :class="{ open: state.isDropdownOpen }" >
      <input placeholder="Selecione" v-on:keydown.prevent :required="required" ref="selectRef" type="text" class="form-control custom-select__selected-option" :value="state.selectedOption">
      <fa-icon class="custom-select__select-icon" icon="fa-solid fa-chevron-down" />
      <span v-if="state.errorMessage" class="custom-input__error" >{{ state.errorMessage }}</span>
    </div>
    <ul v-if="state.isDropdownOpen" ref="selectOptionsRef" class="options" :style="floatingStyles">
      <li
        v-for="option in state.optionsModel"
        :key="option.value"
        @click.stop="selectOption(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch, computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useFloating, autoUpdate } from '@floating-ui/vue';
import { size, autoPlacement } from '@floating-ui/dom';

export default {
  props: {
    label: String,
    value: [String, Number],
    options: {
      type: Array,
      default: () => []
    },
    required: Boolean,
    disabled: Boolean
  },
  setup(props, { emit }) {
    const state = reactive({
      isDropdownOpen: false,
      selectedOption: null,
      optionsModel: props.options,
      isDisabled: props.disabled,
      errorMessage: null
    });

    onMounted(() => {
      if (props.value === null) {
        state.selectedOption = null;
        return;
      }

      setSelectedOption();
    })

    watch(
      () => props.options,
      (options) => {
        state.optionsModel = options;
        setSelectedOption();
      }
    );

    watch(
      () => props.value,
      (value) => {
        state.selectedOption = findOption(value)
      }
    );

    watch(
      () => props.disabled,
      (value) => {
        state.isDisabled = value
      }
    );

    const setSelectedOption = () => {
      if (props.value !== null && props.value !== undefined) {
        state.selectedOption = findOption(props.value);
        return;
      }
      
      state.selectedOption = null;
    }

    const isRequired = computed({
      get() {
        return props.required && !state.selectedOption;
      }
    })

    const findOption = (value) => {
      const option = props.options?.find((x) => {
        if (verifyIdValue(x)) {
          return x.id == value
        }

        return x.value == value;
      })?.name || '';

      return option;
    }

    const selectRef = ref(null);
    const selectOptionsRef = ref(null);
    onClickOutside(selectRef, () => {
      if (state.isDropdownOpen) {
        close();
        onBlur();
      }
    });

    const { floatingStyles } = useFloating(selectRef, selectOptionsRef, {
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      placement: 'bottom',
      transform: true,
      middleware: [
        autoPlacement({
          allowedPlacements: ['bottom', 'top']
        }),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        })
      ]
    });

    const close = () => {
      state.isDropdownOpen = false;
    };

    const toggleDropdown = () => {
      if (state.isDisabled) {
        return;
      }

      state.isDropdownOpen = !state.isDropdownOpen;
    };

    const selectOption = (option) => {
      state.errorMessage = null;
      state.selectedOption = option.name;

      if (verifyIdValue(option)) {
        emit("change", option.id);
        close();
        return;
      }

      emit("change", option.value);
      close();
    };

    const verifyIdValue = (option) => {
      return (option.id !== null && option.id !== undefined || option.id)
    }

    const onBlur = () => {
      if (props.required && !state.selectedOption && !state.isDropdownOpen) {
        state.errorMessage = "Este campo é obrigatório.";
      }

      emit("blur");
    };

    return {
      state,
      selectRef,
      selectOptionsRef,
      floatingStyles,
      isRequired,
      toggleDropdown,
      selectOption,
      onBlur
    };
  },
};
</script>

<style lang="scss" src="./CustomSelect.scss" />
  