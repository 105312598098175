<template>
  <div>
    <div class="side-menu__overlay"></div>
    <div
      class="side-menu"
      ref="menu"
      :class="{ 'side-menu--small': !state.fullWidth }"
    >
      <ul
        class="side-menu__list"
        :class="{ 'side-menu__list--small': !state.fullWidth }"
      >
        <li v-for="(route, index) in state.routes" :key="index">
          <side-menu-item v-if="route.href" :route-config="route" :show-label="state.fullWidth" @click-menu="close"/>
          <side-menu-dropdown v-else :route-config="route" :show-label="state.fullWidth" @click-menu="close"/>
        </li>
      </ul>
      <button class="side-menu__btn-retract" @click="toggleMenu">
        <fa-icon v-if="state.fullWidth" icon="fa-solid fa-chevron-left" />
        <fa-icon v-else icon="fa-solid fa-chevron-right" />
      </button>
      <install-app-banner />
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../services/localStorageService";
import SideMenuItem from "./SideMenuItem.vue";
import SideMenuDropdown from './SideMenuDropdown.vue';
import { MENU_ROUTES, MENU_SIZE_KEY } from "./menuRoutes";
import InstallAppBanner from '@/modules/common/components/InstallAppBanner/InstallAppBanner.vue';

export default {
  components: { SideMenuItem, SideMenuDropdown, InstallAppBanner },
  setup(props, { emit }) {
    const state = reactive({
      fullWidth: true,
      routes: MENU_ROUTES
    });

    onMounted(() => {
      const localStorageMenu = getLocalStorageItem(MENU_SIZE_KEY)
      state.fullWidth = localStorageMenu ? localStorageMenu === "true" : true;
    });

    const menu = ref(null);
    onClickOutside(menu, () => close(), { ignore: ['.navbar__hamburguer']});

    const close = () => {
      emit("close-menu");
    };

    const toggleMenu = () => {
      state.fullWidth = !state.fullWidth;
      setLocalStorageItem(MENU_SIZE_KEY, state.fullWidth);
    };

    return {
      state,
      menu,
      close,
      toggleMenu,
    };
  },
};
</script>

<style lang="scss" src="./SideMenu.scss" />