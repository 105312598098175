<template>
  <section class="recovery-password-view">
    <div class="recovery-container">
      <h1 class="recovery-password-view__title">Seu controle financeiro em um só lugar!</h1>
      <p class="recovery-password-view__subtitle">
        Controle suas receitas e despesas, defina objetivos financeiros e tenha uma visão clara das suas finanças tudo em um só lugar. 
        Gerencie seu dinheiro de forma simples e eficiente.
      </p>
    </div>
    <div class="recovery-box">
      <img
        src="../../../assets/images/logo.png"
        alt="Calculando Riquezas"
        class="recovery-password-view__logo"
      />
      <component :is="state.componentName" :email="state.recoveryData.email" :code="state.recoveryData.code" @setStep="setCurrentStep"/>
      
      <router-link class="recovery-password-view__back-login" to="/login">Voltar para o login</router-link>

      <div class="recovery-password-view__recaptcha">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  </section>
</template>

<script>
import { reactive } from 'vue';
import CreateNewPasswordForm from '../components/CreateNewPasswordForm/CreateNewPasswordForm.vue';
import RecoveryPasswordCodeForm from '../components/RecoveryPasswordCodeForm/RecoveryPasswordCodeForm.vue';
import RecoveryPasswordEmailForm from '../components/RecoveryPasswordEmailForm/RecoveryPasswordEmailForm.vue';

export default {
  components: { RecoveryPasswordCodeForm, RecoveryPasswordEmailForm, CreateNewPasswordForm },
  setup() {
    const state = reactive({
      componentName: 'RecoveryPasswordEmailForm',
      recoveryData: {
        email: null,
        code: null,
      }
    })

    const setCurrentStep = (data) => {
      const { step, email, code} = data;

      state.recoveryData.email = email;
      state.recoveryData.code = code;

      changeComponent(step);
    }

    const changeComponent = (step) => {
      const components = {
        1: 'RecoveryPasswordCodeForm',
        2: 'CreateNewPasswordForm'
      };

      state.componentName = components[step];
    }

    return {
      state,
      setCurrentStep
    }
  },
};
</script>

<style lang="scss" src="./styles/RecoveryPasswordView.scss" />
