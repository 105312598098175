<template>
  <div class="create-planning-view">
    <page-header :title="pageTitle" />
    <div class="create-planning-view__content">
      <box-page v-if="state.isLoading">
        <loader />
      </box-page>

      <template v-else>
        <planning-infos-card :planning-data="state.planningData" @planning-updated="onPlanningUpdated" />
        <div class="create-planning-view__budgets">
          <div v-if="state.planningData.id" class="create-planning-view__box">
            <planning-budget-goal :budget-data="state.planningData"/>
          </div>
          <planning-categories-list :budgets-data="state.planningData.budgets"  :savings-value="savingsValue"
            :planning-budget="state.planningData.value" :category-options="state.categoryOptions"
            :available-categories="availableCategories" @budgets-updated="onUpdateBudgets" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { usePlanningStore } from "@/store/planningData";
import { getPlanningById, getAvailablesCategories } from '../services/planning/planningService';
import { showErrorToast } from '@/services/toastService';
import { getCategories } from '../services/category/categoryService';
import { TRANSACTION_TYPES } from '../constants/transactionConstants';
import PageHeader from '@/components/PageHeader/PageHeader.vue';
import PlanningCategoriesList from '../components/PlanningCategoriesList/PlanningCategoriesList.vue';
import PlanningInfosCard from '../components/PlanningInfosCard/PlanningInfosCard.vue';
import Loader from '@/components/Loader/Loader.vue';
import BoxPage from '@/components/BoxPage/BoxPage.vue';
import PlanningBudgetGoal from '../components/PlanningBudgetGoal/PlanningBudgetGoal.vue';

export default {
  components: { PlanningInfosCard, PlanningCategoriesList, Loader, BoxPage, PageHeader, PlanningBudgetGoal },
  setup() {
    const planningStore = usePlanningStore();
    const router = useRouter();
    const state = reactive({
      isLoading: false,
      planningData: {},
      categoryOptions: [],
      planningId: null,
    });

    onMounted(() => {
      state.planningId = router.currentRoute.value.params.id;
      getPlanningData();
      getTransactionsCategories();
    })

    const availableCategories = computed({
      get() {
        return getAvailablesCategories(state.planningData?.budgets, state.categoryOptions) || {}
      }
    })

    const pageTitle = computed({
      get() {
        return state.planningId === "new" ? 'Novo planejamento financeiro' : state.planningData?.name;
      }
    })

    const savingsValue = computed({
      get() {
        const { value, savingPercent } = state.planningData
        const totalValue = value && savingPercent ? (value * savingPercent) / 100 : 0
        return totalValue
      }
    })

    const getNewPlanningData = () => {
      state.planningData = {
        ...state.planningData,
        ...planningStore.getPlanningData
      };
    }

    const getPlanningData = () => {
      if (state.planningId === "new") {
        getNewPlanningData();
        return;
      }

      state.isLoadingHistory = true;
      return getPlanningById(state.planningId)
        .then(onGetPlanningSuccess)
        .catch(onGetPlanningError)
    }

    const onGetPlanningSuccess = (data) => {
      state.planningData = data;
      state.isLoading = false;
      state.isLoadingHistory = false;
    }

    const onGetPlanningError = () => {
      state.isLoading = false;
      state.isLoadingHistory = false;
      showErrorToast('Ocorreu um erro ao buscar o objetivo. Tente novamente.')
    }

    const getTransactionsCategories = () => {
      getCategories({ Type: TRANSACTION_TYPES.EXPENSE.code })
        .then(onGetTransactionsCategoriesSuccess)
        .catch(onGetTransactionsCategoriesError);
    }

    const onGetTransactionsCategoriesSuccess = (data) => {
      state.categoryOptions = data.items;
    }

    const onGetTransactionsCategoriesError = () => {
      // TODO: erro
    }

    const onUpdateBudgets = (data) => {
      state.planningData.budgets = data;
    }

    const onPlanningUpdated = (data) => {
      state.planningId = data ? data.id : state.planningId;
      getPlanningData();
    }

    return {
      state,
      availableCategories,
      pageTitle,
      savingsValue,
      onUpdateBudgets,
      onPlanningUpdated
    }
  }
}
</script>

<style lang="scss" src="./styles/CreatePlanningView.scss" />
