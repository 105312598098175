import * as authResource from './authResource'
import { useUserDataStore } from "@/store/userData";
import { getCookie } from "@/services/cookiesService";

export async function login(loginData) {
    const { data } = await authResource.login(loginData);

    return data;
}

export async function googleLogin(loginData) {
    const { data } = await authResource.googleLogin(loginData);

    return data;
}

export async function logout() {
    await authResource.logout();
}

export async function refreshLogin() {
    const refreshToken = getCookie('refresh-token');

    const headers = {
        Authorization: `Bearer ${refreshToken}`
    }

    const { data } = await authResource.refreshLogin(headers);

    if(data?.success) {
        setNewUserToken(data);
        return Promise.resolve();
    }
    
    return Promise.reject();
}

export function setNewUserToken(data) {
    const userData = useUserDataStore();

    return userData.setTokenData(data);
}

export function getIsAuthenticated() {
    const userData = useUserDataStore();

    return userData.getIsAuthenticated;
}

export function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}