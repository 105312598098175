<template>
  <data-table :is-loading="state.isLoading" :columns="transactionsColumns" :data="state.transactionsData"
    @change-pagination="onChangePagination" hide-filters class="transactions-list-mobile" sorted-by="transactionDate">
    <template #row="{ row }">
      <transaction-row-mobile :transaction="row" />
    </template>
  </data-table>
</template>

<script>
import { reactive, onMounted, watch, onUnmounted } from "vue";
import { getTransactions } from "../../services/transactionsService";
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { eventBus } from "@/services/eventBusService";
import DataTable from "@/components/DataTable/DataTable.vue";
import TransactionRowMobile from './TransactionRowMobile.vue';

export default {
  components: { DataTable, TransactionRowMobile },
  props: {
    type: Number,
  },
  setup(props) {
    const transactionsColumns = [
      {
        name: "Descrição",
        key: "description",
        sortable: true,
      },
    ];

    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      transactionsData: {
        items: [],
        totalItems: 0,
      },
      isLoading: true,
      paginationFilters: {
        PageSize: 10,
        PageNumber: 1
      },
    });


    const getListTransactions = () => {
      const filters = {
        ...filtersStore.getTransactionsFilters,
        ...state.paginationFilters,
        Type: props.type
      };

      state.isLoading = true;
      return getTransactions(filters).then(getListTransactionsSuccess).catch(getListTransactionsError);
    };

    const getListTransactionsSuccess = (data) => {
      const { items, totalItems } = data;

      state.transactionsData = {
        items,
        totalItems
      };

      state.isLoading = false;
    }

    const getListTransactionsError = () => {
      state.isLoading = false;
    }

    const reloadDataHandler = () => {
      getListTransactions()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      getListTransactions()
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    watch(() => filtersStore.getTransactionsFilters, () => getListTransactions(), { deep: true });

    const onChangePagination = (data) => {
      const { PageSize, PageNumber } = data;
      state.paginationFilters = {
        PageSize,
        PageNumber
      }

      getListTransactions();
    }

    return {
      state,
      transactionsColumns,
      getListTransactions,
      onChangePagination
    };
  },
};
</script>

<style lang="scss" src="./TransactionsListMobile.scss" />
