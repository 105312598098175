<template>
    <div class="category-list">
        <page-header title="Categorias financeiras">
            <div v-if="isMobileDevice" class="category-list__mobile-filters">
                <button class="btn button-outline btn__mobile--rounded" @click="openFilters">
                    <fa-icon icon="fa-solid fa-filter" />
                </button>
                <children-modal title="Filtrar categorias" :show="state.showFiltersModal" @close="closeFilters"
                    @confirm="onConfirmFilter" confirm-label="Filtrar">
                    <transaction-type-select show-all class="type-select" @change-type="onChangeTypeMobile" :value="state.filters.Type"/>
                    <search-input v-model="state.filters.SearchTerm" :value="state.filters.SearchTerm" label="Buscar por nome" placeholder="Buscar por nome" />
                </children-modal>
            </div>
            <template v-else>
                <transaction-type-select show-all label="" class="type-select" @change-type="onChangeType" />
                <search-input :value="state.filters.SearchTerm" placeholder="Buscar por nome" @onSearch="onChangeText" />
            </template>
            <button class="btn button-primary category-list__add-button" @click="createCategory()">
                <fa-icon icon="fa-solid fa-plus" />
                Nova categoria
            </button>
        </page-header>
        <div class="category-list__content" :class="{ 'category-list__content--mobile': isMobileDevice }">
            <box-page>
                <data-table :is-loading="state.isLoading" :columns="getColumns" :data="state.categories" hideFilters
                    sorted-by="name" @change-pagination="onChangePagination">
                    <template #row="{ row }">
                        <template v-if="isMobileDevice">
                            <td id="name" @click="editCategory(row.id)">
                                {{ row.name }}
                                <br>
                                <category-type-badge class="category-list__type-badge--mobile" :type="row.type" />
                            </td>
                            <td id="icon" @click="editCategory(row.id)">
                                <div class="category-list__icon">
                                    <fa-icon :icon="`fa-solid ${row.icon}`" />
                                </div>
                                <div class="category-list__color-display category-list__color-display--mobile"
                                    :style="`background-color: ${row.color}`"></div>
                            </td>
                        </template>
                        <template v-if="!isMobileDevice">
                            <td id="name">{{ row.name }}</td>
                            <td id="type">
                                <category-type-badge :type="row.type" />
                            </td>
                            <td id="icon">
                                <div class="category-list__icon">
                                    <fa-icon :icon="`fa-solid ${row.icon}`" />
                                </div>
                            </td>
                            <td id="color">
                                <div class="category-list__color-display" :style="`background-color: ${row.color}`"></div>
                            </td>
                            <td id="actions">
                                <DropdownButton label="Ações" size="sm" 
                                    :disabled-tooltip="state.disabledTooltip" 
                                    :disabled="row.isDefault">
                                    <template #options>
                                        <DropdownItem label="Editar" @click="editCategory(row.id)" />
                                        <DropdownItem label="Excluir" @click="removeCategory(row)" />
                                    </template>
                                </DropdownButton>
                            </td>
                        </template>
                    </template>
                </data-table>
            </box-page>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue';
import { useModal } from "vue-final-modal";
import { getCategories } from "../services/category/categoryService";
import { isMobile } from '@/services/deviceService';
import { DESKTOP_COLUMNS, MOBILE_COLUMNS } from '../constants/categoryListConstants';
import { eventBus } from "@/services/eventBusService";
import { useUserDataStore } from "@/store/userData";
import { getTracerByName } from '@modules/common/services/tracer/tracerService';
import { showErrorToast } from "@/services/toastService";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import DropdownButton from "@/components/DropdownButton/DropdownButton.vue";
import DropdownItem from "@/components/DropdownButton/DropdownItem.vue";
import CreateCategoryModal from '../components/CreateCategoryModal/CreateCategoryModal.vue';
import RemoveCategoryModal from '../components/RemoveCategoryModal/RemoveCategoryModal.vue';
import CategoryTypeBadge from '../components/CategoryTypeBadge/CategoryTypeBadge.vue';
import SearchInput from '@/components/SearchInput/SearchInput.vue';
import TransactionTypeSelect from '../components/TransactionTypeSelect/TransactionTypeSelect.vue';
import ChildrenModal from '@/components/ChildrenModal/ChildrenModal.vue';
import FeedbackModal from "@modules/common/components/FeedbackModal/FeedbackModal.vue";

const FEEDBACK_TRACER = 'FIRST_CATEGORY';

export default {
    components: {
        BoxPage,
        PageHeader,
        DataTable,
        DropdownButton,
        DropdownItem,
        CategoryTypeBadge,
        SearchInput,
        TransactionTypeSelect,
        ChildrenModal
    },
    setup() {
        const userStore = useUserDataStore();

        const state = reactive({
            categories: {},
            isLoading: true,
            showFiltersModal: false,
            filters: {
                SearchTerm: null,
                Type: null,
                PageSize: null,
                PageNumber: null,
                HideDefault: false
            },
            disabledTooltip: 'Não é possível editar categorias padrão'
        })

        onMounted(() => {
            getCategoriesList();
        });

        const getColumns = computed({
            get() {
                return isMobile() ? MOBILE_COLUMNS : DESKTOP_COLUMNS;
            }
        })

        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        const getCategoriesList = () => {
            state.isLoading = true;

            getCategories(state.filters).then(onGetCategoriesSuccess).catch(onGetCategoriesError);
        }

        const onGetCategoriesSuccess = (data) => {
            state.categories = {
                items: data.items,
                totalItems: data.totalItems,
            }
            state.isLoading = false;
        }

        const onGetCategoriesError = () => {
            state.isLoading = false;
            showErrorToast('Ocorreu um erro ao buscar as categorias. Tente novamente.')
        }

        const editCategory = (categoryId) => {
            if (!userStore.isPremiumUser) {
                eventBus.emit('showPremiumOverlay');
                return;
            }

            state.categoryToEdit = categoryId
            openCategoryModal();
        }

        const removeCategory = (category) => {
            const { name, id } = category;
            state.categoryToRemove = { name, id }

            openRemoveCategoryModal();
        }

        const createCategory = () => {
            if (!userStore.isPremiumUser) {
                eventBus.emit('showPremiumOverlay');
                return;
            }

            openCategoryModal();
        }

        const { open: openCategoryModal, close: closeCategoryModal } = useModal({
            component: CreateCategoryModal,
            attrs: {
                categoryId: computed(() => state.categoryToEdit),
                onClose() {
                    closeCategoryModal();
                    state.categoryToEdit = null;
                },
                onConfirm() {
                    closeCategoryModal();
                    state.categoryToEdit = null;
                },
                onCancel() {
                    closeCategoryModal();
                    state.categoryToEdit = null;
                },
                onSuccess() {
                    getCategoriesList();

                    if (!getTracerByName(FEEDBACK_TRACER) && !state.categoryToEdit) {
                        setTimeout(() => {
                            openFeedbackModal();
                        }, 600)
                    }
                }
            }
        });

        const { open: openRemoveCategoryModal, close: closeRemoveCategoryModal } = useModal({
            component: RemoveCategoryModal,
            attrs: {
                categoryId: computed(() => state.categoryToRemove?.id),
                categoryName: computed(() => state.categoryToRemove?.name),
                onClose() {
                    closeRemoveCategoryModal();
                    state.categoryToRemove = null;
                },
                onConfirm() {
                    closeRemoveCategoryModal();
                    state.categoryToRemove = null;
                },
                onCancel() {
                    closeRemoveCategoryModal();
                    state.categoryToRemove = null;
                },
                onSuccess() {
                    getCategoriesList();
                }
            }
        });

        const { open: openFeedbackModal, close: closeFeedbackModal } = useModal({
            component: FeedbackModal,
            attrs: {
                tracer: FEEDBACK_TRACER,
                description: 'cadastrar uma nova categoria para organizar suas finanças.',
                onClose() {
                    closeFeedbackModal()
                },
                onConfirm() {
                    closeFeedbackModal()
                },
                onCancel() {
                    closeFeedbackModal()
                }
            }
        });

        const onChangeType = (type) => {
            state.filters.Type = type;
            getCategoriesList();
        }

        const onChangeText = (text) => {
            state.filters.SearchTerm = text;
            getCategoriesList();
        }

        const onChangeTypeMobile = (type) => {
            state.filters.Type = type;
        }

        const onChangeTextMobile = (text) => {
            state.filters.SearchTerm = text;
        }

        const onChangePagination = (data) => {
            const { PageSize, PageNumber } = data;
            state.filters = {
                ...state.filters,
                PageSize,
                PageNumber
            }

            getCategoriesList();
        }

        const openFilters = () => {
            state.showFiltersModal = true;
        }

        const closeFilters = () => {
            state.showFiltersModal = false;
        }

        const onConfirmFilter = () => {
            state.showFiltersModal = false;
            getCategoriesList();
        }

        return {
            state,
            isMobileDevice,
            getColumns,
            createCategory,
            removeCategory,
            editCategory,
            onChangeText,
            onChangeType,
            onChangeTextMobile,
            onChangeTypeMobile,
            onChangePagination,
            openFilters,
            closeFilters,
            onConfirmFilter
        }
    }
}
</script>

<style lang="scss" src="./styles/CategoryListView.scss" />