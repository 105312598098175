<template>
    <div class="plannings-widget" :class="{ 'plannings-widget--empty': isEmpty && !state.isLoading }">
        <loader class="loader--white" v-if="state.isLoading" />
        <template v-else>
            <div class="plannings-widget__header">
                <h3 class="plannings-widget__title">{{ title }}</h3>
            </div>
            <div v-if="isEmpty" class="plannings-widget__empty">
                <p>Crie seu primeiro planejamento financeiro!</p>
                <router-link to="/finance/plannings">
                    <button class="btn button-primary button-primary--white">
                        Criar plajenamento
                    </button>
                </router-link>
            </div>
            <template v-else>
                <div class="plannings-widget__content">
                    <div class="plannings-widget__period">
                        {{ formatDate(state.planningData.startDate, state.planningData.endDate) }}
                    </div>
                </div>
                <planning-budget-goal :budget-data="state.planningData"/>
                <router-link to="/finance/plannings" class="plannings-widget__footer">
                    Ver todos
                    <fa-icon icon="fa-solid fa-arrow-right" />
                </router-link>
            </template>
        </template>
    </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { formatCurrency } from '@/services/currencyService';
import { formatStartAndEndDate } from '@/services/formatService';
import { usePlanningStore } from "@/store/planningData";
import { getPlanningList } from '../../services/planning/planningService';
import Loader from '@/components/Loader/Loader.vue';
import PlanningBudgetGoal from '../PlanningBudgetGoal/PlanningBudgetGoal.vue';

export default {
    components: { Loader, PlanningBudgetGoal },
    setup() {
        const planningStore = usePlanningStore();
        const state = reactive({
            planningData: {},
            isLoading: true,
        })

        const isEmpty = computed({
            get() {
                return !state.planningData?.id
            }
        })

        const title = computed({
            get() {
                return isEmpty.value ? 'Planejamentos' : `Planejamento ${state.planningData.name}`
            }
        })

        onMounted(() => {
            const currentDate = new Date();
            const startDate = new Date(currentDate.getFullYear(), currentDate.getUTCMonth(), 1)
            const endDate = new Date(currentDate.getFullYear(), currentDate.getUTCMonth() + 1, 0)
            planningStore.setPlanningData({ startDate, endDate });

            getPlanningData();
        })

        const getPlanningData = () => {
            state.isLoading = true;
            state.monthlyPlanning = {};
            const { startDate, endDate } = planningStore.getPlanningData;
            const filters = { startDate, endDate, customPeriod: false }
            getPlanningList(filters).then(onGetPlanningDataSuccess).catch(onGetPlanningDataError);
        }

        const onGetPlanningDataSuccess = (data) => {
            state.planningData = data.plans?.length ? data.plans[0] : {}
            state.isLoading = false;
        }
        const onGetPlanningDataError = () => {
            state.isLoading = false;
        }

        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        const formatDate = (startDate, endDate) => {
            if (!startDate || !endDate) {
                return;
            }

            return formatStartAndEndDate(new Date(startDate), new Date(endDate));
        }

        return {
            state,
            title,
            isEmpty,
            formatDate,
            toCurrency
        }
    }
}
</script>

<style lang="scss" src="./PlanningsWidget.scss" />
