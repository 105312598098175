<template>
    <div class="month-picker">
        <button class="month-picker__arrow month-picker__arrow--left" @click="decreaseMonth">
            <fa-icon icon="fa-solid fa-chevron-left" />
        </button>
        <VueDatePicker ref="pickerRef" class="month-picker__picker" v-model="state.monthPeriod" :format="customDateFormat" locale="pt"
            :clearable="false" auto-apply month-picker />
        <button class="month-picker__arrow month-picker__arrow--right" @click="increaseMonth">
            <fa-icon icon="fa-solid fa-chevron-right" />
        </button>
    </div>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import { getStartAndEndDate } from "@/services/formatService";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
    props: {
        value: Object
    },
    setup(props, { emit }) {
        const date = new Date();
        const pickerRef = ref(null)

        const state = reactive({
            monthPeriod: props.value || { month: date.getMonth(), year: date.getFullYear() }
        });

        watch(() => props.value, () => state.monthPeriod = props.value);
        watch(() => state.monthPeriod, () => onChangeMonthPeriod(), { deep: true });

        const onChangeMonthPeriod = () => {
            const { month, year } = state.monthPeriod;
            const { startDate, endDate } = getStartAndEndDate(month + 1, year);

            emit('change', { startDate, endDate })
        };

        const customDateFormat = (date) => {
            return capitalizeFirstLetter(format(date, 'MMMM - yyyy', { locale: ptBR }));
        };

        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        const openPicker = () => {
            pickerRef.value.openMenu();
        }

        const decreaseMonth = () => {
            if (state.monthPeriod.month === 0) {
                state.monthPeriod.month = 11;
                state.monthPeriod.year -= 1;
            } else {
                state.monthPeriod.month -= 1;
            }
        };

        const increaseMonth = () => {
            if (state.monthPeriod.month === 11) {
                state.monthPeriod.month = 0;
                state.monthPeriod.year += 1;
            } else {
                state.monthPeriod.month += 1;
            }
        };

        return {
            state,
            pickerRef,
            customDateFormat,
            onChangeMonthPeriod,
            decreaseMonth,
            increaseMonth,
            openPicker
        };
    }
}
</script>

<style lang="scss" src="./Monthpicker.scss" />
