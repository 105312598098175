import requestService from '@services/requestService'

const CHECKOUT_URL = '/checkout'

export function createSession(params) {
    return requestService.post(`${CHECKOUT_URL}/session`, params);
}

export function getProducts() {
    return requestService.get(`${CHECKOUT_URL}/products`);
}