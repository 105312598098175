<template>
  <div class="checkout-view">
    <loader v-show="!state.showCheckout" />
    <div v-show="state.showCheckout" id="checkout"></div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive } from 'vue';
import { createSession } from '../services/checkoutService';
import { loadStripe } from '@stripe/stripe-js';
import { getSessionItem } from '@/services/sessionStorageService';
import { showErrorToast } from "@/services/toastService";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import { useRouter } from 'vue-router';
import Loader from '@/components/Loader/Loader.vue';

export default {
  components: { Loader },
  setup() {
    const router = useRouter();
    const state = reactive({
      stripe: null,
      checkout: null,
      showCheckout: false,
      reloadRequest: 0
    });

    onMounted(async () => {
      state.stripe = await loadStripe(process.env.VUE_APP_STRIPE_CHECKOUT);

      initializeCheckout();
    });

    const initializeCheckout = () => {
      const priceId = getSessionItem("productId");

      if (!priceId) {
        router.push('/plans');
        return;
      }

      sendPixelTrack('InitiateCheckout')

      createSession({ priceId: atob(priceId) })
        .then((data) => {
          onCreateSessionSuccess(data)
        })
        .catch(onCreateSessionError);
    };

    const onCreateSessionSuccess = async (data) => {
      const clientSecret = data.sessionSecret;

      state.checkout = await state.stripe.initEmbeddedCheckout({
        clientSecret: clientSecret
      });

      mountCheckout();
    };

    const mountCheckout = () => {
      if (state.checkout) {
        state.checkout.mount('#checkout');
        state.showCheckout = true;
      }
    };

    onBeforeUnmount(() => {
      if (state.checkout) {
        state.checkout.destroy();
      }
    })

    const onCreateSessionError = () => {
      if (state.reloadRequest >= 2) {
        showErrorToast('Ocorreu um erro. Tente novamente.')
        return;
      }

      initializeCheckout();
      state.reloadRequest++;
    };

    return {
      state
    }
  },
};
</script>

<style lang="scss" src="./styles/CheckoutView.scss" />