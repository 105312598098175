<template>
    <Modal
    :title="title"
    @close="onClose"
    @cancel="onCancel"
    :fetch-action="handleSendFeedback"
    @fetch-success="onSendFeedbackSuccess"
    @fetch-error="onSendFeedbackError"
    confirm-label="Enviar"
    class="feedback-modal"
    :esc-to-close="false"
    :click-to-close="false">
        <template #default>
            <div class="feedback-modal__content">
                <p v-if="description" class="feedback-modal__description">{{ descriptionFormatted }}</p>
                <div class="feedback-modal__nps">
                    <template v-if="isNps">
                        <button v-for="rate in state.rateOptions" :key="rate" class="feedback-modal__btn-nps"
                            :class="{ selected: state.rate === rate }" @click="selectRate(rate)">
                            {{ rate }}
                        </button>
                    </template>
                    <template v-else>
                        <div class="feedback-modal__rate">
                            <template v-for="item in state.starsQty" :key="item">
                                <input type="radio" v-model="state.rate" :value="item" name="rating" :id="`rating-${item}`">
                                <label :for="`rating-${item}`"><fa-icon icon="fa-solid fa-star" /></label>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="feedback-modal__rate-description">
                    <span>Muito difícil</span>
                    <span>Muito fácil</span>
                </div>

                <div class="feedback-modal__comment">
                    <label for="comment">Deixe um comentário</label>
                    <textarea v-model="state.comment" id="comment" name="comment" rows="4"></textarea>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { computed, reactive } from "vue";
import { sendFeedback } from "../../services/feedback/feedbackService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { getUserTracersList } from "../../services/tracer/tracerService";
import Modal from "@/components/Modal/Modal.vue";

export default {
    components: { Modal },
    props: {
        title: {
            type: String,
            default: "Compartilhe sua opinião",
        },
        description: String,
        tracer: String,
        isNps: Boolean
    },
    setup(props, { emit }) {
        const state = reactive({
            rateOptions: [1, 2, 3, 4, 5],
            starsQty: 5,
            rate: null,
            comment: null,
        });

        const descriptionFormatted = computed({
            get() {
                return `Conte como foi sua experiência ao ${props.description}`
            }
        })

        const selectRate = (rate) => {
            state.rate = rate;
        };

        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const handleSendFeedback = () => {
            if(!state.rate || !props.tracer) {
                return;
            }

            const feedback = {
                rate: state.rate,
                comment: state.comment,
                tracer: props.tracer
            }

            return sendFeedback(feedback);
        }

        const onSendFeedbackSuccess = () => {
            showSuccessToast("Feedback enviado com sucesso. Obrigado!");
            getUserTracersList();
            onClose();
        }

        const onSendFeedbackError = () => {
            showErrorToast("Ocorreu um erro ao enviar o feedback. Tente novamente.");
        }

        return {
            state,
            descriptionFormatted,
            selectRate,
            onClose,
            onCancel,
            handleSendFeedback,
            onSendFeedbackSuccess,
            onSendFeedbackError,
        };
    },
};
</script>

<style lang="scss" src="./FeedbackModal.scss" />
