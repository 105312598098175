<template>
  <div v-if="state.show" class="premium-box-information premium-box-information__info">
    <div class="premium-box-information__image">
      <img src="@images/illustrations/go-premium.webp" />
    </div>
    <div class="premium-box-information__body">
      <p class="premium-box-information__title">
        Conclua sua compra
        <a @click="onClose" class="premium-box-information__close">
          <fa-icon icon="fa-solid fa-xmark" />
        </a>
      </p>
      <div class="premium-box-information__content">
        <p>Selecionamos a melhor oferta do nosso plano Premium para você. Clique abaixo para assinar ou conhecer nossos
          planos.</p>
        <div class="premium-box-information__actions">
          <button class="btn button-primary small premium-box-information__buy-plan"
            @click="goToCheckout(getAnnualPriceId)">Assinar plano anual</button>
          <button class="btn button-outline small" @click="goToPlans">Conheça nossos planos</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useUserDataStore } from "@/store/userData";
import { getLocalStorageItem, removeLocalStorageItem } from '@/services/localStorageService';
import { getProducts } from '@modules/checkout/services/checkoutService';
import { setSessionItem } from '@/services/sessionStorageService';
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';

const LOCALSTORAGE_KEY = 'shouldShowPremium'

export default {
  setup() {
    const router = useRouter();
    const userStore = useUserDataStore();
    const state = reactive({
      show: false,
      planData: null,
      reloadRequest: 0
    });

    onMounted(() => {
      const shouldShow = !!getLocalStorageItem(LOCALSTORAGE_KEY);

      setTimeout(() => {
        if (shouldShow && !getIsPremiumUser.value) {
          getProductsData();
        }
      }, 300);
    })

    const getIsPremiumUser = computed({
      get() {
        return userStore.isPremiumUser
      }
    })

    const getProductsData = () => {
      getProducts().then(onGetProductsSuccess).catch(onGetProductsError)
    }

    const onGetProductsSuccess = (data) => {
      state.planData = data.products[0];
      state.show = true;
    }

    const onGetProductsError = () => {
      if (state.reloadRequest >= 2) {
        return;
      }

      getProductsData();
      state.reloadRequest++;
    }

    const goToCheckout = (priceId) => {
      setSessionItem('productId', btoa(priceId));
      sendPixelTrack('PremiumBoxInformationToCheckout')
      hideCard();
      router.push("/checkout");
    }

    const onClose = () => {
      state.show = false;
      hideCard();
    }

    const hideCard = () => {
      removeLocalStorageItem(LOCALSTORAGE_KEY)
    }

    const goToPlans = () => {
      hideCard();
      router.push("/plans");
    }

    const getAnnualPriceId = computed({
      get() {
        return state.planData?.prices?.year[0]?.id
      }
    })

    return {
      state,
      getAnnualPriceId,
      onClose,
      goToPlans,
      goToCheckout
    }
  },
};
</script>

<style lang="scss" src="./PremiumBoxInformation.scss" />
