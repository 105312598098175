<template>
    <div class="recurrence-edition-confirmation-modal">
        <div class="recurrence-edition-confirmation-modal__overlay" />
        <div class="recurrence-edition-confirmation-modal__content">
            <h3 class="recurrence-edition-confirmation-modal__title">{{ actionTitle }} transação recorrente</h3>
            <div class="recurrence-edition-confirmation-modal__options">
                <div class="recurrence-edition-confirmation-modal__option" v-for="editionType in recurrenceEditionTypes"
                    :key="editionType.key">
                    <input type="radio" :id="editionType.key" :name="editionType.name" :value="editionType.value"
                        v-model="state.editionType">
                    <label :for="editionType.key">{{ editionType.name }}</label>
                </div>
            </div>
            <div class="recurrence-edition-confirmation-modal__actions">
                <button @click="onCancel" class="btn small button-outline">
                    Cancelar
                </button>
                <button @click="onConfirm" class="btn small button-primary">
                    Confirmar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { TRANSACTION_RECURRENCE_EDITION_TYPES } from '../../constants/transactionConstants';

export default {
    props: {
        actionTitle: {
            type: String,
            default: "Editar"
        }
    },
    setup(props, { emit }) {
        const recurrenceEditionTypes = TRANSACTION_RECURRENCE_EDITION_TYPES

        const state = reactive({
            editionType: null,
        })

        const onCancel = () => {
            emit('on-cancel')
        }

        const onConfirm = () => {
            emit('on-confirm', state.editionType)
        }

        return {
            state,
            recurrenceEditionTypes,
            onCancel,
            onConfirm
        }
    }
}
</script>

<style lang="scss" src="./RecurrenceEditionConfirmationModal.scss" />
