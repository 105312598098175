<template>
  <Modal :title="modalTitle" :confirm-label="confirmLabel" :fetch-action="handleSaveCategory"
    @fetch-success="onSaveCategorySuccess" @fetch-error="onSaveCategoryError" @close="onClose" @cancel="onCancel"
    class="create-category-modal" button-submit>
    <template #default>
      <form action="#" @submit.prevent>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col">
                  <custom-input :value="state.category.name" label="Nome" v-model="state.category.name" required
                    name="name" autocomplete="off" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <transaction-type-select :value="state.category.type" @change-type="onChangeType" />
                </div>
                <div class="col-lg-3 col-6">
                  <div class="form-group">
                    <label for="color-picker">Cor</label>
                    <color-picker name="color-picker" :color="state.category.color" v-model="state.category.color" />
                  </div>
                </div>
                <div class="col-lg-3 col-6">
                  <select-icon-modal @select-icon="onSelectIcon" :icon="state.category.icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-if="isMobileDevice" #footer>
      <button @click="openRemoveCategoryModal" class="btn button-primary button-primary--alert">
        <fa-icon icon="fa-solid fa-trash"></fa-icon>
      </button>
    </template>
  </Modal>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import {
  getCategoryById,
  saveCategory,
  updateCategory
} from "../../services/category/categoryService";
import { useModal } from "vue-final-modal";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { isMobile } from "@/services/deviceService";
import Modal from "@/components/Modal/Modal.vue";
import CustomInput from '@/components/CustomInput/CustomInput.vue';
import TransactionTypeSelect from '../TransactionTypeSelect/TransactionTypeSelect.vue';
import SelectIconModal from '@/components/SelectIconModal/SelectIconModal.vue';
import RemoveCategoryModal from '../RemoveCategoryModal/RemoveCategoryModal.vue';
import ColorPicker from "@/components/ColorPicker/ColorPicker.vue";

export default {
  components: { Modal, CustomInput, TransactionTypeSelect, SelectIconModal, ColorPicker },
  props: {
    categoryId: {
      type: Number,
      default: null
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      category: {
        id: null,
        name: null,
        icon: 'fa-house',
        color: '#00ad12',
        type: 0
      },
    });

    onMounted(() => {
      if (props.categoryId) {
        getCategoryById(props.categoryId)
          .then(getCategorySuccess)
          .catch(getCategoryError);
      }
    });

    const modalTitle = computed({
      get() {
        return props.categoryId !== null ? 'Editar categoria' : 'Adicionar categoria'
      }
    })

    const isFormValid = computed({
      get() {
        return state.category.name
      },
    });

    const confirmLabel = computed({
      get() {
        return props.categoryId ? 'Editar' : 'Salvar'
      }
    })

    const isMobileDevice = computed({
      get() {
        return isMobile()
      }
    })

    const getCategorySuccess = (data) => {
      state.category = { ...data.item };
    };

    const getCategoryError = () => {
      showErrorToast("Ocorreu um erro ao buscar a categoria. Tente novamente.");
    };

    const handleSaveCategory = () => {
      if (!isFormValid.value) {
        return;
      }

      const categoryData = {
        name: state.category.name,
        icon: state.category.icon,
        color: state.category.color,
        type: state.category.type,
      };

      const { id } = state.category;

      if (id) {
        categoryData.id = id

        return updateCategory(id, categoryData);
      }

      return saveCategory(categoryData);
    };

    const onSaveCategorySuccess = () => {
      const label = props.categoryId ? 'editada' : 'criada';
      showSuccessToast(`Categoria ${label} com sucesso!`);
      emit('success');
      onClose();
    };

    const onSaveCategoryError = () => {
      showErrorToast("Ocorreu um erro ao salvar a categoria. Tente novamente.");
    };

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const onSelectIcon = (icon) => {
      state.category.icon = icon;
    }

    const onChangeType = (type) => {
      state.category.type = type
    }

    const { open: openRemoveCategoryModal, close: closeRemoveCategoryModal } = useModal({
      component: RemoveCategoryModal,
      attrs: {
        categoryId: computed(() => state.category.id),
        categoryName: computed(() => state.category?.name),
        onClose() {
          closeRemoveCategoryModal();
        },
        onConfirm() {
          closeRemoveCategoryModal();
        },
        onCancel() {
          closeRemoveCategoryModal();
        },
        onSuccess() {
         onRemoveCategorySuccess();
        }
      }
    });

    const onRemoveCategorySuccess = () => {
      emit('success');
      onClose();
    }

    return {
      state,
      isFormValid,
      confirmLabel,
      modalTitle,
      isMobileDevice,
      onCancel,
      onClose,
      onChangeType,
      onSelectIcon,
      handleSaveCategory,
      onSaveCategorySuccess,
      onSaveCategoryError,
      openRemoveCategoryModal
    };
  },
};
</script>

<style lang="scss" src="./CreateCategoryModal.scss" />
