import * as userResource from './userResource'

export async function register(registerData) {
    const { data } = await userResource.register(registerData);

    return data;
}

export async function getUserData() {
    const { data } = await userResource.getUserData();

    return data;
}

export async function updateUser(userData) {
    const { data } = await userResource.updateUser(userData);

    return data;
}

export async function changePassword(passwordData) {
    const { data } = await userResource.changePassword(passwordData);

    return data;
}

export async function getRecoveryPasswordCode(email) {
    const { data } = await userResource.getRecoveryPasswordCode(email);

    return data;
}

export async function checkPasswordToken(userData) {
    const { data } = await userResource.checkPasswordToken(userData);

    return data;
}

export async function resetPassword(passwordData) {
    const { data } = await userResource.resetPassword(passwordData);

    return data;
}
