<template>
  <div class="progress-bar">
    <div class="progress-bar__progress" :style="progressStyle"></div>
    <span class="progress-bar__label">
        <toggle-currency-visibility :value="actualValue" />
        ({{ currentProgress }})
      </span>
  </div>
</template>

<script>
import { reactive, computed, watch } from 'vue';
import ToggleCurrencyVisibility from '../ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
  components: { ToggleCurrencyVisibility },
  props: {
    totalValue: Number,
    currentValue: Number,
    showProgressLabel: Boolean
  },
  setup(props) {
    const state = reactive({
      currentValueModel: props.currentValue,
      totalValueModel: props.totalValue
    });

    watch(() => props.currentValue, (value) => state.currentValueModel = value);
    watch(() => props.totalValue, (value) => state.totalValueModel = value);

    const getPercentValue = computed({
      get() {
        return Number(state.totalValueModel) !== 0 ? (state.currentValueModel / state.totalValueModel * 100).toFixed(2) : 0
      }
    })

    const currentProgress = computed({
      get() {
        return `${getPercentValue.value}%`;
      }
    });

    const progressStyle = computed({
      get() {
        return `width: ${getPercentValue.value}%`
      }
    });

    const actualValue = computed({
      get() {
        return state.currentValueModel;
      }
    })

    return {
      progressStyle,
      currentProgress,
      actualValue
    }
  }
}
</script>

<style lang="scss" src="./ProgressBar.scss" />
