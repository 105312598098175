<template>
  <div class="login-form">
    <div class="login-form__content">
      <box-information v-if="state.errorData.messages" type="error" :title="state.errorData.title" :messages="state.errorData.messages"/>
      <form method="post" @submit.prevent>
        <custom-input label="E-mail" v-model="state.email" required name="email"
          :custom-validations="state.emailValidations" />
        <custom-input label="Senha" type="password" v-model="state.password" required name="password" />

        <div class="login-form__actions">
          <router-link class="login-form__recovery-pass" to="/recovery-password">Esqueci minha senha</router-link>
          <request-button label="Entrar" :fetch-action="submitForm" @fetch-success="onLoginSucess"
            @fetch-error="onLoginError" type="submit" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { login, setNewUserToken } from "../../services/authService";
import { emailValidator } from "@/services/validationsService";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { validateRecaptcha } from "@/modules/common/services/recaptcha/recaptchaService";
import { showErrorToast } from "@/services/toastService";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import RequestButton from "@/components/RequestButton/RequestButton.vue";
import BoxInformation from '@/components/BoxInformation/BoxInformation.vue';

export default {
  components: { CustomInput, RequestButton, BoxInformation },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const router = useRouter();

    const state = reactive({
      email: null,
      password: null,
      emailValidations: [
        {
          message: "E-mail inválido",
          valid: (email) => emailValidator(email),
        },
      ],
      errorData: {
        title: 'Ocorreu um erro ao logar.',
        messages: null
      }
    });

    const isFormValid = computed({
      get() {
        return state.email && state.password;
      },
    });

    const submitForm = () => {
      if (!isFormValid.value) {
        return;
      }
      state.errorData.messages = null;

      return submitRecaptcha();
    }

    const submitRecaptcha = async () => {
      if (!isFormValid.value) {
        return;
      }

      await recaptchaLoaded()

      const recaptchaAction = 'login';
      const token = await executeRecaptcha(recaptchaAction);
      const isValid = await validateRecaptcha({ token, recaptchaAction });

      if (isValid) {
        return handleLogin();
      }

      return Promise.reject();
    }

    const handleLogin = () => {
      if (!isFormValid.value) {
        return;
      }

      const formData = {
        email: state.email,
        password: state.password,
      };

      return login(formData);
    };

    const onLoginSucess = (data) => {
      setNewUserToken(data);

      router.push("/");
    };

    const onLoginError = (data) => {
      const error = data?.errors?.length ? [data.errors[0]] : null
      state.errorData.messages = error;

      if (!error) {
        showErrorToast("Ocorreu um erro ao logar. Verifique seus dados e tente novamente.");
      }
    };

    return {
      state,
      submitForm,
      onLoginSucess,
      onLoginError
    };
  },
};
</script>

<style lang="scss" src="./LoginForm.scss" />
