<template>
  <Modal
    title="Excluir categoria"
    buttonType="alert"
    @close="onClose"
    @cancel="onCancel"
    :fetch-action="handleRemoveCategory"
    @fetch-success="onRemoveCategorySuccess"
    @fetch-error="onRemoveCategoryError"
  >
    <template #default>
      <div class="remove-category-modal">
        <div class="remove-category-modal__confirm">
          <p>
            Tem certeza que deseja excluir a categoria <b>{{ categoryName }}</b>?
          </p>
          <p>
            Ao remover esta categoria, todas as transações associadas serão automaticamente movidas para a categoria "Outros".
          </p>
          <p>Esta ação não poderá ser desfeita.</p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import { removeCategory } from "../../services/category/categoryService";
import { showErrorToast, showSuccessToast } from '@/services/toastService';

export default {
  components: { Modal },
  props: {
    categoryId: Number,
    categoryName: String,
  },
  setup(props, { emit }) {
    const handleRemoveCategory = () => {
      return removeCategory(props.categoryId);
    };

    const onRemoveCategorySuccess = () => {
      showSuccessToast("Categoria excluída com sucesso!")
      emit('success')
      onClose()
    };

    const onRemoveCategoryError = () => {
      showErrorToast("Ocorreu um erro ao excluir a categoria. Tente novamente.")
      onClose()
    };

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    return {
      onCancel,
      onClose,
      handleRemoveCategory,
      onRemoveCategorySuccess,
      onRemoveCategoryError
    };
  },
};
</script>

<style lang="scss" src="./RemoveCategoryModal.scss" />
