<template>
  <Modal title="Adicionar depósito" class="create-goal-deposit-modal" :fetch-action="handleSaveDeposit"
    @fetch-success="onSaveGoalSuccess" @fetch-error="onSaveGoalError" @close="onClose" @cancel="onCancel">
    <template #default>
      <form action="#" @submit.prevent>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6">
                  <currency-input label="Valor (R$)" :value="state.goal.value" v-model="state.goal.value" required
                    name="value" />
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <label for="data-input">Data</label>
                    <datepicker v-model="state.goal.date" :value="state.goal.date" name="data-input"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive, computed } from "vue";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { saveDeposit } from "../../services/goal/goalService";
import { getCurrentDate } from "@/modules/common/services/date/dateService";
import Modal from "@/components/Modal/Modal.vue";
import CurrencyInput from "@/components/CurrencyInput/CurrencyInput.vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";

export default {
  components: { Modal, CurrencyInput, Datepicker },
  props: {
    goalId: String
  },
  setup(props, { emit }) {
    const state = reactive({
      goal: {
        goalId: props.goalId,
        value: 0,
        date: getCurrentDate(),
      },
    });

    const isFormValid = computed({
      get() {
        return state.goal.value && state.goal.date;
      },
    });

    const handleSaveDeposit = () => {
      if (!isFormValid.value) {
        return;
      }

      return saveDeposit(state.goal);
    };

    const onSaveGoalSuccess = () => {
      showSuccessToast(`Depósito criado com sucesso!`);
      emit("success");
      onClose();
    };

    const onSaveGoalError = () => {
      showErrorToast("Ocorreu um erro ao salvar o depósito. Tente novamente.");
    };

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    return {
      state,
      isFormValid,
      onCancel,
      onClose,
      handleSaveDeposit,
      onSaveGoalSuccess,
      onSaveGoalError,
    };
  },
};
</script>

<style lang="scss" src="./CreateGoalDepositModal.scss" />