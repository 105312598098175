<template>
  <form class="change-password-form" @submit.prevent.stop>
    <div class="change-password-form__content">
      <box-information
        v-if="state.hasError"
        title="Corrija os erros e tente novamente."
        type="error"
        :messages="state.errorMessages"
      />

      <custom-input
        label="Senha antiga"
        type="password"
        :value="state.oldPassword"
        v-model="state.oldPassword"
        required
        name="oldPassword"
      />
      <password-input ref="passwordInputRef" @setPassword="setPassword" />

      <div class="change-password-form__actions">
        <request-button
          class="change-password-form__btn-update"
          :fetch-action="handleChangePassword"
          @fetch-success="onChangePasswordSuccess"
          @fetch-error="onChangePasswordError"
          :disabled="!isFormValid"
          label="Alterar senha" />
      </div>
    </div>
  </form>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { changePassword } from "../../services/userService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import BoxInformation from "@/components/BoxInformation/BoxInformation.vue";
import PasswordInput from "@/components/PasswordInput/PasswordInput.vue";
import RequestButton from "@/components/RequestButton/RequestButton.vue";

export default {
  components: { CustomInput, BoxInformation, PasswordInput, RequestButton },
  setup() {
    const passwordInputRef = ref(null);
    const state = reactive({
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
      hasError: false,
      errorMessages: []
    });

    const isFormValid = computed({
      get() {
        return (
          state.oldPassword &&
          state.newPassword &&
          state.newPasswordConfirmation
        );
      },
    });

    const setPassword = (password) => {
      state.newPassword = password;
      state.newPasswordConfirmation = password;
    }

    const handleChangePassword = () => {
      if (!isFormValid.value) {
        return;
      }

      state.hasError = false;
      state.errorMessages = []

      const { oldPassword, newPassword, newPasswordConfirmation } = state;
      const formData = {
        oldPassword,
        newPassword,
        newPasswordConfirmation,
      };

      return changePassword(formData);
    };

    const onChangePasswordSuccess = () => {
      showSuccessToast("Senha alterada com sucesso!");
      resetForm();
    };

    const onChangePasswordError = (data) => {
      showErrorToast("Ocorreu um erro, tente novamente.");
      state.errorMessages = Object.values(data?.errors);
      state.hasError = !!data.errors;
    };

    const resetForm = () => {
      state.oldPassword = null;
      state.newPassword = null;
      state.newPasswordConfirmation = null;
      passwordInputRef.value.resetValues();
    }

    return {
      state,
      isFormValid,
      passwordInputRef,
      handleChangePassword,
      setPassword,
      onChangePasswordSuccess,
      onChangePasswordError
    };
  },
};
</script>
  
<style lang="scss" src="./ChangePasswordForm.scss" />