export const GOAL_TRANSACTION_TYPE = Object.freeze({
    DEPOSIT: {
        name: 'Depósito',
        code: 0
    },
    WITHDRAWAL: {
        name: 'Retirada',
        code: 1
    }
});
