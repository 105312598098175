import * as checkoutResource from './checkoutResource'

export async function getProducts() {
    const { data } = await checkoutResource.getProducts();

    return data;
}

export async function createSession(params) {
    const { data } = await checkoutResource.createSession(params);

    return data;
}