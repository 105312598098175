<template>
    <div class="totals-categories-chart">
        <loader v-if="state.isLoading" />

        <div v-else-if="emptyData" class="totals-transactions-chart__empty">
            <img src="../../../../assets/images/illustrations/empty-list.webp"
                class="totals-transactions-chart__empty-img" />
            <p>Não existem dados para exibir</p>
        </div>

        <apexchart v-else-if="!emptyData" type="pie" width="380" :options="state.chartOptions" :series="state.series">
        </apexchart>
    </div>
</template>

<script>
import { computed, onMounted, reactive, watch, onUnmounted } from "vue";
import { eventBus } from "@/services/eventBusService";
import { getCategoryBalance } from "../../services/transactionsService";
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import Loader from "@/components/Loader/Loader.vue";

export default {
    components: { Loader },
    props: {
        type: Number,
    },
    setup(props) {
        const filtersStore = useTransactionsFiltersStore();

        const state = reactive({
            isLoading: true,
            series: [],
            chartOptions: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                tooltip: {
                    enabled: true,
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    offsetX: 0,
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
            },
        });

        const emptyData = computed({
            get() {
                return !state.series.length && !state.isLoading
            }
        })

        const reloadDataHandler = () => {
            fetchData()
        }

        onMounted(() => {
            eventBus.on('reloadData', reloadDataHandler)
            fetchData();
        })

        onUnmounted(() => {
            eventBus.off('reloadData', reloadDataHandler)
        })

        watch(() => filtersStore.getTransactionsFilters, () => fetchData(), { deep: true });

        const fetchData = () => {
            const filters = filtersStore.getTransactionsFilters;
            filters.Type = props.type;

            state.isLoading = true;
            getCategoryBalance(filters)
                .then(onFetchSuccess)
                .catch(onFetchError)
        }

        const onFetchSuccess = (data) => {
            const balance = data?.balance;
            const names = [];
            const values = [];
            const colors = [];

            for (const category in balance) {
                const { name, value, color } = balance[category];
                names.push(name);
                values.push(value);
                colors.push(color);
            }

            state.series = values;
            state.chartOptions.labels = names;
            state.chartOptions.colors = colors;

            state.isLoading = false;
        }

        const onFetchError = () => {
            state.isLoading = false;
        }

        return {
            state,
            emptyData
        };
    },
};
</script>

<style lang="scss" src="./TotalsCategoriesChart.scss" />
