<template>
    <button class="add-button btn">
        <fa-icon icon="fa-solid fa-plus" />
        {{ label }}
    </button>
</template>

<script>
export default {
    props: {
        label: String
    }
}
</script>

<style lang="scss" src="./AddButton.scss" />
