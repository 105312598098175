export const HAS_TOUR_LIST = Object.freeze(['/', '/finance', '/finance/revenues', '/finance/expenses'])

export const DASHBOARD_STEPS = Object.freeze([
    {
        popover: {
            title: "Bem-vindo à Calculando Riquezas!",
            description: "Simplifique sua gestão financeira com nossa plataforma. Acompanhe suas receitas, despesas e muito mais em um só lugar. Clique em próximo e conheça a plataforma!"
        }
    },
    {
        element: '.side-menu',
        popover: {
            title: "Menu Lateral",
            description: "Use este menu para acessar diferentes páginas da plataforma, como visão geral, receitas, despesas e objetivos. Navegue facilmente por todas as funcionalidades disponíveis."
        }
    },
    {
        element: '.install-app-banner',
        popover: {
            title: "Instalar App",
            description: "Clique aqui para instalar nosso aplicativo e tenha acesso rápido à plataforma diretamente da sua tela inicial, sem precisar abrir o navegador."
        }
    },
    {
        element: '.toggle-currency-visibility-button',
        popover: {
            title: "Mostrar/Ocultar Valores",
            description: "Os valores da sua conta são ocultos por padrão para proteger a sua privacidade. Para visualizar os valores, basta clicar aqui. Isso ajuda a manter suas finanças mais seguras."
        }
    },
    {
        element: '.user-resume',
        popover: {
            title: "Menu Superior",
            description: "Aqui você encontra informações sobre sua conta, suporte e plano de assinatura. Gerencie suas configurações pessoais e obtenha ajuda sempre que precisar."
        }
    },
    {
        element: '#init-tour',
        popover: {
            title: "Explore o sistema",
            description: "Descubra como usar os recursos da plataforma. Se houver um tutorial disponível, você encontrará o botão ao lado do título da tela."
        }
    },
    {
        element: '#dashboard-balance',
        popover: {
            title: "Visão geral do balanço anual",
            description: "Aqui você encontra o resumo total das suas receitas, despesas e o balanço anual (receitas menos despesas)."
        }
    },
    {
        element: '#current-filters',
        popover: {
            title: "Filtros aplicados",
            description: "Aqui você pode ver os filtros que estão atualmente aplicados aos dados financeiros exibidos."
        }
    },
    {
        element: '#dashboard-transactions-chart',
        popover: {
            title: "Gráfico de histórico financeiro anual",
            description: "Este gráfico exibe a evolução das suas receitas e despesas ao longo do ano, ajudando a visualizar o balanço financeiro anual."
        }
    }
]);

export const FINANCE_RESUME_STEPS = Object.freeze([
    {
        element: '#finance-balance',
        popover: {
            title: "Visão geral do balanço do período",
            description: "Este resumo apresenta o total das suas receitas, despesas e o balanço do período (receitas menos despesas)."
        }
    },
    {
        element: '.toggle-currency-visibility-button',
        popover: {
            title: "Mostrar/Ocultar Valores",
            description: "Os valores da sua conta são ocultos por padrão para proteger a sua privacidade. Para visualizar os valores, basta clicar aqui. Isso ajuda a manter suas finanças mais seguras."
        }
    },
    {
        element: '#current-filters',
        popover: {
            title: "Filtros aplicados",
            description: "Aqui você pode ver os filtros que estão atualmente aplicados aos dados financeiros exibidos."
        }
    },
    {
        element: '#monthly-filter',
        popover: {
            title: "Filtro mensal",
            description: "Use este seletor para escolher o mês e filtrar os dados financeiros exibidos."
        }
    },
    {
        element: '#advanced-filters',
        popover: {
            title: "Filtros avançados",
            description: "Aqui você pode aplicar filtros adicionais para refinar a visualização dos dados financeiros de acordo com suas necessidades."
        }
    },
    {
        element: '#add-transaction',
        popover: {
            title: "Adicionar nova transação",
            description: "Clique aqui para registrar uma nova transação financeira, como receitas ou despesas, no sistema."
        }
    },
    {
        element: '#income-categories-chart',
        popover: {
            title: "Gráfico de receitas por categorias",
            description: "Este gráfico mostra a distribuição das suas receitas por categorias durante o período selecionado, facilitando a análise das fontes de receita."
        }
    },
    {
        element: '#expenses-categories-chart',
        popover: {
            title: "Gráfico de despesas por categorias",
            description: "Visualize a distribuição das suas despesas por categorias no período selecionado, permitindo uma análise detalhada dos seus gastos."
        }
    },
    {
        element: '#transactions-list',
        popover: {
            title: "Lista de receitas e despesas",
            description: "Aqui você pode visualizar a lista completa de todas as receitas e despesas registradas no período selecionado."
        }
    }
]);

export const REVENUES_RESUME_STEPS = Object.freeze([
    {
        element: '#revenues-balance',
        popover: {
            title: "Visão geral das receitas do período",
            description: `Este resumo apresenta o total das suas receitas pendentes, receitas recebidas e
                          total do período (receitas pendentes mais receitas recebidas).`
        }
    },
    {
        element: '.toggle-currency-visibility-button',
        popover: {
            title: "Mostrar/Ocultar Valores",
            description: "Os valores da sua conta são ocultos por padrão para proteger a sua privacidade. Para visualizar os valores, basta clicar aqui. Isso ajuda a manter suas finanças mais seguras."
        }
    },
    {
        element: '#monthly-filter',
        popover: {
            title: "Filtro mensal",
            description: "Use este seletor para escolher o mês e filtrar os dados financeiros exibidos."
        }
    },
    {
        element: '#advanced-filters',
        popover: {
            title: "Filtros avançados",
            description: "Aqui você pode aplicar filtros adicionais para refinar a visualização dos dados financeiros de acordo com suas necessidades."
        }
    },
    {
        element: '#add-transaction',
        popover: {
            title: "Adicionar nova receita",
            description: "Clique aqui para registrar uma nova receita no sistema."
        }
    },
    {
        element: '#transactions-list',
        popover: {
            title: "Lista de receitas do período",
            description: "Aqui você pode visualizar a lista completa de todas as receitas registradas no período selecionado."
        }
    },
    {
        element: '#order-list',
        popover: {
            title: "Clique no tipo de dado para ordenar a tabela",
            description: "Clique no cabeçalho das colunas da tabela para ordenar os dados de acordo com o tipo selecionado."
        }
    }
]);

export const EXPENSES_RESUME_STEPS = Object.freeze([
    {
        element: '#expenses-balance',
        popover: {
            title: "Visão geral do balanço do período",
            description: `Este resumo apresenta o total das suas despesas pendentes, despesas pagas e
                          total do período (despesas pendentes mais despesas pagas).`
        }
    },
    {
        element: '.toggle-currency-visibility-button',
        popover: {
            title: "Mostrar/Ocultar Valores",
            description: "Os valores da sua conta são ocultos por padrão para proteger a sua privacidade. Para visualizar os valores, basta clicar aqui. Isso ajuda a manter suas finanças mais seguras."
        }
    },
    {
        element: '#monthly-filter',
        popover: {
            title: "Filtro mensal",
            description: "Use este seletor para escolher o mês e filtrar os dados financeiros exibidos."
        }
    },
    {
        element: '#advanced-filters',
        popover: {
            title: "Filtros avançados",
            description: "Aqui você pode aplicar filtros adicionais para refinar a visualização dos dados financeiros de acordo com suas necessidades."
        }
    },
    {
        element: '#add-transaction',
        popover: {
            title: "Adicionar nova despesa",
            description: "Clique aqui para registrar uma nova despesa no sistema."
        }
    },
    {
        element: '#transactions-list',
        popover: {
            title: "Lista de despesas do período",
            description: "Aqui você pode visualizar a lista completa de todas as despesas registradas no período selecionado."
        }
    },
    {
        element: '#order-list',
        popover: {
            title: "Clique no tipo de dado para ordenar a tabela",
            description: "Clique no cabeçalho das colunas da tabela para ordenar os dados de acordo com o tipo selecionado."
        }
    }
]);

export const TOURS_MAP = Object.freeze({
    '/': DASHBOARD_STEPS,
    '/finance': FINANCE_RESUME_STEPS,
    '/finance/revenues': REVENUES_RESUME_STEPS,
    '/finance/expenses': EXPENSES_RESUME_STEPS
})
