<template>
  <div class="account-data-form">
    <div class="account-data-form__content">
      <box-information
        v-if="state.hasError"
        title="Corrija os erros e tente novamente."
        type="error"
        :messages="state.errorMessages"
      />

     <form autocomplete="off" @submit.prevent>
      <custom-input :value="state.name" label="Nome" v-model="state.name" required name="name" />
      <custom-input
        label="E-mail"
        v-model="state.email"
        :value="state.email"
        disabled
        name="email"
        :custom-validations="state.emailValidations"
        autocomplete="off"
      />
      <cpf-input
        label="CPF"
        v-model="state.cpf"
        :value="state.cpf"
        name="cpf"
        autocomplete="off"
      />
      <custom-input
        label="Telefone (WhatsApp)"
        v-model="state.phoneNumber"
        :value="state.phoneNumber"
        v-mask="'(##) # ####-####'"
        name="phone"
        autocomplete="off"
      />
      <div class="form-group">
        <label for="dateOfBirth">Data de nascimento</label>
        <datepicker v-model="state.dateOfBirth" :value="state.dateOfBirth" name="dateOfBirth"/>
      </div>
      <div class="account-data-form__communications">
          <input v-model="state.allowsCommunication" type="checkbox" id="communications" name="communications">
          <span>Aceito receber comunicações de novidades e promoções</span>
      </div>
     </form>
      <div class="account-data-form__actions">
        <request-button
          class="account-data-form__btn-update"
          :fetch-action="handleUpdate"
          @fetch-success="onUpdateSucess"
          @fetch-error="onUpdateError"
          label="Salvar" />
      </div>
    </div>
  </div>
</template>
  
<script>
import { computed, onMounted, reactive, watch } from "vue";
import { useUserDataStore } from "@/store/userData";
import { updateUser } from "../../services/userService";
import { emailValidator } from "@/services/validationsService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import CpfInput from "@/components/CpfInput/CpfInput.vue";
import BoxInformation from "@/components/BoxInformation/BoxInformation.vue";
import RequestButton from "@/components/RequestButton/RequestButton.vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";

export default {
  components: { CustomInput, BoxInformation, RequestButton, CpfInput, Datepicker },
  setup() {
    const userStore = useUserDataStore();
    const state = reactive({
      name: null,
      email: null,
      cpf: null,
      dateOfBirth: null,
      phoneNumber: null,
      allowsCommunication: false,
      emailValidations: [
        {
          message: "E-mail inválido",
          valid: (email) => emailValidator(email),
        },
      ],
      hasError: false,
      errorMessages: []
    });

    const isFormValid = computed({
      get() {
        return state.name && state.email;
      },
    });

    const userData = computed(() => userStore.getUserEmail);

    watch(userData, () => {
      handleUserData();
    })

    onMounted(() => {
      handleUserData();
    });

    const handleUserData = () => {
      const { name, email, cpf, dateOfBirth, allowsCommunication, phoneNumber } = userStore.getUserData;

      state.name = name;
      state.email = email;
      state.cpf = cpf;
      state.phoneNumber = phoneNumber;
      state.dateOfBirth = dateOfBirth;
      state.allowsCommunication = allowsCommunication;
    };

    const handleUpdate = () => {
      if (!isFormValid.value) {
        return;
      }

      state.hasError = false;
      state.errorMessages = []

      const birthDate = state.dateOfBirth ? new Date(state.dateOfBirth) : null;

      const formData = {
        name: state.name,
        cpf: state.cpf?.replaceAll('.', '').replace('-', '') || null,
        dateOfBirth: birthDate,
        phoneNumber: state.phoneNumber?.replace(/[\s()-]/g, '') || null,
        allowsCommunication: state.allowsCommunication
      };

      return updateUser(formData);
    };

    const onUpdateSucess = (data) => {
      userStore.setUserData(data)
      showSuccessToast("Dados alterados com sucesso!");
    };

    const onUpdateError = (data) => {
      showErrorToast("Ocorreu um erro, tente novamente.");
      state.errorMessages = Object.values(data?.errors);
      state.hasError = !!data.errors;
    };

    return {
      state,
      handleUpdate,
      onUpdateSucess,
      onUpdateError
    };
  },
};
</script>
  
<style lang="scss" src="./AccountDataForm.scss" />
  