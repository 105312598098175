<template>
    <transactions-list-mobile v-if="isMobileDevice" :type="type"/>
    <transactions-list v-else :type="type"/>
</template>

<script>
import { computed } from 'vue';
import { isMobile } from '@/services/deviceService';
import TransactionsList from '../TransactionsList/TransactionsList.vue';
import TransactionsListMobile from '../TransactionsListMobile/TransactionsListMobile.vue';

export default {
    components: { TransactionsList, TransactionsListMobile },
    props: {
        type: Number,
    },
    setup() {
        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        return {
            isMobileDevice
        }
    }
}
</script>
