<template>
    <div class="user-plan-view">
        <box-page title="Meus planos">
            <loader v-if="state.isLoading" />
            <template v-if="!state.isLoading && state.shouldShowCards">
                <h3 class="user-plan-view__title">Seu plano ativo</h3>
                <div class="user-plan-view__content">
                    <premium-plan-box v-if="shouldShowPremiumBox" :subscription-data="state.activeSubscription" @refresh-plans="reloadData"/>
                    <free-plan-box v-else />
                </div>
            </template>
            <div class="user-plan-view__empty" v-if="!state.isLoading && !state.shouldShowCards">
                <img src="@images/illustrations/error-person.svg" class="user-plan-view__empty-img" />
                <p class="user-plan-view__empty-text">Ocorreu um erro ao buscar a assinatura. Tente novamente mais tarde ou entre em contato pelo suporte.</p>
            </div> 
        </box-page>
        <box-page title="Assinaturas inativas" v-if="shouldShowCanceledSubscriptions && !state.isLoading">
                <div class="user-plan-view__content user-plan-view__canceled">
                    <premium-plan-box v-for="sub in state.canceledSubscriptions" :key="sub.id" :subscription-data="sub" hide-infos/>
                </div>
        </box-page>
    </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { useUserDataStore } from "@/store/userData";
import { useRouter } from "vue-router";
import { refreshLogin } from '../services/authService';
import { getUserData } from "@/modules/user/services/userService";
import { getSubscriptions } from '../services/billingService';
import { showErrorToast } from "@/services/toastService";
import { getSessionItem, deleteSessionItem } from '@/services/sessionStorageService';
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import BoxPage from '@/components/BoxPage/BoxPage.vue';
import FreePlanBox from '../components/FreePlanBox/FreePlanBox.vue';
import PremiumPlanBox from '../components/PremiumPlanBox/PremiumPlanBox.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
    components: { BoxPage, FreePlanBox, PremiumPlanBox, Loader },
    setup() {
        const userStore = useUserDataStore();
        const router = useRouter();
        const state = reactive({
            subscriptions: [],
            canceledSubscriptions: [],
            activeSubscription: {},
            shouldShowCards: false,
            isLoading: true
        })

        const shouldShowPremiumBox = computed({
            get() {
                return userStore.isPremiumUser;
            }
        })

        onMounted(() => {
            handleGetSubscriptions();

            const routeParam = router.currentRoute.value.query.subscription

            if(routeParam === 'successfully') {
                const value = getSessionItem('productPrice');
                const decodedValue = atob(value) || 0;
                sendPixelTrack('Purchase', { currency: 'BRL', amount: Number(decodedValue)});
                deleteSessionItem('productPrice');
                reloadData();
            }
        })

        const shouldShowCanceledSubscriptions = computed({
            get() {
                return state.canceledSubscriptions.length;
            }
        })

        const reloadData = () => {
            refreshLogin();
            handleUserData();
            handleGetSubscriptions();
        }

        const handleUserData = () => {
            getUserData().then(getUserDataSuccess);
        };

        const getUserDataSuccess = (data) => {
            userStore.setUserData(data);
        };

        const handleGetSubscriptions = () => {
            return getSubscriptions().then(onGetSubscriptionsSuccess).catch(onGetSubscriptionsError)
        }

        const onGetSubscriptionsSuccess = (data) => {
            state.subscriptions = data?.subscriptions;
            state.canceledSubscriptions = data?.subscriptions.filter(sub => sub.status?.toUpperCase() !== "ACTIVE")
            state.activeSubscription = data?.subscriptions[0];
            state.shouldShowCards = true;
            state.isLoading = false;
        };

        const onGetSubscriptionsError = () => {
            state.isLoading = false;
            showErrorToast('Ocorreu um erro ao buscar a assinatura. Tente novamente.')
        }

        return {
            state,
            shouldShowPremiumBox,
            shouldShowCanceledSubscriptions,
            reloadData
        }
    }
}
</script>

<style lang="scss" src="./styles/UserPlanView.scss" />
