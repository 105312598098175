import * as planningResource from './planningResource'

export async function getPlanningList(params) {
    const { data } = await planningResource.getPlanningList(params);

    return data;
}

export async function getPlanningById(id) {
    const { data } = await planningResource.getPlanningById(id);

    return data;
}

export async function getLastPlanning(id) {
    const { data } = await planningResource.getLastPlanning(id);

    return data;
}

export async function savePlanning(params) {
    const { data } = await planningResource.savePlanning(params);

    return data;
}

export async function updatePlanning(id, params) {
    const { data } = await planningResource.updatePlanning(id, params);

    return data;
}

export async function removePlanning(id) {
    return await planningResource.removePlanning(id);
}

export async function getDefaultPlanning(params) {
    const { data } = await planningResource.getDefaultPlanning(params);

    return data;
}

export async function getBudgetTransactions(id) {
    const { data } = await planningResource.getBudgetTransactions(id);

    return data;
}

export function getAvailablesCategories(planningCategories, categories) {
    if (!categories.length) {
        return;
    }

    const planningCategoryIds = planningCategories?.map(category => category.categoryId);
    return categories.filter(category => !planningCategoryIds?.includes(category.id));
}

export function buildPlanningBudgets(budgets) {
    if (!budgets) {
        return;
    }

    return budgets.map(budget => {
        return {
            id: budget.id,
            categoryId: budget.categoryId,
            percent: budget.savings || 0,
            value: budget.value,
            currentValue: budget.currentValue,
            categoryData: budget.categoryData
        }
    })
}

export function planningValueValidator(budgets, value) {
    if (!budgets.length) {
        return true;
    }

    const totalBudgetValue = budgets.reduce((total, budget) => total + budget.value, 0);
    return totalBudgetValue < value;
}