<template>
  <div class="edit-goal-view">
    <box-page v-if="state.isLoading">
      <loader />
    </box-page>
    <template v-else>
      <box-page class="box-page--visible">
        <div class="edit-goal-view__goal-header goal-header">
          <div class="goal-header__infos">
            <div class="goal-header__icon" :style="`background-color: ${state.goal.color}`">
              <fa-icon :icon="`fa-solid ${state.goal.icon}`" />
            </div>
              <h3 class="goal-header__name" :style="`color: ${state.goal.color}`">{{ state.goal.name }}</h3>
          </div>
        </div>
        <div class="edit-goal-view__goal">
          <div class="edit-goal-view__form">
            <edit-goal-form :goal-data="state.goal" @refresh-data="getGoalData" />
          </div>
          <div class="edit-goal-view__progress">
            <progress-circle class="goal-header__progress" :current-value="state.goal.currentValue"
              :total-value="state.goal.value" show-progress-label />
          </div>
        </div>
      </box-page>
      <box-page title="Histórico de transações">
        <data-table v-if="!state.isLoadingHistory" :is-loading="state.isLoadingHistory" :columns="getColumns"
          :data="state.goal.transactions" hideFilters :show-pagination="false" :register-per-page-qty="1000"
          sorted-by="date">
          <template #row="{ row }">
            <td id="type">
              <goal-transaction-type-badge :type="row.type" />
            </td>
            <td id="value">
              <toggle-currency-visibility :value="row.value" />
            </td>
            <td id="date">{{ formatDate(row.date) }}</td>
            <td id="actions">
              <button class="btn btn-remove button-outline small" @click="removeTransaction(row)">Excluir</button>
            </td>
          </template>
        </data-table>
      </box-page>
    </template>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { getGoalById, removeGoalTransaction } from "../services/goal/goalService";
import { formatFullDate } from "@/services/formatService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useRouter } from 'vue-router';
import { useModal } from "vue-final-modal";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import Loader from '@/components/Loader/Loader.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import GoalTransactionTypeBadge from "../components/GoalTransactionTypeBadge/GoalTransactionTypeBadge.vue";
import EditGoalForm from '../components/EditGoalForm/EditGoalForm.vue';
import ProgressCircle from '@/modules/common/components/ProgressCircle/ProgressCircle.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';
import ToggleCurrencyVisibility from "@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue";

export default {
  components: { BoxPage, Loader, DataTable, GoalTransactionTypeBadge, EditGoalForm, ProgressCircle, ToggleCurrencyVisibility },
  setup() {
    const router = useRouter();

    const getColumns = [
      {
        name: "Tipo",
        key: "type",
        sortable: true,
      },
      {
        name: "Valor",
        key: "value",
        sortable: true,
      },
      {
        name: "Data",
        key: "date",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ];

    const state = reactive({
      goal: {},
      isLoading: true,
      isLoadingHistory: true,
      transactionToRemoveId: null
    });

    onMounted(() => {
      getGoalData();
    })

    const getGoalData = () => {
      const goalId = router.currentRoute.value.params.id;
      state.isLoadingHistory = true;
      getGoalById(goalId)
        .then(onGetGoalSuccess)
        .catch(onGetGoalError)
    }

    const onGetGoalSuccess = (data) => {
      state.goal = data;
      state.isLoading = false;
      state.isLoadingHistory = false;
    }

    const onGetGoalError = () => {
      state.isLoading = false;
      state.isLoadingHistory = false;
      showErrorToast('Ocorreu um erro ao buscar o objetivo. Tente novamente.')
    }

    const formatDate = (date) => {
      return formatFullDate(new Date(date))
    }

    const removeTransaction = (transaction) => {
      state.transactionToRemoveId = transaction.id;

      openTransactionDeleteConfirmModal();
    }

    const onConfirmRemoveTransaction = () => {
      const goalId = state.goal.id;
      const transactionId = state.transactionToRemoveId;

      removeGoalTransaction(goalId, transactionId).then(removeGoalTransactionSuccess).catch(removeGoalTransactionError)
    }

    const removeGoalTransactionSuccess = () => {
      getGoalData();
      showSuccessToast('Transação excluída com sucesso!')
    }
    
    const removeGoalTransactionError = () => {
      showErrorToast('Ocorreu um erro ao excluir a transação. Tente novamente.')
    }

    const { open: openTransactionDeleteConfirmModal, close: closeTransactionDeleteConfirmModal } = useModal({
      component: ConfirmationModal,
      attrs: {
        buttonType: 'alert',
        title: 'Excluir transação',
        description: `Tem certeza de que deseja excluir a transação?`,
        confirmLabel: 'Excluir',
        onClose() {
          closeTransactionDeleteConfirmModal()
        },
        onConfirmAction() {
          onConfirmRemoveTransaction()
          closeTransactionDeleteConfirmModal()
        },
        onCancel() {
          closeTransactionDeleteConfirmModal()
        },
      }
    });

    return {
      state,
      getColumns,
      formatDate,
      getGoalData,
      removeTransaction
    }
  }
}
</script>

<style lang="scss" src="./styles/EditGoalView.scss" />
