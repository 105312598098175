<template>
  <Modal
    title="Excluir transação"
    :buttonType="buttonType"
    @close="onClose"
    @cancel="onCancel"
    @confirm="onConfirm"
  >
    <template #default>
      <div class="remove-imported-transaction-modal">
        <div class="remove-imported-transaction-modal__confirm">
          <p>
            Tem certeza que deseja excluir a transação
            <b>{{ nameTransaction }}</b
            >? <br />
            Após remover, essa ação não poderá ser desfeita.
          </p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";

export default {
  components: { Modal },
  props: {
    transactionId: String,
    nameTransaction: String,
    buttonType: String,
  },
  setup(props, { emit }) {
    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const onConfirm = () => {
      emit("confirm");
    };

    return {
      onConfirm,
      onCancel,
      onClose,
    };
  },
};
</script>

<style lang="scss" src="./RemoveImportedTransactionModal.scss" />
