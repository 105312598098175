<template>
  <div class="password-input">
    <custom-input @blur="verifyPasswords" @input="onShowPasswordRules" :label="label" type="password" v-model="state.password" :value="state.password" required name="password"
      autocomplete="off" />
    <custom-input @blur="verifyPasswords" :label="confirmLabel" type="password" v-model="state.confirmPassword" :value="state.confirmPassword" 
      required :message-error="state.passwordError"
      :class="{ 'success': state.passwordErrors.every(error => error.valid) }" name="confirm-password"
      autocomplete="off" />

    <div v-if="state.showPasswordRules && !state.isPasswordValid" class="password-input__rules">
      <span>A senha deve conter pelo menos:</span>

      <ul class="password-input__error-list">
        <li v-for="(error, index) in state.passwordErrors" :key="index" class="password-input__error-item"
          :class="{ 'password-input__error-item--done': error.valid }">{{ error.message }}</li>
      </ul>
    </div>
  </div>
</template>
  
<script>
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import { reactive } from "vue";

export default {
  components: { CustomInput },
  props: {
    label: {
      type: String,
      default: "Senha"
    },
    confirmLabel: {
      type: String,
      default: "Confirme a senha"
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      showPasswordRules: false,
      password: null,
      confirmPassword: null,
      showPasswordValidations: false,
      passwordErrors: [
        { message: "As senhas devem ser iguais.", valid: false },
        { message: "8 caracteres.", valid: false },
        { message: "Uma letra maiúscula.", valid: false },
        { message: "Um número.", valid: false },
        { message: "Um caractere especial.", valid: false },
      ],
      isPasswordValid: false
    });

    const validateLength = (password) => password?.length >= 8;
    const validateUppercase = (password) => /[A-Z]/.test(password);
    const validateNumber = (password) => /\d/.test(password);
    const validateSpecialChar = (password) => /[@$!%*?&]/.test(password);

    const verifyPasswords = () => {
      const password = state.password;
      const confirmPassword = state.confirmPassword;

      state.passwordErrors[0].valid = password && password === confirmPassword;

      state.passwordErrors[1].valid = validateLength(password);
      state.passwordErrors[2].valid = validateUppercase(password);
      state.passwordErrors[3].valid = validateNumber(password);
      state.passwordErrors[4].valid = validateSpecialChar(password);

      state.isPasswordValid = state.passwordErrors.every(x => x.valid);

      if (state.isPasswordValid) {
        setPassword();
      }
    };

    const setPassword = () => {
      emit('set-password', state.password)
    }

    const onShowPasswordRules = () => {
      state.showPasswordRules = true;
      state.isPasswordValid = false;
    }

    const resetValues = () => {
      state.password = null;
      state.confirmPassword = null;
    }

    return {
      state,
      resetValues,
      verifyPasswords,
      onShowPasswordRules
    };
  },
};
</script>
  
  <style lang="scss" src="./PasswordInput.scss" />