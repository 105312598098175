<template>
    <div class="goals-widget" :class="{ 'goals-widget--empty': isEmpty && !state.isLoading }">
        <loader v-if="state.isLoading" />
        <div v-if="!state.isLoading && isEmpty" class="goals-widget__empty">
            <div class="goals-widget__header">
                <h3 class="goals-widget__title">Objetivos</h3>
            </div>
            <p>Crie seu primeiro objetivo financeiro!</p>
            <router-link to="/finance/goals">
                <button class="btn button-primary">
                    Criar objetivo
                </button>
            </router-link>
        </div>
        <template v-if="!state.isLoading && !isEmpty">
            <div class="goals-widget__header">
                <div class="goals-widget__icon" :style="`background-color: ${state.goalData.color}`">
                    <fa-icon :icon="`fa-solid ${state.goalData.icon}`" />
                </div>
                <h3 class="goals-widget__title">Objetivo {{ state.goalData.name }}</h3>
            </div>
            <div class="goals-widget__content">
                <h4 class="goals-widget__value">
                    <toggle-currency-visibility :value="state.goalData.value" />
                </h4>
                <progress-bar class="goal-card__progress" :current-value="state.goalData.currentValue"
                    :total-value="state.goalData.value" />
            </div>
            <router-link to="/finance/goals" class="goals-widget__footer">
                Ver todos
                <fa-icon icon="fa-solid fa-arrow-right" />
            </router-link>
        </template>
    </div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue';
import { formatCurrency } from '@/services/currencyService';
import { formatStartAndEndDate } from '@/services/formatService';
import { getFirstGoal } from '../../services/goal/goalService';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';
import ProgressBar from '@/modules/common/components/ProgressBar/ProgressBar.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
    components: { ToggleCurrencyVisibility, ProgressBar, Loader },
    setup() {
        const state = reactive({
            goalData: {},
            isLoading: true,
        })

        const isEmpty = computed({
            get() {
                return !state.goalData?.id
            }
        })

        onMounted(() => {
            getGoalData();
        })

        const getGoalData = () => {
            state.isLoading = true;
            getFirstGoal().then(onGetGoalDataSuccess).catch(onGetGoalDataError);
        }

        const onGetGoalDataSuccess = (data) => {
            state.goalData = data?.items ? data.items[0] : [];
            state.isLoading = false;
        }
        const onGetGoalDataError = () => {
            state.isLoading = false;
        }

        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        const formatDate = (startDate, endDate) => {
            if (!startDate || !endDate) {
                return;
            }

            return formatStartAndEndDate(new Date(startDate), new Date(endDate));
        }

        return {
            state,
            isEmpty,
            formatDate,
            toCurrency
        }
    }
}
</script>

<style lang="scss" src="./GoalsWidget.scss" />
