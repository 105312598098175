import { defineStore } from 'pinia';

export const useUserTracerStore = defineStore({
    id: 'userTracer',
    state: () => ({
        tracers: []
    }),
    getters: {
        getTracer: (state) => {
            return (name) => state.tracers.find(tracer => tracer === name)
        },
    },
    actions: {
        setTracers(data) {
            this.tracers = data;
        },
    }
})
