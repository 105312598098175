<template>
  <div class="create-new-password-form">
    <p class="create-new-password-form__description">
      Crie uma nova senha para acessar sua conta
    </p>
    <div class="create-new-password-form__content">
      <form @submit.prevent.stop>
        <box-information v-if="state.hasError" title="Corrija os erros e tente novamente." type="error"
          :messages="state.errorMessages" />

        <password-input @setPassword="setPassword" label="Nova senha" confirmLabel="Confirme a nova senha" />

        <div class="create-new-password-form__actions">
          <request-button
            type="submit"
            class="account-data-form__btn-update"
            :fetch-action="handleResetPassword"
            @fetch-success="onResetPasswordSuccess"
            @fetch-error="onResetPasswordError"
            label="Alterar senha" />
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import { computed, reactive } from "vue";
import { resetPassword } from "../../services/userService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useRouter } from "vue-router";
import BoxInformation from "@/components/BoxInformation/BoxInformation.vue";
import PasswordInput from "@/components/PasswordInput/PasswordInput.vue";
import RequestButton from '@/components/RequestButton/RequestButton.vue';

export default {
  components: { BoxInformation, PasswordInput, RequestButton },
  props: {
    code: String,
    email: String,
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      newPassword: null,
      newPasswordConfirmation: null,
      hasError: false,
      errorMessages: []
    });

    const isFormValid = computed({
      get() {
        return (
          state.newPassword &&
          state.newPasswordConfirmation &&
          props.email && props.code
        );
      },
    });

    const setPassword = (password) => {
      state.newPassword = password;
      state.newPasswordConfirmation = password;
    }

    const handleResetPassword = () => {
      if (!isFormValid.value) {
        return;
      }

      state.hasError = false;
      state.errorMessages = []

      const { newPassword, newPasswordConfirmation } = state;
      const formData = {
        newPassword,
        newPasswordConfirmation,
        token: props.code,
        email: props.email
      };

      return resetPassword(formData);
    };

    const onResetPasswordSuccess = () => {
      showSuccessToast("Senha alterada com sucesso!");
      router.push("/login");
    };

    const onResetPasswordError = (data) => {
      showErrorToast("Ocorreu um erro, tente novamente.");
      state.errorMessages = Object.values(data?.errors);
      state.hasError = !!data.errors;
    };

    return {
      state,
      handleResetPassword,
      setPassword,
      onResetPasswordSuccess,
      onResetPasswordError
    };
  },
};
</script>
    
  <style lang="scss" src="./CreateNewPasswordForm.scss" />