<template>
    <div class="use-terms terms">
        <h1>Termos de uso</h1>
        <p>Estes Termos e Condições de Uso e Serviços (“Termos de Uso”), juntamente com a Política de Privacidade,
            descrevem os termos e condições aplicáveis aos serviços prestados pela Calculando Riquezas por meio da
            Plataforma (conforme abaixo definido).</p>
        <p>POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS DE USO. Eles abrangem informações relevantes sobre o serviço
            prestados pela Calculando Riquezas por meio da Plataforma, tais como (i) detalhamento dos serviços prestados
            pela Calculando Riquezas; (ii) detalhamento sobre a proteção de Dados Pessoais (conforme abaixo definido) e
            (iii) todas as demais informações necessárias concernentes a Calculando Riquezas, à Plataforma e ao Usuário
            (conforme abaixo definido).
        </p>
        <p>
            Ao marcar a opção referente à concordância e aceitação destes Termos de Uso você está anuindo com todas as
            suas condições e com a Política de Privacidade. Caso não esteja de acordo, não será possível utilizar a
            Plataforma, pois a sua utilização precede a aceitação integral a estes Termos de Uso, assim como a Política
            de Privacidade.</p>

        <h4>Definições</h4>
        <ul>
            <li><strong>Cadastro</strong> – significa o cadastro do Usuário feito no Site para ter acesso á plataforma
                de gestão financeira.</li>
            <li><strong>Código de Defesa do Consumidor</strong> – significa a Lei nº 8.078, de 11 de setembro de 1990,
                conforme alterada.</li>
            <li><strong>Conta de Usuário</strong> – significa a conta de Usuário criada após a realização do Cadastro.
            </li>
            <li><strong>Gestão financeira</strong> – tem o significado que lhe é atribuído no item I.</li>
            <li><strong>Conteúdos Calculando Riquezas</strong> – significa o Conteúdo Financeiro e com todo e qualquer
                outro conteúdo disponibilizado pela Calculando Riquezas em sua Plataforma.</li>
            <li><strong>CPF/ME</strong> - significa Cadastro da Pessoa Física do Ministério da Economia.</li>
            <li><strong>Dados Pessoais</strong> – significa toda informação relacionada a pessoa natural identificada ou
                identificável, nos termos da LGPD originados ou coletados pelas partes e transferidas para a outra parte
                para fins dos serviços da Calculando Riquezas previstos nestes Termos de Uso; ou aqueles coletados,
                gerados, ou de qualquer maneira tratados pela parte, por conta e em benefício da outra parte com relação
                aos Termos de Uso.</li>
            <li><strong>Direito de Propriedade Intelectual</strong> – tem o significado que lhe é atribuído no item
                VI.</li>
            <li><strong>Leis Aplicáveis</strong> - significa a LGPD e todas as leis, estatutos, normas e regulamentos
                promulgados por autoridade governamental e jurisprudência durante a vigência dos serviços da Calculando
                Riquezas tratados nestes Termos de Uso, na medida em que se apliquem às partes nos termos deste
                instrumento.</li>
            <li><strong>Lei nº 9.609</strong> – significa a Lei nº 9.609, de 19 de fevereiro de 1998, conforme alterada.
            </li>
            <li><strong>Lei nº 9.610</strong> – significa a Lei nº 9.610, de 19 de fevereiro de 1998, conforme alterada.
            </li>
            <li><strong>LGPD</strong> – significa a Lei nº 13.709, de 14 de agosto de 2018, conforme alterada.</li>
            <li><strong>Melhores Práticas do Mercado</strong> - significa normas, práticas, métodos e procedimentos
                conformes com as Leis Aplicáveis e o grau de capacidade e zelo, diligência, prudência e precaução que
                seria razoável e comumente esperado de operador qualificado e experiente participante de atividade
                semelhante em circunstâncias semelhantes.</li>
            <li><strong>Plano</strong> – significa cada um dos planos de assinatura de serviços pagos oferecidos na
                Plataforma pela Calculando Riquezas.</li>
            <li><strong>Plataforma</strong> – significa o Site, bem como os seus diversos ambientes que o Usuário terá
                acesso após o Cadastro ou contratação de um Plano.</li>
            <li><strong>Política de Privacidade</strong> – significa a política de privacidade da Calculando Riquezas
                prevista em seu Site.</li>
            <li><strong>Site</strong> – significa o endereço da rede mundial de computadores denominado <a
                    href="https://calculandoriquezas.com.br/">https://calculandoriquezas.com.br/</a>.</li>
            <li><strong>Calculando Riquezas</strong> – significa a CALCULANDO RIQUEZAS SISTEMAS DE SOFTWARE LTDA., com
                sede na Cidade de Chapecó, Estado de Santa Catarina, na Rua MARECHAL FLORIANO PEIXOTO, 145, LETRA L, CEP
                89.801-501, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia sob o nº
                55.684.517/0001-39.</li>
            <li><strong>Termos de Uso</strong> – significa estes Termos e Condições de Uso e Serviços.</li>
            <li><strong>Usuário</strong> – significa toda pessoa física ou jurídica que acessa a Plataforma.</li>
        </ul>

        <h4>Informações Preliminares</h4>
        <p>ESTE É UM CONTRATO FIRMADO ENTRE VOCÊ (de agora em diante denominado como usuário) E A CALCULANDO RIQUEZAS.
            ESTE TERMO DE USO REGE O USO DA PLATAFORMA. CASO VOCÊ NÃO CONCORDE COM ESTES TERMOS DE USO, OU PARTE DELES,
            VOCÊ NÃO DEVE UTILIZAR A PLATAFORMA.</p>
        <p>VOCÊ RECONHECE QUE ANALISOU E ACEITOU OS TERMOS DE USO. LEIA-OS ATENTAMENTE POIS O USO DA PLATAFORMA
            SIGNIFICA QUE VOCÊ ACEITOU TODAS AS DISPOSIÇÕES DESTES TERMOS DE USO E CONCORDA EM CUMPRI-LOS.</p>
        <p>Para todos os fins de direito, a Calculando Riquezas informa que o tratamento de todos os Dados Pessoais
            coletados pela Calculando Riquezas é feito de acordo com as diretrizes e princípios da LGPD. Para mais
            informações, acesse a nossa <a href="https://calculandoriquezas.com.br/politica-de-privacidade">Política de
                Privacidade</a>.</p>

        <h4>I – O que é a Calculando Riquezas?</h4>
        <p>A Calculando Riquezas é uma empresa que desenvolveu a Plataforma para ser uma ferramenta simplificada com a
            proposta de auxiliar a gestão financeira pessoal, para tanto, disponibiliza em sua plataforma diversas
            ferramentas de organização e acompanhamento financeiro.</p>
        <p>Algumas ferramentas serão disponibilizadas pela Calculando Riquezas de forma gratuita, enquanto outras
            deverão ser contratadas em nossos planos, conforme ofertas de serviços anunciadas pela Calculando Riquezas.
        </p>
        <p>A Plataforma, o aplicativo e as Ferramentas Calculando Riquezas só podem ser utilizados por pessoas físicas
            legalmente capazes. Assim, ao utilizar a Plataforma, o aplicativo e as Ferramentas Calculando Riquezas você
            declara e garante, por meio destes Termos de Uso, sob as penas da lei, estar em plena capacidade civil.</p>

        <h4>II – Ferramentas de gestão financeira</h4>
        <p>A partir da realização do Cadastro, o Usuário poderá ter acesso às ferramentas de gestão financeira disponibilizadas pela
            Calculando Riquezas.</p>
        <p>As ferramentas de gestão financeira poderão ser pagas ou gratuitas, conforme informações contidas no Site ou
            aplicativo.</p>
        <p>O Usuário deve inserir na Plataforma as informações sobre suas receitas, despesas, objetivos financeiros e
            outras informações para sua gestão financeira. Desse modo, o melhor aproveitamento das ferramentas depende
            das corretas informações fornecidas pelo Usuário.</p>
        <p>A Calculando Riquezas não se obriga e não se responsabiliza pela acurácia dos dados financeiros fornecidos,
            de modo que os dados financeiros poderão conter erros, omissões e inexatidão de informação.</p>
        <p>Todos os Dados Pessoais referentes aos dados financeiros serão protegidos pela Calculando Riquezas nos termos
            da LGPD.</p>
        <h4>III – Cadastro na Plataforma</h4>
        <p>Para o acesso na plataforma, o Usuário deverá realizar o Cadastro.</p>
        <p>O Cadastro será feito pelo Usuário no Site, com o correto preenchimento da ficha de cadastro e fornecimento
            dos dados solicitados, bem como a escolha de uma senha que será utilizada para o login na Plataforma. Para
            facilitar, o Usuário poderá, a seu exclusivo critério, utilizar o mesmo cadastro de sua conta nos serviços
            da gmail, com o que, caso assim o faça, desde já concorda com o compartilhamento de conta para fins de
            acesso.</p>
        <p>Ao realizar o Cadastro, a Calculando Riquezas concede uma conta com ferramentas de gestão financeira
            limitadas pelo plano gratuito, não-exclusiva e intransferível para o Usuário acessar as funcionalidades da
            Plataforma relacionadas aos determinados dados financeiros, sem prejuízo de eventual necessidade de
            contratação de Plano.</p>
        <p>Para assegurar os serviços prestados no âmbito dos dados financeiros, a Calculando Riquezas se reserva o
            direito de utilizar a dupla camada extra de segurança, onde o usuário tem que digitar um código enviado para
            poder continuar o acesso ao conteúdo, após determinado período de uso. Assim, o nível de segurança aumenta e
            é dada maior confiabilidade à operação que vinha sendo realizada no âmbito da plataforma.</p>
        <p>O Cadastro é individual e intransferível, assim como seu login e senha, que deverão ser guardados em local
            seguro.</p>
        <p>Ao realizar o Cadastro você concorda em receber comunicações por meio de sua Conta de Usuário ou por outros
            meios, inclusive por e-mail, para o qual a Calculando Riquezas poderá enviar ofertas ou qualquer outra
            atividade que considere pertinentes ao Usuário.</p>
        <p>São fornecidas informações pelo Usuário no âmbito do Cadastro, tais como (i) nome completo, (ii) endereço de
            e-mail, dentre outras, que deverão ser periodicamente e permanentemente atualizadas pelo Usuário.</p>
        <p>Todos os Dados Pessoais referentes ao Cadastro serão protegidos pela Calculando Riquezas nos termos da LGPD.
        </p>

        <h4>IV – Planos da Calculando Riquezas</h4>
        <p>As ferramentas e conteúdos Calculando Riquezas poderão ser gratuitos (disponibilizados ao público no geral, mediante
            Cadastro ou não) e pagos (cujo acesso é permitido mediante contratação de Plano), conforme ofertas de
            serviços anunciadas no Site ou aplicativo, que indicarão as condições gerais de acesso, além do preço e
            forma de pagamento.</p>
        <p>Em caso de opção por obter as ferramentas ilimitadas pagas, os Usuários poderão selecionar uma das opções de
            Planos disponíveis para a contratação pela Calculando Riquezas. O Site conterá, resumidamente, as
            condições gerais de cada um dos Planos, que terão ferramentas, valores e prazos diferentes.</p>
        <p>Selecionado o Plano Calculando Riquezas pago desejado, o Usuário será direcionado à finalização do pagamento,
            conforme opções de pagamento fornecidas no ato da finalização da compra.</p>
        <p>O acesso às Ferramentas Calculando Riquezas contratadas será sempre mediante fornecimento do login e senha,
            após comprovação de pagamento. Para obtenção da nota fiscal, o Usuário deve contatar a Calculando Riquezas
            por meio do e-mail <a href="mailto:contato@calculandoriquezas.com.br">contato@calculandoriquezas.com.br</a>
            e solicitar sua emissão. As devidas notas fiscais serão enviadas ao e-mail cadastrado do Usuário.</p>

        <h4>V – Renovação e Cancelamento</h4>
        <h5>Renovação Automática</h5>
        <p>Para conveniência de nossos Usuários, todos os Planos poderão ser renovados de forma automática, mesmo após o
            término do período vigente.</p>
        <p>A Calculando Riquezas enviará comunicação por e-mail ao Usuário, para informar sobre a data de encerramento
            dos Planos contratados e sua renovação por igual período.</p>
        <p>Caso o Usuário tenha não tenha interesse na renovação, bastará entrar na tela de assinaturas do Usuário e
            cancelar a renovação automática.</p>
        <p>Na renovação automática o valor a ser pago na renovação será de acordo com o preço de vigência do Plano
            escolhido na primeira compra.</p>
        <p>Caso haja mais de uma forma de pagamento cadastrada para pagamento do Plano, e visando a assegurar o acesso
            contínuo do Usuário à Plataforma, a Calculando Riquezas poderá efetuar a cobrança da renovação automática em
            qualquer um dos meios de pagamento cadastrados.</p>
        <p>Caso o cartão de crédito cadastrado pelo Usuário como único meio para pagamento seja recusado, a Calculando
            Riquezas reserva para si o direito de cancelar a contratação do Plano.</p>
        <h5>Cancelamento pela Calculando Riquezas</h5>
        <p>Com o objetivo de resguardar a Plataforma, bem como a segurança de todos os demais Usuários, a Calculando
            Riquezas reserva-se ao direito de providenciar, de forma imediata, a seu exclusivo critério e sem qualquer
            tipo de reembolso, o cancelamento do Plano contratado pelo Usuário que tenha violado os Termos de Uso e a
            Política de Privacidade, interrompendo assim o acesso do Usuário infrator à Plataforma e ao aplicativo,
            assim como o acesso de qualquer outro tipo de conteúdo, sem prejuízo ainda de serem todas adotadas todas as
            medidas judiciais necessárias, e que sejam compatíveis com a infração cometida.</p>
        <p>Ainda com o objetivo de resguardar o bom uso da Plataforma e segurança dos demais Usuários, a Calculando
            Riquezas se reserva no direito de excluir, imediatamente, o cadastro e de cancelar o Plano contratado pelo
            Usuário que prestar informações falsas em seu do perfil de Cadastro, não sendo devido o reembolso de valores
            já pagos.</p>
        <p>O Usuário poderá retificar os dados incorretos de seu Cadastro, após ser cientificado pela Calculando
            Riquezas, no prazo de 05 (cinco) dias, para com isso evitar o cancelamento imediato de seu Plano.</p>

        <h5>Cancelamento pelo Usuário</h5>
        <p>A qualquer momento, o Usuário poderá solicitar a verificação de um possível cancelamento de seu Cadastro e de
            seu Plano. </p>
        <p>Para isso, deverá acessar a tela de assinaturas do Usuário e efetuar o cancelamento, ou enviar um e-mail com
            a solicitação para <a href="mailto:contato@calculandoriquezas.com.br">contato@calculandoriquezas.com.br</a>
            ou acionar pelos canais de atendimento descritos na Plataforma. Nossa equipe seguirá com a tratativa da
            solicitação de cancelamento, nos termos abaixo indicados.</p>
        <p>Para cancelamentos realizados em até 7 (sete) dias da aquisição, renovação ou alteração do Plano, a
            Calculando Riquezas reembolsará integralmente o valor pago pelo Usuário, de acordo com o Código de Defesa do
            Consumidor.</p>
        <p>Com o objetivo de evitar fraudes e abuso no exercício de direitos, o Usuário não poderá efetuar a
            contratação, e cancelamento, sucessivos de Planos da Calculando Riquezas, sendo esta limitada a apenas 2
            (dois) cancelamentos durante o período experimental durante o período de 12 (doze meses).</p>
        <p>Caso o Usuário efetue, por mais de duas oportunidades no período de 12 (doze meses), contratações e
            cancelamentos sucessivos, será verificado o abuso quanto ao exercício no direito do cancelamento durante o
            período experimental, razão pela qual a Calculando Riquezas providenciará o cancelamento, mas não efetuará o
            reembolso de valores já pagos pelo Usuário.</p>
        <p>Privilegiando a transparência, a Calculando Riquezas destaca que adotará essas prerrogativas como meio de
            coibir o uso inadvertido de sua Plataforma ou de seu aplicativo, e reforça que possui um canal específico de
            atendimento ao usuário, cujo enfoque é informar e eventualmente destacar as características de seus Planos,
            privilegiando uma contratação adequada à desejada pelo Usuário.</p>
        <p>Após o período experimental de 7 (sete) dias, será dado início ao período de fidelidade, que se encerrará com
            o fim do prazo do Plano contratado.</p>
        <p>Durante o período de fidelidade, o Usuário poderá solicitar, a qualquer tempo, o cancelamento do Plano
            contratado, que resultará no cancelamento da renovação automática do mesmo, porém o valor pago não será
            reembolsado caso o mesmo tenha se dado ao final do período experimental, de 7 dias após a compra.</p>
        <p>O cancelamento da renovação automática poderá ser solicitado a qualquer momento, entretanto, o valor do plano
            vigente após período experimental não será reembolsado.</p>
        <p>No advento do cancelamento de um Plano mensal, caso este tenha sido realizado após o sétimo dia da data de
            contratação inicial do Plano, será cobrado o valor integral do mês e o Usuário terá acesso durante 30 dias
            desde o dia da contratação.</p>
        <p>Eventuais reembolsos de valores pagos pelos Usuários serão feitos o quanto antes pela Calculando Riquezas,
            observada a forma de pagamento selecionada pelo Usuário para aquisição do Plano, bem como os prazos e
            condições estabelecidos pelas instituições financeiras e operadoras de cartão de crédito, de acordo com suas
            próprias políticas.</p>
        <p>Em geral, o estorno acontecerá em um prazo máximo de 30 (trinta) dias. Para facilitar a efetivação do
            reembolso, a Calculando Riquezas poderá solicitar dados adicionais do Usuário, como, por exemplo, dados
            bancários.</p>

        <h4>VI – Declarações do Usuário</h4>
        <p>Mediante utilização da Plataforma e/ou do aplicativo, o Usuário declara e garante a Calculando Riquezas que:
        </p>
        <ul>
            <li>Está ciente e de acordo com a forma de cálculo e pagamento de remuneração da Calculando Riquezas, no
                caso da contratação de Plano;</li>
            <li>Está ciente de que é de sua exclusiva responsabilidade a devida contabilização das transações
                financeiras em suas declarações anuais de imposto de renda;</li>
            <li>Está ciente de que a Calculando Riquezas não se responsabiliza por decorrências negativas do Usuário em
                razão da utilização das Ferramentas Calculando Riquezas;</li>
            <li>Está ciente de acordo com as isenções de responsabilidade da Calculando Riquezas previstas nestes Termos
                de Uso;</li>
            <li>Está ciente de que a Calculando Riquezas não obriga e não se responsabiliza pela acurácia das
                Ferramentas Calculando Riquezas, de modo que as Ferramentas Calculando Riquezas poderão contar com
                inexatidões, omissões, erros e faltas de atualização;</li>
            <li>Possui plena capacidade civil;</li>
            <li>Está ciente e de acordo que as Ferramentas Calculando Riquezas não promovem qualquer garantia de sucesso
                ao Usuário, inclusive no âmbito da sua gestão financeira;</li>
            <li>Está ciente e de acordo que as informações das Ferramentas Calculando Riquezas não devem servir como
                único embasamento para decisões financeiras do Usuário e não refletem qualquer forma de recomendação de
                investimento;</li>
            <li>Está ciente que o tratamento de todos os Dados Pessoais (conforme abaixo definido) coletados pela
                Calculando Riquezas é feito de acordo com as diretrizes e princípios da LGPD;</li>
            <li>Está ciente e de acordo em receber comunicações da Calculando Riquezas, pelos meios de comunicação
                fornecidos pelo Usuário a Calculando Riquezas no âmbito da utilização dos serviços da Calculando
                Riquezas, referentes a ofertas de outros serviços e de produtos que considere pertinentes ao Usuário;
            </li>
            <li>Está e de acordo que a Plataforma deverá ser utilizada apenas pelo Usuário, de modo que a venda,
                transferência, modificação, engenharia reversa ou distribuição bem como a cópia de textos, imagens ou
                quaisquer partes neles contido é expressamente proibida, sendo responsabilizado o Usuário infrator penal
                e civilmente, inclusive mediante o pagamento de indenização por perdas e danos e demais obrigações
                legais decorrentes da violação do presente contrato.</li>
        </ul>
        <h4>VII – Obrigações do Usuário</h4>
        <p>Ao acessar a Plataforma e/ou do aplicativo da Calculando Riquezas, cada Usuário compromete-se a:</p>
        <ol>
            <li>Fornecer à Calculando Riquezas as informações solicitadas para o bom andamento da prestação dos
                serviços;</li>
            <li>Não criar ou utilizar mais do que 1 (um) perfil para utilizar a Plataforma, sendo vedada a criação de
                diversos perfis por um mesmo Usuário para o acesso às Ferramentas Calculando Riquezas;</li>
            <li>Não utilizar identificação de um terceiro ou permitir que terceiros utilizem sua identificação para
                acessar a Plataforma, nem violar qualquer restrição de acesso ou de disponibilidade das Ferramentas
                Calculando Riquezas;</li>
            <li>Respeitar todos as Ferramentas Calculando Riquezas, incluindo, mas não se limitando em matéria de
                direito da propriedade intelectual e direitos autorais;</li>
            <li>Não disfarçar ou ocultar o número de IP (internet protocol) que identifica sua conexão à internet para
                fins de acesso à Plataforma;</li>
            <li>Não interferir, de qualquer forma, inclusive por meio de outros programas de computador, no
                funcionamento da Plataforma e de qualquer outro sistema ou interface da Calculando Riquezas
                disponibilizada no âmbito da Plataforma;</li>
            <li>Não burlar qualquer tecnologia usada pela Calculando Riquezas ou por qualquer terceiro para proteger as
                Ferramentas Calculando Riquezas;</li>
            <li>Não modificar, ampliar, decompilar, reduzir, e/ou adaptar as Ferramentas Calculando Riquezas;</li>
            <li>Não copiar, não criar banco de dados, não reproduzir ou adaptar quaisquer informações a que tiver acesso
                e que sejam de propriedade da, ou pertencentes ao, Calculando Riquezas, inclusive se referentes às
                Ferramentas Calculando Riquezas, exceto nos estritos limites do que lhe for expressamente autorizado
                pela Calculando Riquezas;</li>
            <li>Não alterar ou copiar, bem como não desenvolver, criar ou patrocinar quaisquer programas que possam
                alterar ou copiar as Ferramentas Calculando Riquezas;</li>
            <li>Não interferir na arquitetura da Plataforma e/ou de qualquer sistema ou interface da Calculando
                Riquezas;</li>
            <li>Não disseminar vírus ou código de computador malicioso, arquivos ou programas desenvolvidos para coletar
                dados não autorizados da Plataforma e/ou qualquer outro sistema ou interface da Calculando Riquezas, bem
                como não interromper, destruir ou limitar a funcionalidade da Plataforma e/ou qualquer outro sistema ou
                interface da Calculando Riquezas;</li>
            <li>Não remover ou alterar qualquer direito autoral, marca comercial ou outros avisos de propriedade
                intelectual contidos e/ou distribuídos nas publicações da Calculando Riquezas;</li>
            <li>Não violar qualquer legislação nacional ou internacional que seja integrada ao ordenamento jurídico
                brasileiro, ou ainda, que, por qualquer razão legal, deva ser no Brasil aplicada;</li>
            <li>Não praticar atos contrários à moral e aos bons costumes;</li>
            <li>Não praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar prejuízo a
                Calculando Riquezas, a outros clientes da Calculando Riquezas ou a qualquer terceiro;</li>
            <li>Não utilizar os serviços prestados pela Calculando Riquezas no âmbito da Plataforma de má-fé, sempre
                respeitando o propósito do serviço contratado;</li>
            <li>Respeitar a Política de Privacidade, a qual está disponibilizada em seu Site; e</li>
            <li>Se encarregar dos tributos ou contribuições fiscais ou parafiscais, independente de sua natureza, que
                recaiam ou venham a recair sobre os investimentos que integram a carteira do Usuário, isentando a
                Calculando Riquezas de qualquer responsabilidade sobre este tema.</li>
        </ol>
        <h4>IIX – Obrigações da Calculando Riquezas</h4>
        <p>A Calculando Riquezas, por sua vez, tem as seguintes obrigações, sem prejuízo de outras previstas nestes
            Termos de Uso:</p>
        <ol>
            <li>Exercer suas atividades com boa fé, transparência, diligência e lealdade em relação ao Usuário;</li>
            <li>Atender às normas, políticas e regulamentações vigentes, referentes aos serviços prestados no âmbito da
                Plataforma e/ou do aplicativo e aos padrões ético e profissional;</li>
            <li>Acatar ordens judiciais emitidas por autoridade competente e, quando do recebimento de ofício pelo
                Usuário, desde que este encaminhe tal ofício tempestivamente à Calculando Riquezas;</li>
            <li>Envidar os melhores esforços para prestação dos serviços previstos na Plataforma.</li>
        </ol>
        <h4>IX – Isenção de Garantias e Limitações de Responsabilidade</h4>
        <p>A Calculando Riquezas não oferece qualquer garantia vinculada à Plataforma, ao seu aplicativo e as
            Ferramentas Calculando Riquezas e a sua utilização, não sendo responsável por quaisquer prejuízos ou perdas
            que resultem de seu uso. A utilização da Plataforma, do aplicativo e das Ferramentas Calculando Riquezas é
            feita sob inteira responsabilidade do Usuário, que deverá utilizar seus próprios conhecimentos e técnicas
            para decidir sobre sua gestão financeira. A Calculando Riquezas não se responsabiliza pelas decisões
            financeiras tomadas pelo Usuário, sendo estas de caráter pessoal e exercidas conforme critérios alheios à
            interferência da Plataforma e da Calculando Riquezas. SENDO ASSIM, O USUÁRIO RECONHECE E CONCORDA QUE
            UTILIZA A PLATAFORMA POR SUA CONTA E RISCO, VISTO QUE A CALCULANDO RIQUEZAS NÃO POSSUI QUALQUER
            INTERFERÊNCIA OU INGERÊNCIA COM RELAÇÃO ÀS DECISÕES TOMADAS POR SEUS USUÁRIOS, AINDA QUE TAIS DECISÕES
            POSSAM TER COMO BASE OS CONTEÚDOS CALCULANDO RIQUEZAS DISPONIBILIZADOS NA PLATAFORMA. A Calculando Riquezas
            poderá conter o direcionamento a endereços eletrônicos que não pertencem e não são operados pela Calculando
            Riquezas. A Calculando Riquezas não se responsabiliza pelo conteúdo, serviços e procedimentos praticados de
            qualquer endereço eletrônico fora do domínio do Site. O Usuário expressamente declara que possui
            conhecimento de que a Calculando Riquezas não é responsável por prover a rede de dados, conexão via internet
            e/ou demais instrumentos para navegar em nossa Plataforma. A Calculando Riquezas não garante, declara nem
            assegura que a Plataforma esteja livre de perda, interrupção, ataque, vírus, interferência, pirataria ou
            outra invasão de segurança e isenta-se de qualquer responsabilidade em relação a essas questões.</p>

        <h4>X – Propriedade Intelectual</h4>
        <p>É vedada a reprodução e distribuição, no todo ou em parte, a qualquer terceiro, sem prévia e expressa
            autorização da Calculando Riquezas, de seus Conteúdos Calculando Riquezas disponibilizados ao Usuário.</p>
        <p>Esses Conteúdos Calculando Riquezas são oferecidos aos leitores e assinantes da Calculando Riquezas em
            diversos formatos, havendo, no caso do conteúdo pago, sempre um prazo de vigência determinado no momento da
            aquisição do Plano da Calculando Riquezas.</p>
        <p>Aplicam-se aos presentes Termos de Uso as disposições da Lei nº 9.609 e da Lei nº 9.610, ficando os
            infratores sujeitos às penas dos crimes previstos no art. 12 da Lei 9.609/98 e no artigo 102 e seguintes da
            Lei nº 9.610, sem prejuízo da responsabilidade civil pelos danos eventualmente causados pelo uso e
            distribuição de cópias não autorizadas das Ferramentas Calculando Riquezas ou por qualquer outra violação
            aos direitos de propriedade dos serviços de prestados pela Calculando Riquezas na Plataforma, incluindo, mas
            não se limitando, o acesso à Plataforma de terceiros não autorizados.</p>
        <p>Os Conteúdos Calculando Riquezas, dentre os quais incluem, sem limitação, relatórios, vídeos, textos,
            gráficos, imagens, fotos, ilustrações, marcas comerciais, nomes comerciais, marcas de serviço, logotipos,
            informações, código-fonte, layouts, nomes de domínio, software, know-how, e quaisquer outros materiais são
            todos protegidos por direito de propriedade intelectual (“Direito de Propriedade Intelectual”). Todos os
            Direitos de Propriedade Intelectual conferidos às Ferramentas Calculando Riquezas são de titularidade da
            Calculando Riquezas, licenciados à Calculando Riquezas ou de parceiros da Calculando Riquezas, conforme
            aplicável.</p>
        <p>A exploração comercial, transmissão, divulgação, modificação, reprodução, cópia ou quaisquer outras formas de
            utilização comercial de tais Conteúdos Calculando Riquezas pelo Usuário são rigorosamente proibidas.</p>
        <p>Os presentes Termos de Uso não implicam a cessão ou transferência ao Usuário de qualquer Direito de
            Propriedade Intelectual, ou a qualquer parte de seu conteúdo ou características. O Usuário apenas poderá
            utilizar quaisquer Direitos de Propriedade Intelectual caso haja expressa autorização, por escrito, pela
            Calculando Riquezas.</p>
        <p>De modo a evitar a pirataria das Ferramentas Calculando Riquezas, a Calculando Riquezas também se reserva o
            direito de impedir o download das Ferramentas Calculando Riquezas em PDF, garantindo, por outro lado, que
            tais Conteúdos Calculando Riquezas permaneçam disponíveis na Plataforma. No mesmo sentido, a Calculando
            Riquezas se reserva o direito de solicitar a autenticação de 2 (dois) fatores para acesso à Plataforma, com
            o intuito de zelar que, conforme o caso, somente Usuários devidamente cadastrados tenham acesso à
            Plataforma.</p>
        <h4>XI – Confidencialidade</h4>
        <p>A Calculando Riquezas e os Usuários comprometem-se em manter sob sigilo as informações decorrentes da outra
            parte, seja por meio escrito ou verbal, obrigando-se, ainda, a envidar seus melhores esforços para que os
            seus colaboradores, caso aplicável, assumam a obrigação de confidencialidade estabelecida no presente Termos
            de Uso, devendo ambos assegurar o mesmo grau de diligência que dispensariam às suas próprias informações
            confidenciais.</p>
        <p>As informações confidenciais não devem ser reveladas pela Calculando Riquezas ou pelo Usuário sem prévio e
            expresso consentimento da outra parte, por ação ou omissão, observado o disposto abaixo.</p>
        <p>A quebra de confidencialidade não será configurada nas hipóteses a seguir:</p>
        <ul>
            <li>caso a informação seja conhecimento da parte antes de ser divulgada pela outra parte;</li>
            <li>caso a informação tenha sido legalmente obtida de terceiros, sem inobservância de qualquer das
                disposições destes Termos de Uso;</li>
            <li>caso a informação esteja ou se torne disponível ao público de outra forma, que não em decorrência de
                qualquer ato comissivo ou omissivo;</li>
            <li>caso a informação tenha sido solicitada por qualquer autoridade a que a parte esteja sujeita em razão do
                exercício de sua atividade;</li>
            <li>caso haja ordem judicial ou de tribunal arbitral devidamente constituído; e</li>
            <li>caso haja ordem extrajudicial, e não haja decisão judicial em tempo hábil que desobrigue a parte de
                fornecer tal informação à autoridade requerente.</li>
        </ul>
        <p>A Calculando Riquezas declara e garante que emprega medidas de segurança, técnicas, físicas e
            organizacionais, aptas a proteger os Dados Pessoais, inclusive de acessos não-autorizados e de situações
            acidentais ou ilícitas de perda, destruição, alteração, comunicação ou de qualquer forma de tratamento
            inadequado ou ilícito. Para maiores informações, acessar a Política de Privacidade, a qual o Usuário
            expressamente concorda com os seus termos.</p>
        <p>Os sistemas utilizados pela Calculando Riquezas para o tratamento dos Dados Pessoais estão estruturados de
            forma a atender aos requisitos de segurança, aos padrões de boas práticas e de governança, e aos princípios
            gerais da LGPD.</p>
        <p>A Calculando Riquezas assegura que utiliza e continuará utilizando as Melhores Práticas do Mercado em relação
            à segurança das informações que circulam em seus ambientes físicos e virtuais, e que em hipótese alguma
            reduzirá seus níveis de proteção de forma a entrar em desacordo com a LGPD.</p>
        <h4>XII – Disposições Gerais</h4>
        <p>O Usuário não poderá ceder ou transferir a qualquer terceiro, total ou parcialmente, seus direitos e
            obrigações decorrentes destes Termos de Uso.</p>
        <p>Por medidas de segurança, a Calculando Riquezas poderá restringir o acesso às Ferramentas Calculando Riquezas
            em mais de 2 (dois) dispositivos que estejam sendo usados simultaneamente. Nestas situações, o Usuário será
            informado e deslogado dos demais dispositivos.</p>
        <p>O Usuário que infringir os presentes Termos de Uso será responsabilizado pelos danos e prejuízos de qualquer
            natureza que a Calculando Riquezas venha a sofrer ou aqueles causados a terceiros.</p>
        <p>O não exercício ou o atraso no exercício, pelas partes de qualquer direito constante destes Termos de Uso não
            deverá ser interpretado como uma renúncia ou novação. A proibição, invalidade ou inexequibilidade de
            qualquer disposição destes Termos de Uso não invalidará ou afetará o mesmo como um todo, devendo permanecer
            em vigor todas as demais disposições que não tenham sido afetadas pela proibição, invalidade ou
            inexequibilidade da cláusula inoperante.</p>
        <p>O conteúdo destes Termos de Uso poderá ser atualizado e modificado a qualquer tempo e conforme a melhor
            conveniência da Calculando Riquezas, com o que o Usuário já manifesta sua plena concordância. Realizada
            qualquer alteração ou atualização em relação ao conteúdo destes Termos de Uso, a Calculando Riquezas deverá
            comunicar tal fato ao Usuário em seu e-mail cadastrado, sendo que a não manifestação de contrariedade
            significará a aceitação quanto ao novo conteúdo dos Termos de Uso.</p>
        <p>Fica eleito o Foro da Comarca de Chapecó, Estado de Santa Catarina, como o único competente para dirimir
            quaisquer controvérsias decorrentes deste instrumento, independentemente de qualquer outro por mais
            privilegiado que seja ou venha a ser.</p>
    </div>
</template>