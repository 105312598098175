<template>
  <div class="planning-view">
    <page-header :title="pageTitle">
      <div class="planning-view__monthly" :class="{'without-add-button' : state.monthlyPlanning.id}" id="monthly-filter">
        <button class="btn button-outline btn__mobile--rounded no-desktop" @click="openDatePicker">
          <fa-icon icon="fa-solid fa-calendar" />
        </button>
        <monthpicker ref="monthPickerRef" :value="state.monthFilter" @change="onChangeMonthPeriod" />
      </div>
      <add-button v-if="!state.monthlyPlanning.id" class="button-primary" @click="createPlanning" label="Novo" />
    </page-header>
    <box-page v-if="state.isLoading">
      <loader />
    </box-page>
    <template v-else>
      <box-page v-if="emptyData">
        <div class="planning-view__empty">
          <img src="@images/illustrations/empty-list.webp" class="planning-view__empty-img" />
          <p>Não existem planejamentos cadastrados</p>
          <button class="btn button-primary" @click="createPlanning">Novo planejamento</button>
        </div>
      </box-page>
      <div v-else class="planning-view__content">
        <div class="planning-view__budgets">
          <div v-if="state.monthlyPlanning.id" class="planning-view__montlhy">
            <planning-resume-card v-if="state.monthlyPlanning.id" :planning-data="state.monthlyPlanning"
            :is-active="isSelected(state.monthlyPlanning.id)" @click="onSelectPlanning(state.monthlyPlanning.id)" />
          </div>
          <div class="planning-view__custom-plannings">
            <h2 class="planning-view__custom-plans">Personalizados</h2>
            <template v-if="state.customPlannings.length">
              <planning-resume-card v-for="planning in state.customPlannings" :key="planning.id"
                :planning-data="planning" :is-active="isSelected(planning.id)" @click="onSelectPlanning(planning.id)" />
            </template>
            <button class="planning-view__new-planning btn button-outline" @click="createPlanning">
              Novo planejamento personalizado
            </button>
          </div>
        </div>
        <div class="planning-view__details">
          <box-page v-if="state.isLoadingPlanningData">
            <loader />
          </box-page>
          <planning-details v-else :planning-data="state.selectedPlanning" @delete-planning="onDeletePlanning" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useModal } from "vue-final-modal";
import { isMobile } from "@/services/deviceService";
import { useRouter } from "vue-router";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useUserDataStore } from "@/store/userData";
import { getPlanningList, getPlanningById, removePlanning } from "../services/planning/planningService";
import { usePlanningStore } from "@/store/planningData";
import { eventBus } from "@/services/eventBusService";
import { PLANNING_LIMIT_REACHED, PLANNING_DISABLED_CREATE_CUSTOM } from "../services/planning/planningConstants";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import Loader from "@/components/Loader/Loader.vue";
import PlanningResumeCard from "../components/PlanningResumeCard/PlanningResumeCard.vue";
import PlanningDetails from "../components/PlanningDetails/PlanningDetails.vue";
import Monthpicker from '@/modules/common/components/Monthpicker/Monthpicker.vue';
import CreatePlanningModal from "../components/CreatePlanningModal/CreatePlanningModal.vue";
import AddButton from '@/components/AddButton/AddButton.vue';

export default {
  components: { BoxPage, PageHeader, Loader, PlanningResumeCard, PlanningDetails, Monthpicker, AddButton },
  setup() {
    const planningStore = usePlanningStore();
    const userStore = useUserDataStore();
    const date = new Date();
    const router = useRouter();
    const monthPickerRef = ref(null);
    const state = reactive({
      isLoading: true,
      isLoadingPlanningData: false,
      monthlyPlanning: {},
      customPlannings: [],
      limitReached: false,
      selectedPlanning: {},
      monthFilter: { month: date.getMonth(), year: date.getFullYear() }
    })

    onMounted(() => {
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(), currentDate.getUTCMonth(), 1)
      const endDate = new Date(currentDate.getFullYear(), currentDate.getUTCMonth() + 1, 0)
      planningStore.setPlanningData({ startDate, endDate });

      fetchAction();
    })

    const isPremiumUser = computed({
      get() {
        return userStore.isPremiumUser
      }
    })

    const emptyData = computed({
      get() {
        return !state.customPlannings?.length && !state.monthlyPlanning.id
      }
    })

    const isMobileDevice = computed({
      get() {
        return isMobile()
      }
    })

    const addPlanningLabel = computed({
      get() {
        return isMobile() ? 'Novo' : 'Novo planejamento'
      }
    })

    const pageTitle = computed({
      get() {
        return isMobile() ? 'Planejamentos' : 'Planejamentos financeiros'
      }
    })

    const createPlanning = () => {
      if (state.limitReached) {
        openPremiumOverlay(PLANNING_LIMIT_REACHED)
        return;
      }

      if (state.monthlyPlanning?.id && !userStore.isPremiumUser) {
        openPremiumOverlay(PLANNING_DISABLED_CREATE_CUSTOM)
        return;
      }

      openCreatePlanningModal();
    }

    const openPremiumOverlay = (params) => {
      eventBus.emit('showPremiumOverlay', params);
    }

    const openCreatePlanningView = () => {
      router.push('/finance/plannings/new')
    }

    const openDatePicker = () => {
      monthPickerRef.value.openPicker();
    }

    const isSelected = (id) => {
      return id === state.selectedPlanning.id;
    }

    const fetchAction = () => {
      Promise.all([getMonthPlanning(), getCustomPlannings()]).then(onFetchSuccess).catch(onGetPlanningsError)
    }

    const getMonthPlanning = () => {
      state.isLoading = true;
      state.monthlyPlanning = {};
      state.selectedPlanning = {};
      const { startDate, endDate } = planningStore.getPlanningData;
      const filters = { startDate, endDate, customPeriod: false }
      return getPlanningList(filters);
    }

    const getCustomPlannings = () => {
      state.isLoading = true;
      const { endDate } = planningStore.getPlanningData;
      const filters = { rangeDate: endDate, customPeriod: true }
      return getPlanningList(filters);
    }

    const onFetchSuccess = ([monthlyPlanning, customPlannings]) => {
      state.monthlyPlanning = monthlyPlanning.plans?.length ? monthlyPlanning.plans[0] : {};
      state.customPlannings = customPlannings.plans;
      state.limitReached = monthlyPlanning.limitReached;
      state.isLoading = false;

      getFirstPlanning()
    }

    const onGetPlanningsError = () => {
      showErrorToast("Não foi possível buscar seus planejamentos. Tente novamente.")
      state.isLoading = false;
    }

    const getFirstPlanning = () => {
      const { id } = state.monthlyPlanning;

      if (id) {
        getPlanningDetails(id);
        return;
      }

      const customId = state.customPlannings[0]?.id

      if (customId) {
        getPlanningDetails(customId);
      }
    }

    const getPlanningDetails = (id) => {
      state.isLoadingPlanningData = true;
      getPlanningById(id)
        .then(onGetPlanningSuccess)
        .catch(onGetPlanningError)
    }

    const onGetPlanningSuccess = (data) => {
      state.selectedPlanning = data;
      state.isLoadingPlanningData = false;
    }

    const onGetPlanningError = () => {
      state.isLoadingPlanningData = false;
      showErrorToast('Ocorreu um erro ao buscar os dados do planejamento. Tente novamente.')
    }

    const onSelectPlanning = (id) => {
      getPlanningDetails(id);
    }

    const onChangeMonthPeriod = ({ startDate, endDate }) => {
      planningStore.setPlanningData({ startDate, endDate });

      fetchAction();
    }

    const { open: openCreatePlanningModal, close: closeCreatePlanningModal } = useModal({
      component: CreatePlanningModal,
      attrs: {
        onlyCustomPeriod: computed(() => !!state.monthlyPlanning.id),
        onClose() {
          closeCreatePlanningModal();
        },
        onCreate(data) {
          planningStore.setPlanningData(data);
          openCreatePlanningView();
          closeCreatePlanningModal();
        },
        onCancel() {
          closeCreatePlanningModal();
        },
      },
    });

    const onDeletePlanning = (id) => {
      removePlanning(id).then(onRemovePlanningSuccess).catch(onRemovePlanningError)
    }

    const onRemovePlanningSuccess = () => {
      fetchAction();
      showSuccessToast('Planejamento excluído com sucesso.')
    }

    const onRemovePlanningError = () => {
      showErrorToast('Ocorreu um erro ao excluir o planejamento. Tente novamente.')
    }

    return {
      state,
      isMobileDevice,
      addPlanningLabel,
      emptyData,
      isPremiumUser,
      pageTitle,
      monthPickerRef,
      openDatePicker,
      getPlanningList,
      createPlanning,
      onChangeMonthPeriod,
      onSelectPlanning,
      onDeletePlanning,
      isSelected
    }
  }
}
</script>

<style lang="scss" src="./styles/PlanningView.scss" />