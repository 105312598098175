<template>
  <VueDatePicker
    class="date"
    locale="pt"
    teleport="body"
    auto-apply
    :name="name"
    format="dd/MM/yyyy"
    autocomplete="off"
    :value="value"
    :enable-time-picker="false"
    :clearable="clearable"
    timezone="UTC"
    @update:model-value="onChangeDate"
  />
</template>

<script>
import { reactive } from 'vue'
export default {
    props: {
        value: [String, Date],
        name: String,
        clearable: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            dateModel: props.value
        })

        const onChangeDate = (date) => {
            if (!date) {
                return;
            }
            
            const adjustedDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
            emit('update:modelValue', adjustedDate.toISOString());
        }

        return {
            state,
            onChangeDate
        }
    }
}
</script>