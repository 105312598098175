import requestService from '@services/requestService'

const GOALS_URL = '/goals'

export function getGoals(params) {
    return requestService.get(`${GOALS_URL}`, { params });
}

export function getFirstGoal() {
    return requestService.get(`${GOALS_URL}/first`);
}

export function getGoalById(id) {
    return requestService.get(`${GOALS_URL}/${id}`);
}

export function saveGoal(params) {
    return requestService.post(`${GOALS_URL}`, params);
}

export function saveDeposit(params) {
    return requestService.post(`${GOALS_URL}/deposit`, params);
}

export function saveWithdraw(params) {
    return requestService.post(`${GOALS_URL}/withdraw`, params);
}

export function updateGoal(id, params) {
    return requestService.put(`${GOALS_URL}/${id}`, params);
}

export function removeGoal(id) {
    return requestService.post(`${GOALS_URL}/delete/${id}`);
}

export function removeGoalTransaction(goalId, transactionId) {
    return requestService.post(`${GOALS_URL}/${goalId}/transaction-delete/${transactionId}`);
}