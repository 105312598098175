<template>
    <div class="contact-view">
        <BoxPage title="Suporte">
            <div class="contact-view__content">
                <div class="contact-view__tutorial">
                    <p>Por favor, preencha o formulário abaixo com os detalhes da sua solicitação.
                        Nossa equipe está aqui para ajudar e responderemos o mais rápido possível.
                        Descreva sua dúvida, problema técnico ou outro assunto abaixo. Quanto mais detalhes você
                        fornecer, melhor poderemos ajudar.</p>
                    <p>Vamos analisar cuidadosamente e <b>responderemos para o seu email em breve.</b></p>
                </div>
                <div class="contact-view__form">
                    <iframe :width='iframeWidth' height='760'
                        :src='`https://app.pipefy.com/public/form/poMYsBwR?embedded=true${emailFormatted}`'
                        frameborder='0'></iframe>
                </div>
            </div>
        </BoxPage>
    </div>
</template>

<script>
import { computed } from "vue";
import { useUserDataStore } from "@/store/userData";
import { isMobile } from '@/services/deviceService'
import BoxPage from "@/components/BoxPage/BoxPage";

export default {
    components: { BoxPage },
    setup() {
        const userStore = useUserDataStore();

        const userEmail = computed(() => userStore.getUserEmail);

        const emailFormatted = computed({
            get() {
                return `&email=${userEmail.value}`
            }
        })

        const iframeWidth = computed({
            get() {
                return isMobile() ? '100%' : '70%'
            }
        })

        return {
            emailFormatted,
            iframeWidth
        }
    }
}
</script>

<style lang="scss" src="./styles/ContactView.scss" />
