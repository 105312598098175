<template>
  <div class="totals-transactions-chart">
    <loader v-if="state.isLoading" />

    <div v-else-if="emptyData" class="totals-transactions-chart__empty">
      <img src="@images/illustrations/empty-list.webp" class="totals-transactions-chart__empty-img" />
      <p>Não existem dados para exibir</p>
    </div>

    <apexchart v-else-if="!emptyData" type="line" height="350" :options="state.chartOptions" :series="state.series" />
  </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from "vue";
import { formatCurrency, formatNumberToK } from "@/services/currencyService";
import { isMobile } from "@/services/deviceService";
import { getTransactionsBalance } from "../../services/transactionsService";
import { SMALL_MONTH_NAMES } from "@/modules/common/constants/dateConstants";
import { useUserDataStore } from "@/store/userData";
import Loader from "@/components/Loader/Loader.vue";

export default {
  components: { Loader },
  props: {
    filters: Object
  },
  setup(props) {
    const userStore = useUserDataStore();
    const state = reactive({
      isLoading: true,
      series: [],
      localSeries: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#00bd8e', '#d96055', '#705cf2'],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return formatData(val);
          },
        },
        tooltip: {
          formatter: (val) => {
            return formatCurrency(val);
          },
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
            return formatData(val);
          },
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

    });

    function formatData(val) {
      return userStore.getShouldShowCurrencyValues ? (isMobile() ? formatNumberToK(val) : formatCurrency(val)) : 'R$ ******';
    }

    watch(() => userStore.getShouldShowCurrencyValues, () => {
      state.chartOptions.dataLabels.formatter = (val) => formatData(val);
      state.chartOptions.yaxis.labels.formatter = (val) => formatData(val);
      state.series = [...state.localSeries];
    });
    
    const emptyData = computed({
      get() {
        return !state.series.length && !state.isLoading
      }
    })

    onMounted(() => {
      fetchData();
    })

    watch(() => props.filters, () => fetchData(), { deep: true });

    const fetchData = () => {
      const currentDate = new Date();
      const filters = {
        StartDate: props.filters.StartDate || new Date(currentDate.getFullYear(), 0, 1),
        EndDate: props.filters.EndDate || new Date(currentDate.getFullYear(), 11, 31),
      }

      state.isLoading = true;
      getTransactionsBalance(filters)
        .then(onFetchSuccess)
        .catch(onFetchError)
    }

    const onFetchSuccess = (data) => {
      const balance = data?.balance;
      const revenues = [];
      const expenses = [];
      const monthlyBalances = [];

      for (const monthYear in balance) {
        const { revenues: revenue, expenses: expense, monthlyBalance } = balance[monthYear];
        revenues.push(revenue);
        expenses.push(expense);
        monthlyBalances.push(monthlyBalance);
      }

      state.chartOptions.xaxis.categories = formatMonthNames(balance);
      state.localSeries = [
        { name: 'Receitas', data: revenues },
        { name: 'Despesas', data: expenses },
        { name: 'Balanço', data: monthlyBalances },
      ];

      state.series = state.localSeries;

      state.isLoading = false;
    }

    const onFetchError = () => {
      state.isLoading = false;
    }

    const formatMonthNames = (names) => {
      return Object.keys(names).map(date => {
        const [month, year] = date.split('/');
        return `${SMALL_MONTH_NAMES[parseInt(month) - 1]}/${year}`;
      })
    }

    return {
      state,
      emptyData
    };
  },
};
</script>

<style lang="scss" src="./TotalsTransactionsChart.scss" />