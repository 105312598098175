<template>
    <Modal class="change-transaction-situation-modal" :title="title" @close="onClose" @cancel="onCancel"
        :fetch-action="updateSituation" @fetch-success="onUpdateTransactionSituationSuccess"
        @fetch-error="onUpdateTransactionSituationError" confirm-label="Confirmar" :esc-to-close="false"
        :click-to-close="false">
        <template #default>
            <div class="change-transaction-situation-modal__content">
                <p class="change-transaction-situation-modal__description">{{ description }}</p>
            </div>
        </template>
    </Modal>
</template>

<script>
import { computed } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import { updateTransactionSituation } from '../../services/transactionsService';
import { showSuccessToast, showErrorToast } from "@/services/toastService";
import Modal from "@/components/Modal/Modal.vue";

export default {
    components: { Modal },
    props: {
        type: [String, Number],
        id: String
    },
    setup(props, { emit }) {
        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const title = computed(() => {
            const label = props.type === TRANSACTION_TYPES.REVENUE.code ? 'recebida' : 'paga'
            return `Marcar como ${label}`
        })

        const description = computed(() => {
            const label = props.type === TRANSACTION_TYPES.REVENUE.code ? 'recebida' : 'paga'
            return `Deseja atualizar a transação para ${label}`
        })

        const updateSituation = () => {
            const params = {
                id: props.id,
                status: true
            }

            return updateTransactionSituation(params);
        }

        const onUpdateTransactionSituationSuccess = () => {
            showSuccessToast('Transação atualizada com sucesso!')
            emit('success');
            onClose();
        }

        const onUpdateTransactionSituationError = () => {
            showErrorToast('Ocorreu um erro ao atualizar a transação. Tente novamente.');
        }

        return {
            title,
            description,
            onClose,
            onCancel,
            updateSituation,
            onUpdateTransactionSituationSuccess,
            onUpdateTransactionSituationError
        };
    },
};
</script>

<style lang="scss" src="./ChangeTransactionSituationModal.scss" />
