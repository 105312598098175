<template>
    <Modal
    class="confirmation-modal"
    :title="title"
    @close="onClose"
    @cancel="onCancel"
    @confirm="onConfirm"
    :confirm-label="confirmLabel"
    :buttonType="buttonType"
    :esc-to-close="false"
    :click-to-close="false">
        <template #default>
            <div class="confirmation-modal__content">
                <p class="confirmation-modal__description">{{ description }}</p>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";

export default {
    components: { Modal },
    props: {
        title: String,
        description: String,
        confirmLabel: String,
        buttonType: String,
    },
    setup(props, { emit }) {
        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const onConfirm = () => {
            emit("confirmAction");
        };

        return {
            onClose,
            onCancel,
            onConfirm
        };
    },
};
</script>

<style lang="scss" src="./ConfirmationModal.scss" />
