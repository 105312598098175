import requestService from '@services/requestService'

const CATEGORY_URL = '/transaction-category'

export function getCategories(params) {
    return requestService.get(`${CATEGORY_URL}`, { params });
}

export function getCategoryById(id) {
    return requestService.get(`${CATEGORY_URL}/${id}`);
}

export function saveCategory(params) {
    return requestService.post(`${CATEGORY_URL}`, params);
}

export function updateCategory(id, params) {
    return requestService.put(`${CATEGORY_URL}/${id}`, params);
}

export function removeCategory(id) {
    return requestService.post(`${CATEGORY_URL}/delete/${id}`);
}