import axios from 'axios';
import { useUserDataStore } from "@/store/userData";

const CALC_RIQUEZAS_API = process.env.VUE_APP_CALC_RIQUEZAS_API;

const axiosInstance = axios.create({
  baseURL: CALC_RIQUEZAS_API,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const userData = useUserDataStore();
    const token = userData.getUserToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;