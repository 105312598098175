<template>
  <loader v-if="state.isLoading" />
  <div v-else class="cards-resume cards-resume-expenses">
    <CardResume title="Despesas pendentes" type="down" icon="fa-minus" :value="state.transactionsData.pendingExpenses"/>
    <CardResume title="Despesas pagas" type="down" icon="fa-minus" :value="state.transactionsData.expenses"/>
    <CardResume title="Total do período" type="down" icon="fa-minus" :value="state.transactionsData.monthlyBalance"/>
  </div>
</template>

<script>
import { onMounted, reactive, watch, onUnmounted } from 'vue';
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { getTransactionsSimpleBalance } from "../../services/transactionsService";
import { eventBus } from "@/services/eventBusService";
import CardResume from "../CardResume/CardResume.vue";
import Loader from '@/components/Loader/Loader.vue';

export default {
  components: { CardResume, Loader },
  setup() {
    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      isLoading: false,
      transactionsData: {
        pendingExpenses: null,
        expenses: null,
        monthlyBalance: null
      }
    })

    const reloadDataHandler = () => {
      fetchData()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      fetchData()
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    const fetchData = () => {
      const filters = {
        ...filtersStore.getTransactionsFilters,
        Type: TRANSACTION_TYPES.EXPENSE.code
      };

      state.isLoading = true;
      getTransactionsSimpleBalance(filters)
        .then(fetchDataSuccess)
        .catch(fetchDataError)
    }

    const fetchDataSuccess = (balance) => {
      state.transactionsData = { ...balance }
      state.isLoading = false;
    }

    const fetchDataError = () => {
      state.isLoading = false;
    }

    watch(() => filtersStore.getTransactionsFilters, () => fetchData(), { deep: true });
    
    return {
      state
    }
  }
};
</script>

