<template>
  <div class="goals-list">
    <div v-if="isEmpty" class="goals-list__empty">
      <img
        src="@/assets/images/illustrations/empty-list.webp"
        class="goals-list__empty-img"
      />
      <p>Não existem objetivos cadastrados</p>
    </div>
    <goal-card v-else v-for="goal in goalsData" :key="goal.id" :goal-data="goal" @refresh-data="onRefreshData" />
  </div>
</template>

<script>
import { computed } from 'vue';
import GoalCard from '../GoalCard/GoalCard.vue'

export default {
  components: { GoalCard },
  props: {
    goalsData: Array
  },
  setup(props, { emit }) {
    const onRefreshData = () => {
      emit('refresh-data');
    }

    const isEmpty = computed({
      get() {
        return !props.goalsData.length;
      }
    })

    return {
      isEmpty,
      onRefreshData
    }
  }
}
</script>

<style lang="scss" src="./GoalsList.scss" />
