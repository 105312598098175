<template>
  <div class="transactions-page-filters">
    <div class="transactions-page-filters__monthly" id="monthly-filter">
      <button class="btn button-outline btn__mobile--rounded no-desktop" @click="openDatePicker">
        <fa-icon icon="fa-solid fa-calendar" />
      </button>
      <monthpicker ref="transactionPeriodRef" :value="state.monthFilter" @change="onChangeMonthPeriod"/>
    </div>
    <div class="transactions-page-filters__advanced" id="advanced-filters">
      <button class="btn button-outline transactions-page-filters__btn-advanced no-tablet" @click="openAdvancedFilters">
        Filtros avançados
      </button>
      <button class="btn button-outline btn__mobile--rounded no-desktop" @click="openAdvancedFilters">
        <fa-icon icon="fa-solid fa-filter" />
      </button>
      <children-modal title="Filtrar transações" :show="state.showAdvancedFilters" @close="closeAdvancedFilters"
        @confirm="onConfirmFilter" confirm-label="Filtrar" :is-closable="state.isClosable">
        <transaction-category-select :type="type" @change-category="onChangeCategory" :value="getCategoryValue()" />
        <custom-input label="Buscar por texto" :value="state.advancedFilters.SearchTerm"
          v-model="state.advancedFilters.SearchTerm" name="textual-search" />
        <div class="form-group">
          <label for="from-date">De</label>
          <datepicker
            v-model="state.StartDate"
            :value="state.StartDate"
            name="from-date"
            clearable
            @open="blockCloseModal"
            @closed="enableCloseModal"
            :max-date="state.EndDate"
            @cleared="onClearStartDate"
          />
        </div>
        <div class="form-group">
          <label for="to-date">Até</label>
          <datepicker
            v-model="state.EndDate"
            :value="state.EndDate"
            name="to-date"
            clearable
            @open="blockCloseModal"
            @closed="enableCloseModal"
            :min-date="state.StartDate"
            @cleared="onClearEndDate"
          />
        </div>
      </children-modal>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { eventBus } from "@/services/eventBusService";
import { useUserDataStore } from "@/store/userData";
import ChildrenModal from "@/components/ChildrenModal/ChildrenModal.vue";
import CustomInput from '@/components/CustomInput/CustomInput.vue';
import TransactionCategorySelect from '../TransactionCategorySelect/TransactionCategorySelect.vue';
import Datepicker from '@/modules/common/components/Datepicker/Datepicker.vue';
import Monthpicker from '@/modules/common/components/Monthpicker/Monthpicker.vue';

export default {
  components: { ChildrenModal, TransactionCategorySelect, CustomInput, Datepicker, Monthpicker },
  props: {
    type: Number,
  },
  setup() {
    const userStore = useUserDataStore();
    const filtersStore = useTransactionsFiltersStore();
    const state = reactive({
      isClosable: true,
      monthFilter: filtersStore.getMonthFilter,
      showAdvancedFilters: false,
      StartDate: filtersStore.getTransactionsFilters.StartDate,
      EndDate: filtersStore.getTransactionsFilters.EndDate,
      periodSelected: null,
      advancedFilters: {
        Category: filtersStore.getCategoryFilter,
        Type: null,
        SearchTerm: filtersStore.getTextualFilter,
      },
    })

    watch(() => filtersStore.getTextualFilter, () => {
      state.advancedFilters.SearchTerm = filtersStore.getTextualFilter;
    })

    watch(() => filtersStore.getCategoryFilter, () => {
      state.advancedFilters.Category = filtersStore.getCategoryFilter;
    })

    const transactionPeriodRef = ref(null)

    const openDatePicker = () => {
      transactionPeriodRef.value.openPicker();
    }

    const onChangeMonthPeriod = ({ startDate, endDate }) => {
      filtersStore.updatePeriod(startDate, endDate);
    }

    const onChangeCategory = (category) => {
      state.advancedFilters.Category = category;
    }

    const openAdvancedFilters = () => {
      state.showAdvancedFilters = true;
    }

    const closeAdvancedFilters = () => {
      state.showAdvancedFilters = false;
    }

    const onConfirmFilter = () => {
      if (!userStore.isPremiumUser) {
        eventBus.emit('showPremiumOverlay')
        return;
      }

      filtersStore.updateAdvancedFilters(state.advancedFilters);

      if (state.StartDate && state.EndDate || state.periodSelected === 5) {
        filtersStore.updatePeriod(state.StartDate, state.EndDate);
        filtersStore.updatePeriodOption(state.periodSelected)
      }
    }

    const getCategoryValue = () => {
      return filtersStore.getCategoryFilter?.id
    }

    const blockCloseModal = () => {
      state.isClosable = false
    }

    const enableCloseModal = () => {
      state.isClosable = true
    }

    const onClearStartDate = () => {
      filtersStore.resetStartDate();
      state.StartDate = filtersStore.getTransactionsFilters.StartDate;
    }

    const onClearEndDate = () => {
      filtersStore.resetEndDate();
      state.EndDate = filtersStore.getTransactionsFilters.EndDate;
    }

    return {
      state,
      transactionPeriodRef,
      openDatePicker,
      getCategoryValue,
      onChangeMonthPeriod,
      onChangeCategory,
      openAdvancedFilters,
      closeAdvancedFilters,
      onConfirmFilter,
      blockCloseModal,
      enableCloseModal,
      onClearStartDate,
      onClearEndDate
    }
  }
}
</script>

<style lang="scss" src="./TransactionsPageFilters.scss" />
