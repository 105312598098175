<template>
  <Modal title="Excluir transação"
  :buttonType="buttonType"
  @close="onClose"
  @cancel="onCancel"
  :fetch-action="confirmDeletion"
  @fetch-success="onRemoveTransactionSuccess"
  @fetch-error="onRemoveTransactionError">
    <template #default>
      <div class="remove-transaction-modal">
        <div class="remove-transaction-modal__confirm">
          <p>
            Tem certeza que deseja excluir a transação
            <b>{{ nameTransaction }}</b>? <br />
            Após remover, essa ação não poderá ser desfeita.
          </p>
        </div>
      </div>
      <recurrence-edition-confirmation-modal action-title="Excluir" v-if="state.showRecurrenceConfirmDeletion"
        @onConfirm="onConfirmRemoveRecurrenceTransaction" @onCancel="onCancelRemoveRecurrenceTransaction" />
    </template>
  </Modal>
</template>

<script>
import { reactive } from 'vue';
import { removeTransaction } from "../../services/transactionsService";
import { showErrorToast, showSuccessToast } from '@/services/toastService';
import Modal from "@/components/Modal/Modal.vue";
import RecurrenceEditionConfirmationModal from '../RecurrenceEditionConfirmationModal/RecurrenceEditionConfirmationModal.vue';

const CURRENT_EDITION_TYPE = 0;

export default {
  components: { Modal, RecurrenceEditionConfirmationModal },
  props: {
    transactionId: String,
    nameTransaction: String,
    recurrenceType: Number,
    buttonType: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      showRecurrenceConfirmDeletion: false,
      editionType: CURRENT_EDITION_TYPE,
    });

    const handleRemoveTransaction = () => {
      const params = {
        id: props.transactionId,
        subsequentType: state.editionType
      };

      return removeTransaction(params);
    };

    const onRemoveTransactionSuccess = () => {
      showSuccessToast("Transação excluída com sucesso!")
      emit('success')
      onClose()
    };

    const onRemoveTransactionError = () => {
      showErrorToast("Ocorreu um erro ao excluir a transação. Tente novamente.")
      onClose()
    };

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const confirmDeletion = () => {
      if (props.recurrenceType) {
        state.showRecurrenceConfirmDeletion = true;
        return;
      }

      return handleRemoveTransaction();
    };

    const onConfirmRemoveRecurrenceTransaction = (editionType) => {
      state.editionType = editionType;

      handleRemoveTransaction().then(onRemoveTransactionSuccess).catch(onRemoveTransactionError);
      state.showRecurrenceConfirmDeletion = false;
    }
    const onCancelRemoveRecurrenceTransaction = () => {
      state.showRecurrenceConfirmDeletion = false;
    }

    return {
      state,
      confirmDeletion,
      onCancel,
      onClose,
      onConfirmRemoveRecurrenceTransaction,
      onCancelRemoveRecurrenceTransaction,
      onRemoveTransactionSuccess,
      onRemoveTransactionError
    };
  },
};
</script>

<style lang="scss" src="./RemoveTransactionModal.scss" />
