<template>
    <router-link class="side-menu-item" :to="routeConfig.href" :class="{'active': isActive(routeConfig.href), [routeConfig.class]: routeConfig.class}" @click="onClick">
        <fa-icon :icon="`fa-solid ${routeConfig.icon}`" />
        <template v-if="showLabel">{{ routeConfig.title }}</template>
        <slot></slot>
    </router-link>
</template>

<script>
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
    props: {
        routeConfig: Object,
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const router = useRouter();
        const state = reactive({
            showText: props.showLabel,
            routePath: router.currentRoute.value.fullPath,
        })

        watch(() => props.showLabel,
            (value) => {
                state.showText = value
            })

        watch(() => router.currentRoute.value.fullPath,
        (value) => {
            state.routePath = value;
        })

        const onClick = () => {
            emit('click-menu')
        }

        const isActive = (href) => {
            const baseHref = href.replace(/\/[0-9a-fA-F-]+$/, '');
            const baseRoutePath = state.routePath.replace(/\/[0-9a-fA-F-]+$/, '').replace('/new', '');
        
            return baseHref === baseRoutePath;
        }

        return {
            state,
            onClick,
            isActive
        }
    }
}
</script>

<style lang="scss" src="./SideMenuItem.scss" />
