import * as goalResource from './goalResource'

export async function getGoals(params) {
    const { data } = await goalResource.getGoals(params);

    return data;
}

export async function getFirstGoal() {
    const { data } = await goalResource.getFirstGoal();

    return data;
}

export async function getGoalById(id) {
    const { data } = await goalResource.getGoalById(id);

    return data;
}

export async function saveGoal(params) {
    const { data } = await goalResource.saveGoal(params);

    return data;
}

export async function saveDeposit(params) {
    const { data } = await goalResource.saveDeposit(params);

    return data;
}

export async function saveWithdraw(params) {
    try {
        const { data } = await goalResource.saveWithdraw(params);
        return data;
    } catch (error) {
        return Promise.reject(error?.errors?.[0]);
    }
}

export async function updateGoal(id, params) {
    const { data } = await goalResource.updateGoal(id, params);

    return data;
}

export async function removeGoal(id) {
    return await goalResource.removeGoal(id);
}

export async function removeGoalTransaction(goalId, transactionId) {
    return await goalResource.removeGoalTransaction(goalId, transactionId);
}

