<template>
  <span class="badge" :class="badgeClass">
    {{ value }}
    <fa-icon class="badge__cleaner" v-if="removeCallback" icon="fa-solid fa-xmark" @click="removeCallback"/>
  </span>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    value: [String, Number],
    type: String,
    removeCallback: Function
  },
  setup(props) {
    const badgeClass = computed({
      get() {
        if (props.type === "alert") {
          return `badge--alert`;
        }

        if (props.type === "warning") {
          return `badge--warning`;
        }

        if (props.type === "success") {
          return `badge--success`;
        }

        return "";
      }
    });

    return {
      badgeClass
    }
  },
};
</script>

<style lang="scss" src="./Badge.scss" />
