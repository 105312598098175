<template>
  <custom-select
    class="transaction-category-select"
    :label="label"
    :options="state.categoryOptions"
    @change="onChange"
    :value="state.categorySelected"
    :required="required"
  />
</template>

<script>
import CustomSelect from "@/components/CustomSelect/CustomSelect.vue";
import { onMounted, reactive, watch } from "vue";
import { getCategories } from "../../services/category/categoryService";
import { showErrorToast } from "@/services/toastService";

export default {
  components: { CustomSelect },
  props: {
    label: {
      type: String,
      default: 'Categoria'
    },
    value: [String, Number],
    type: Number,
    options: Array,
    fetchOnLoad: {
      type: Boolean,
      default: true
    },
    required: Boolean
  },
  setup(props, { emit }) {
    const state = reactive({
      categoryOptions: props.options,
      categorySelected: props.value || null
    });

    watch(
      () => props.options,
      (value) => {
        state.categoryOptions = value;
      }
    );

    watch(
      () => props.value,
      (value) => {
        state.categorySelected = value;
      }
    );

    onMounted(() => {
      if (props.fetchOnLoad) {
        getCategories({Type: props.type})
          .then(getTransactionCategoriesSuccess)
          .catch(getTransactionCategoriesError);
      }
    });

    const getTransactionCategoriesSuccess = (data) => {
      state.categoryOptions = data.items;
    };

    const getTransactionCategoriesError = () => {
        showErrorToast("Ocorreu um erro ao buscar as categorias. Tente novamente.");
    };

    const onChange = (option) => {
      const category = state.categoryOptions.find(cat => cat.id === option);
      state.categorySelected = category.id;
      emit("change-category", category);
    };

    return {
      state,
      onChange,
    };
  },
};
</script>
