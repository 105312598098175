import * as categoryResource from './categoryResource'

export async function getCategories(params) {
    const { data } = await categoryResource.getCategories(params);

    return data;
}

export async function getCategoryById(id) {
    const { data } = await categoryResource.getCategoryById(id);

    return data;
}

export async function saveCategory(params) {
    const { data } = await categoryResource.saveCategory(params);

    return data;
}

export async function updateCategory(id, params) {
    const { data } = await categoryResource.updateCategory(id, params);

    return data;
}

export async function removeCategory(id) {
    return await categoryResource.removeCategory(id);
}
