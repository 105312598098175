export const TRANSACTION_TYPES = Object.freeze({
    REVENUE: {
        name: 'Receita',
        code: 0
    },
    EXPENSE: {
        name: 'Despesa',
        code: 1
    }
})

export const TRANSACTION_TYPES_SELECT = Object.freeze([
    {
        name: 'Receita',
        value: 0
    },
    {
        name: 'Despesa',
        value: 1
    }
])

export const TRANSACTION_TYPES_SELECT_ALL = Object.freeze([
    {
        name: 'Todas',
        value: 'ALL'
    },
    {
        name: 'Receita',
        value: 0
    },
    {
        name: 'Despesa',
        value: 1
    }
])

export const TRANSACTION_FREQUENCY_NAMES = Object.freeze(['diária', 'semanal', 'mensal', 'anual'])

export const TRANSACTION_FREQUENCY = Object.freeze([
    {
        name: 'Diário',
        value: 0
    },
    {
        name: 'Semanal',
        value: 1
    },
    {
        name: 'Mensal',
        value: 2
    },
    {
        name: 'Anual',
        value: 3
    }
])

export const TRANSACTION_RECURRENCE_TYPES = Object.freeze([
    {
        name: 'Única',
        key: 'only',
        value: 0
    },
    {
        name: 'Repete',
        key: 'repeat',
        value: 1
    },
    {
        name: 'Fixa',
        key: 'fixed',
        value: 2
    },
])

export const TRANSACTION_RECURRENCE_EDITION_TYPES = Object.freeze([
    {
        name: 'Esta transação',
        key: 'current',
        value: 0
    },
    {
        name: 'Esta e as transações seguintes',
        key: 'thisAndFuture',
        value: 1
    },
    {
        name: 'Todas as transações',
        key: 'all',
        value: 2
    },
    {
        name: 'Esta e as transações anteriores',
        key: 'previous',
        value: 3
    },
])

export const TRANSACTIONS_COLUMNS = Object.freeze([
    {
        name: "Descrição",
        key: "description",
        sortable: true,
    },
    {
        name: "",
        key: "recurrence",
        sortable: false,
        classes: 'recurrence-icon'
    },
    {
        name: "Data",
        key: "transactionDate",
        sortable: true,
    },
    {
        name: "Situação",
        key: "wasPaid",
        sortable: true,
    },
    {
        name: "Categoria",
        key: "category",
        sortable: true,
    },
    {
        name: "Valor (R$)",
        key: "value",
        sortable: true,
    },
    {
        name: "",
        key: "actions",
        sortable: false,
    },
]);
