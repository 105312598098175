<template>
    <button class="toggle-currency-visibility-button btn button-outline small" @click="toggleVisibility" v-tooltip="tooltip">
      <fa-icon :icon="icon" />
      <span>{{ legend }}</span>
    </button>
</template>

<script>
import { isMobile } from "@/services/deviceService";
import { useUserDataStore } from "@/store/userData";
import { computed } from 'vue';

export default {
  setup() {
    const userStore = useUserDataStore();

    const toggleVisibility = () => {
      userStore.toggleUserShowCurrencyValues();
    };

    const icon = computed(() => {
      const icon = showValue.value ? 'fa-eye-slash' : 'fa-eye'
      return `fa-solid ${icon}`;
    })

    const tooltip = computed(() => {
      if (isMobile()) {
        return ''
      }

      return showValue.value ? 'Ocultar Valores' : 'Exibir Valores';
    })

    const legend = computed(() => {
      return showValue.value ? 'Ocultar' : 'Exibir';
    })

    const showValue = computed(() => userStore.getShouldShowCurrencyValues)

    return {
      icon,
      tooltip,
      legend,
      showValue,
      toggleVisibility,
    };
  },
};
</script>

<style lang="scss" src="./ToggleCurrencyVisibilityButton.scss" />
