<template>
  <div
    class="cpf-input form-group"
    :class="[
      hasError ? 'cpf-input__with-error' : '',
      disabled ? 'cpf-input__disabled' : '',
    ]"
  >
    <label v-if="label" class="cpf-input__label" :for="name">
      {{ label }}
      <span v-if="required" class="cpf-input__required">*</span>
    </label>
    <input
      class="cpf-input__input form-control"
      :type="type"
      :id="name"
      :name="name"
      v-model="state.modelValue"
      :required="required"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      v-mask="'###.###.###-##'"
      @blur="onBlur"
      @input="onInput"
      @change="onChange"
    />
    <span
      v-if="state.errorMessage || messageError"
      class="cpf-input__error"
      >{{ state.errorMessage || messageError }}</span
    >
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";

export default {
  props: {
    label: String,
    name: String,
    value: [String, Number, Object, Array],
    type: {
      type: String,
      default: "text",
    },
    required: Boolean,
    disabled: Boolean,
    cpfValidations: Array,
    messageError: String,
    placeholder: String,
    autocomplete: {
      type: String,
      default: 'on'
    },
    mask: String
  },
  setup(props, { emit }) {
    const state = reactive({
      modelValue: props.value,
      errorMessage: null,
    });

    const onBlur = () => {
      if (props.required && !state.modelValue) {
        state.errorMessage = "Este campo é obrigatório.";
      }

      emit("blur");
    };

    watch(
      () => props.value,
      (newValue) => {
        state.modelValue = newValue;
      }
    );

    const onChange = () => {};

    const validate = () => {
      state.errorMessage = null;

      const validations = props.cpfValidations;
      if (!validations) {
        return;
      }

      for (const validation of validations) {
        const validationResult = validation.valid(state.modelValue);

        if (validationResult !== true) {
          state.errorMessage = validation.message;
          break;
        }
      }
    };

    const hasError = computed(() => {
      return state.errorMessage || props.messageError;
    });

    const onInput = () => {
      validate();

      emit("update:modelValue", state.modelValue);
    };

    return {
      state,
      onBlur,
      onInput,
      onChange,
      hasError,
    };
  },
};
</script>

<style lang="scss" src="./CpfInput.scss" />
