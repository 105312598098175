<template>
  <div class="payment-type-box">
    <div class="payment-type-box__card" v-if="isCardType">
        <div class="payment-type-box__card-brand">
            <img
                :src="require(`@/assets/images/payments/${paymentMethod.brand}.svg`)"
                :alt="`${paymentMethod.brand}`"
              />
        </div>
        <span class="payment-type-box__card-number">
            **** **** **** {{ paymentMethod.last4 }}
        </span>
        <span class="payment-type-box__card-expiration">
            {{ paymentMethod.expDate }}
        </span>
    </div>
    <div class="payment-type-box__pix"></div>
    <div class="payment-type-box__boleto"></div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: {
        paymentMethod: Object
    },
    setup(props) {
        const isCardType = computed({
            get() {
                return props.paymentMethod?.type === "Card";
            }
        });

        return {
            isCardType
        }
    }
}
</script>

<style lang="scss" src="./PaymentTypeBox.scss" />
