<template>
  <div class="import-transactions-view" :key="state.key">
    <box-page title="Importar transações">
      <div class="import-transactions-view__header">
        <h3 class="import-transactions-view__title">Selecione o tipo de arquivo</h3>
        <toggle-button ref="toggleButtonRef" class="import-transactions-view__file-type" :model-value="state.isCsvFile"
          is-small left-label="OFX" rightLabel="CSV" @change="onChangeFileType" />
      </div>
      <div class="import-transactions-view__import-file import-transactions-view__import-file--csv">
        <template v-if="state.isCsvFile">
          <p>
            Importe sua planilha financeira para adicionar suas transações ao nosso sistema de forma simples e rápida.
          </p>
          <p>Basta importar o arquivo CSV e então <strong>editar a descrição,
              o tipo de transação (receita ou despesa)</strong>, selecionar a <strong>categoria</strong> e
            então <strong>salvar</strong>.</p>

          <a class="import-transactions-view__download-model" href="/files/calculando-riquezas-import.xlsx"
            download="calculando-riquezas-import.xlsx">Baixar arquivo CSV modelo</a>
        </template>
        <template v-else>
          <p>
            Importe seu extrato bancário ou do cartão de crédito a partir de um arquivo OFX para
            adicionar suas transações financeiras ao nosso sistema de forma simples e rápida.
          </p>
          <p>Basta importar o arquivo OFX e então <strong>editar a descrição,
              o tipo de transação (receita ou despesa)</strong>, selecionar a <strong>categoria</strong> e
            então <strong>salvar</strong>.</p>
        </template>
        <div class="import-transactions-view__form">
          <input-file label="Selecionar arquivo" name="import-file" :accept-files="fileTypeAccepted"
            @onChange="onSelectFile" :disabled="disableImport" :tooltip="importFileTooltip" />
          <request-button label="Enviar" :fetch-action="sendFile" @fetch-success="onFileUploadSuccess"
            @fetch-error="onFileUploadError" :disabled="disableImport" />
        </div>
      </div>
      <div v-if="state.transactionsData" class="import-transactions-view__transactions-list">
        <box-page title="Revisar transações">
          <div>
            <imported-transactions-list :transactionsData="state.transactionsData" @reset-form="resetData"
              :file-type="fileType" />
          </div>
        </box-page>
      </div>
    </box-page>
  </div>
</template>

<script>
import { onMounted, reactive, computed, ref } from 'vue';
import { useUserDataStore } from "@/store/userData";
import { uploadTransactions, getImportLimitReached } from '../services/transactionsService';
import { showErrorToast } from '@/services/toastService';
import { useModal } from "vue-final-modal";
import BoxPage from '@/components/BoxPage/BoxPage.vue';
import InputFile from '@/components/InputFile/InputFile';
import ImportedTransactionsList from '../components/ImportedTransactionsList/ImportedTransactionsList.vue';
import RequestButton from '@/components/RequestButton/RequestButton.vue';
import ToggleButton from '@/components/ToggleButton/ToggleButton.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';

const ALL_TRANSACTIONS_CODE = 2;

export default {
  components: { BoxPage, InputFile, ImportedTransactionsList, RequestButton, ToggleButton },
  setup() {
    const userStore = useUserDataStore();
    const toggleButtonRef = ref(null)
    const state = reactive({
      transactionsData: null,
      file: null,
      key: 1,
      isCsvFile: false,
      limitsReached: {}
    });

    const shouldVerifyLimit = computed({
      get() {
        return !userStore.isPremiumUser;
      }
    })

    const fileType = computed({
      get() {
        return state.isCsvFile ? 'csv' : 'ofx';
      }
    })

    const fileTypeAccepted = computed({
      get() {
        return state.isCsvFile ? '.csv' : '.ofx';
      }
    })

    const importFileTooltip = computed({
      get() {
        if (state.isCsvFile && state.limitsReached.csvLimitReached) {
          return 'Você atingiu o limite de importações de arquivos .csv da sua conta gratuita.'
        }

        if (!state.isCsvFile && state.limitsReached.ofxLimitReached) {
          return 'Você atingiu o limite de importações de arquivos .ofx da sua conta gratuita.'
        }

        return ''
      }
    })

    const disableImport = computed({
      get() {
        if (state.limitsReached.ofxLimitReached || state.limitsReached.csvLimitReached) {
          return true;
        }

        return false
      }
    })

    onMounted(() => {
      if (shouldVerifyLimit.value) {
        getImportLimitReached().then(onGetImportLimitReachedSuccess)
      }
    })

    const onChangeFileType = () => {
      if(!state.file) {
        state.isCsvFile = !state.isCsvFile;
        return;
      }

      openChangeTypeModal();
    }

    const changeFileType = () => {
      state.isCsvFile = !state.isCsvFile;
      resetData();
    }

    const { open: openChangeTypeModal, close: closeChangeTypeModal } = useModal({
      component: ConfirmationModal,
      attrs: {
        buttonType: 'alert',
        title: 'Alterar tipo de arquivo',
        description: `Tem certeza de que deseja alterar o tipo do arquivo? Ao confirmar, os dados atuais serão perdidos.`,
        confirmLabel: 'Confirmar',
        onClose() {
          toggleButtonRef.value.revertState()
          closeChangeTypeModal()
        },
        onConfirmAction() {
          changeFileType()
          closeChangeTypeModal()
        },
        onCancel() {
          toggleButtonRef.value.revertState()
          closeChangeTypeModal()
        },
      }
    });

    const onGetImportLimitReachedSuccess = (data) => {
      state.limitsReached = data
    }

    const onSelectFile = (file) => {
      if (!file) {
        return;
      }

      state.file = file;
    }

    const sendFile = () => {
      if (!state.file) {
        return;
      }

      const formData = new FormData();
      formData.append("file", state.file);
      formData.append("transactionType", ALL_TRANSACTIONS_CODE);

      return uploadTransactions(fileType.value, formData);
    }

    const onFileUploadSuccess = (data) => {
      const { totalItems, items } = data;

      const itensFormatted = items.map((item, index) => {
        return {
          ...item,
          id: `${index}_${Math.floor(Math.random() * 100000)}`
        }
      });

      state.transactionsData = {
        totalItems,
        items: itensFormatted
      }
    }

    const onFileUploadError = () => {
      showErrorToast("Ocorreu um erro ao importar o arquivo. Tente novamente.");
    }

    const resetData = () => {
      state.key += 1;
      state.transactionsData = null;
      state.file = null;
    }

    return {
      state,
      importFileTooltip,
      fileTypeAccepted,
      fileType,
      disableImport,
      toggleButtonRef,
      onSelectFile,
      sendFile,
      resetData,
      onFileUploadSuccess,
      onFileUploadError,
      onChangeFileType
    }
  }
}
</script>

<style lang="scss" src="./styles/ImportTransactionsView.scss" />
