<template>
  <div class="pagination">
    <div class="pagination__register-quantity">
      <custom-select
        label="Registros por página"
        :options="state.registersPerPageOptions"
        @change="onChangeRegisterPerPage"
        :value="state.registersPerPageQuantity"
      />
    </div>
    <nav v-if="state.totalRegisters" aria-label="navigation" class="pagination__navigation">
      <ul class="pagination__pagination">
        <li class="pagination__page-item">
          <a
            class="pagination__page-link"
            @click.stop.prevent="selectPage(1)"
            :disabled="disablePrev"
            href="#"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="pagination__page-item">
          <a
            class="pagination__page-link"
            @click.stop.prevent="selectPage(state.selectedPage - 1)"
            :disabled="disablePrev"
            href="#"
            >{{ prevPageText }}</a
          >
        </li>
        <li
          v-for="page in visiblePages"
          :key="page"
          class="pagination__page-item"
        >
          <a
            class="pagination__page-link"
            @click.stop.prevent="selectPage(page)"
            :class="{
              'pagination__page-link--active': state.selectedPage === page,
            }"
            href="#"
            >{{ page }}</a
          >
        </li>
        <li class="pagination__page-item">
          <a
            class="pagination__page-link"
            :disabled="disableNext"
            @click.stop.prevent="selectPage(state.selectedPage + 1)"
            href="#"
          >
            {{ nextPageText }}</a
          >
        </li>
        <li class="pagination__page-item">
          <a
            class="pagination__page-link"
            :disabled="disableNext"
            @click.stop.prevent="selectPage(totalPages)"
            href="#"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { isMobile } from '@/services/deviceService'
import CustomSelect from "../CustomSelect/CustomSelect.vue";

const REGISTERS_PER_PAGE_OPTIONS = [
  {
    name: "10",
    value: 10,
  },
  {
    name: "20",
    value: 20,
  },
  {
    name: "50",
    value: 50,
  },
  {
    name: "100",
    value: 100,
  },
];

export default {
  components: { CustomSelect },
  props: {
    totalItems: Number,
    pageNumber: Number,
    pageSize: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      totalRegisters: props.totalItems,
      registersPerPageOptions: REGISTERS_PER_PAGE_OPTIONS,
      registersPerPageQuantity: props.pageSize || 10,
      selectedPage: props.pageNumber || 1,
      quantityPagesShow: isMobile() ? 2 : 4
    });

    watch(
      () => props.totalItems,
      (value) => {
        state.totalRegisters = value;
      }
    );

    watch(
      () => props.pageNumber,
      (value) => {
        state.selectedPage = value;
      }
    );

    watch(
      () => props.pageSize,
      (value) => {
        state.registersPerPageQuantity = value;
      }
    );

    const totalPages = computed({
      get() {
        return Math.ceil(state.totalRegisters / state.registersPerPageQuantity);
      },
    });

    const visiblePages = computed({
      get() {
        const startPage =
          state.selectedPage > 2 ? Math.max(1, state.selectedPage - 2) : 1;
        const endPage = Math.min(totalPages.value, startPage + state.quantityPagesShow);

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        return pages;
      },
    });

    const disablePrev = computed({
      get() {
        return state.selectedPage === 1;
      },
    });

    const disableNext = computed({
      get() {
        return state.selectedPage === totalPages.value;
      },
    });

    const prevPageText = computed({
      get() {
        return isMobile() ? '<' : "Anterior"
      }
    })

    const nextPageText = computed({
      get() {
        return isMobile() ? '>' : "Próxima"
      }
    })

    const selectPage = (page) => {
      state.selectedPage = page;

      emit('page-selected', page)
    };

    const onChangeRegisterPerPage = (quantity) => {
      state.registersPerPageQuantity = quantity;
      state.selectedPage = 1;
      
      emit('page-quantity-updated', quantity);
      
    };

    return {
      state,
      totalPages,
      visiblePages,
      disablePrev,
      disableNext,
      prevPageText,
      nextPageText,
      selectPage,
      onChangeRegisterPerPage
    };
  },
};
</script>

<style lang="scss" src="./Pagination.scss" />
