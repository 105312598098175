import * as billingResource from './billingResource';

export async function getSubscriptions() {
    const { data } = await billingResource.getSubscriptions();

    return data;
}

export async function cancelSubscription(params) {
    const { data } = await billingResource.cancelSubscription(params);

    return data;
}

export async function renewalSubscription(params) {
    const { data } = await billingResource.renewalSubscription(params);

    return data;
}
