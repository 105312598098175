<template>
    <div class="recovery-password-code-form">
        <p class="recovery-password-code-form__description">Preencha o campo abaixo com o código recebido no seu e-mail
        </p>
        <div class="recovery-password-code-form__content">
            <form @submit.prevent>
                <custom-input label="Código" :value="state.code" v-model="state.code" required name="code" />
                <div class="recovery-password-code-form__actions">
                    <request-button type="submit" class="account-data-form__btn-update" :fetch-action="submitForm"
                        @fetch-success="onValidateCodeSuccess" @fetch-error="onValidateCodeError"
                        label="Verificar código" />
                    <button class="recovery-password-code-form__resend-code btn button-outline" @click="onClickGetCode">
                        Enviar novo código
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { getRecoveryPasswordCode, checkPasswordToken } from '../../services/userService';
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { validateRecaptcha } from "@/modules/common/services/recaptcha/recaptchaService";
import CustomInput from '@/components/CustomInput/CustomInput.vue';
import RequestButton from '@/components/RequestButton/RequestButton.vue';

export default {
    components: {
        CustomInput,
        RequestButton
    },
    props: {
        email: String,
    },
    setup(props, { emit }) {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

        const state = reactive({
            code: null
        })

        const submitForm = () => {
            if (!props.email && !state.code) {
                return;
            }

            return submitRecaptcha();
        }

        const submitRecaptcha = async () => {
            if (!props.email && !state.code) {
                return;
            }

            await recaptchaLoaded()
            const recaptchaAction = 'recovery_password';
            const token = await executeRecaptcha(recaptchaAction);
            const isValid = await validateRecaptcha({ token, recaptchaAction });

            if (isValid) {
                return onClickValidateCode();
            }

            return Promise.reject();
        }

        const onClickValidateCode = () => {
            if (!props.email && !state.code) {
                return;
            }

            const formData = {
                email: props.email,
                token: state.code
            }

            return checkPasswordToken(formData);
        }

        const onValidateCodeSuccess = () => {
            emit('set-step', { step: 2, email: props.email, code: state.code })
        }

        const onValidateCodeError = () => {
            showErrorToast('Algo deu errado. Tente novamente mais tarde.')
        }

        const onClickGetCode = () => {
            if (!props.email) {
                return;
            }

            getRecoveryPasswordCode(props.email)
                .then(getRecoveryPasswordCodeSuccess)
                .catch(getRecoveryPasswordCodeError)
        }

        const getRecoveryPasswordCodeSuccess = () => {
            showSuccessToast('Código enviado com sucesso para o seu e-mail.')
        }

        const getRecoveryPasswordCodeError = () => {
            showErrorToast('Código incorreto. Verifique e tente novamente.')
        }

        return {
            state,
            submitForm,
            onValidateCodeSuccess,
            onValidateCodeError,
            onClickGetCode
        }
    }
}
</script>

<style lang="scss" src="./RecoveryPasswordCodeForm.scss" />
