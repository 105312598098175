<template>
    <div class="goal-card">
        <div class="goal-card__icon" :style="`background-color: ${goalData.color}`">
            <fa-icon :icon="`fa-solid ${goalData.icon}`" />
        </div>
        <h3 class="goal-card__name" :style="`color: ${goalData.color}`">{{ goalData.name }}</h3>
        <h4 class="goal-card__value">
            <toggle-currency-visibility :value="goalData.value" />
        </h4>
        <progress-bar class="goal-card__progress" :current-value="goalData.currentValue" :total-value="goalData.value"/>
        <div class="goal-card__footer">
            <button @click="openCreateGoalDepositModal" v-tooltip="'Aplicar valor'">
                <fa-icon icon="fa-solid fa-plus" />
            </button>
            <button @click="editGoal(goalData.id)" v-tooltip="'Editar objetivo'">
                <fa-icon icon="fa-solid fa-pen-to-square" />
            </button>
            <button @click="openDeletionConfirmModal" v-tooltip="'Exluir valor'">
                <fa-icon icon="fa-solid fa-trash" />
            </button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { removeGoal } from "../../services/goal/goalService";
import { useModal } from "vue-final-modal";
import { useRouter } from 'vue-router';
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import ProgressBar from '@/modules/common/components/ProgressBar/ProgressBar.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';
import CreateGoalDepositModal from '../CreateGoalDepositModal/CreateGoalDepositModal.vue';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
    components: { ProgressBar, ToggleCurrencyVisibility },
    props: {
        goalData: Object
    },
    setup(props, { emit }) {
        const router = useRouter();

        const editGoal = (id) => {
            router.push(`/finance/goals/${id}`)
        }

        const { open: openDeletionConfirmModal, close: closeDeletionConfirmModal } = useModal({
            component: ConfirmationModal,
            attrs: {
                buttonType: 'alert',
                title: 'Excluir objetivo',
                description: `Tem certeza de que deseja excluir o objetivo ${props.goalData?.name}?`,
                confirmLabel: 'Confirmar',
                onClose() {
                    closeDeletionConfirmModal()
                },
                onConfirmAction() {
                    deleteGoal()
                    closeDeletionConfirmModal()
                },
                onCancel() {
                    closeDeletionConfirmModal()
                },
            }
        });

        const { open: openCreateGoalDepositModal, close: closeCreateGoalDepositModal } = useModal({
            component: CreateGoalDepositModal,
            attrs: {
                goalId: computed(() => props.goalData.id),
                onClose() {
                    closeCreateGoalDepositModal()
                },
                onConfirm() {
                    closeCreateGoalDepositModal()
                },
                onSuccess() {
                    emit('refresh-data')
                },
                onCancel() {
                    closeCreateGoalDepositModal()
                },
            }
        });

        const deleteGoal = () => {
            const id = props.goalData?.id;
            removeGoal(id)
                .then(onDeleteGoalSuccess)
                .catch(onDeleteGoalError);
        }

        const onDeleteGoalSuccess = () => {
            showSuccessToast("Objetivo excluído com sucesso!");
            emit('refresh-data')
        }

        const onDeleteGoalError = () => {
            showErrorToast('Ocorreu um erro ao excluir o objetivo. Tente novamente.');
        }

        return {
            editGoal,
            openDeletionConfirmModal,
            openCreateGoalDepositModal
        }
    }
}
</script>

<style lang="scss" src="./GoalCard.scss" />
