<template>
  <nav class="navbar">
    <div class="navbar__container">
      <div class="navbar__header">
        <div class="navbar__hamburguer" @click.stop.prevent="toggleMenuMobile">
          <fa-icon icon="fa-solid fa-bars" />
        </div>
        <router-link to="/">
          <img class="navbar__logo" src="../../assets/images/logo.png" alt="Calculando Riquezas" />
        </router-link>
      </div>
      <toggle-currency-visibility-button />
      <div class="navbar__user user-resume">
        <div class="user-resume__infos" @click="toggleActions">
          <p class="user-resume__name">Olá, {{ nameFormatted }}</p>
          <div :alt="nameFormatted" class="user-resume__image">{{ firstLetterName }}</div>
        </div>
        <div ref="actionsMenuRef" class="user-resume__actions" :class="{ 'user-resume__actions--open': state.isActionsOpened }">
          <ul>
            <li @click="toggleActions">
              <router-link to="/user/account-settings">Minha conta</router-link>
            </li>
            <li @click="toggleActions">
              <router-link to="/user/my-plan">Meus planos</router-link>
            </li>
            <li @click="toggleActions">
              <router-link to="/support">Suporte</router-link>
            </li>
            <li>
              <a @click="handleLogoff">Sair</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { deleteCookie } from "@/services/cookiesService";
import { useRouter } from "vue-router";
import { useUserDataStore } from "@/store/userData";
import { logout } from "../../modules/user/services/authService";
import { computed, onMounted, reactive, watch, ref } from 'vue';
import { onClickOutside } from "@vueuse/core";
import ToggleCurrencyVisibilityButton from "@/modules/common/components/ToggleCurrencyVisibilityButton/ToggleCurrencyVisibilityButton.vue";

export default {
  components: { ToggleCurrencyVisibilityButton },
  setup(props, { emit }) {
    const router = useRouter();
    const userStore = useUserDataStore();

    const state = reactive({
      name: null,
      isActionsOpened: false,
    })

    const actionsMenuRef = ref(null);
    onClickOutside(actionsMenuRef, () => closeActions(), { ignore: ['.user-resume__infos']});

    const toggleActions = () => {
      state.isActionsOpened = !state.isActionsOpened
    }

    const closeActions = () => {
      state.isActionsOpened = false
    }

    const handleLogoff = () => {
      logout()
        .then(() => {
          deleteCookie("auth-token");
          deleteCookie("refresh-token");
          userStore.resetAuthData();
          router.push("/login");
        })
        .catch(() => {
          router.push("/login");
        });
    };

    const nameFormatted = computed({
      get() {
        return state.name ? state.name.split(' ')[0] : ''
      }
    })

    const firstLetterName = computed({
      get() {
        return nameFormatted.value ? nameFormatted.value.charAt(0).toUpperCase() : ''
      }
    })

    watch(() => userStore.getUserName, () => {
      handleUserData();
    })

    onMounted(() => {
      handleUserData();
    });

    const handleUserData = () => {
      state.name = userStore.getUserName;
    };

    const toggleMenuMobile = () => {
      emit('toggle-menu-mobile')
    }

    return {
      state,
      nameFormatted,
      firstLetterName,
      actionsMenuRef,
      toggleActions,
      handleLogoff,
      toggleMenuMobile
    };
  },
};
</script>

<style lang="scss" src="./TopMenu.scss" />
