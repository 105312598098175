<template>
  <router-view />
  <ModalsContainer />
</template>

<script>
import { ModalsContainer } from "vue-final-modal";

export default {
  components: { ModalsContainer },
};
</script>