<template>
  <div v-if="state.showBanner" class="install-app-banner">
    <p>Instale nosso aplicativo gratuito. Não utiliza memória no seu dispositivo.</p>
    <button @click="installPWA" class="btn button-primary button-primary--white">Instalar</button>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue';

export default {
  setup() {
    const state = reactive({
      showBanner: false
    })

    let deferredPrompt = null;

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        deferredPrompt = e;
        state.showBanner = true;
      });

      if (window.matchMedia('(display-mode: standalone)').matches) {
        state.showBanner = false;
      }

      window.addEventListener('appinstalled', () => {
        state.showBanner = false;
      });
    });

    const installPWA = async () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        deferredPrompt = null;
        if (outcome === 'accepted') {
          state.showBanner = false;
        }
      }
    };

    return {
      state,
      installPWA
    }
  }
}
</script>

<style lang="scss" src="./InstallAppBanner.scss" />
