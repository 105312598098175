<template>
  <ul class="plan-details-list-mobile">
    <li v-for="item in items" :key="item" class="plan-details-list-mobile__item">
      <plan-include-icon included />
      <p>{{ item }}</p>
    </li>
  </ul>
</template>

<script>
import PlanIncludeIcon from './PlanIncludeIcon.vue';

export default {
  components: { PlanIncludeIcon },
  props: {
    items: Array
  }
}
</script>

<style lang="scss" src="./PlanDetailsListMobile.scss" />
