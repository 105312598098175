<template>
    <div class="search-input">
        <custom-input v-model="state.searchTerm" :placeholder="placeholder" :label="label" 
            :name="name" :value="value" :type="type" :required="required" :disabled="disabled"
            :customValidations="customValidations" :messageError="messageError" :autocomplete="autocomplete" @input="onInput"/>
        <button class="btn button-primary" @click="onSearchInput">
            <fa-icon icon="fa-solid fa-magnifying-glass"/>
        </button>
    </div>
</template>

<script>
import { reactive } from 'vue'
import CustomInput from '../CustomInput/CustomInput.vue'

export default {
    components: { CustomInput },
    props: {
        label: String,
        name: String,
        value: [String, Number, Object, Array],
        type: {
            type: String,
            default: "text",
        },
        required: Boolean,
        disabled: Boolean,
        customValidations: Array,
        messageError: String,
        placeholder: String,
        autocomplete: {
            type: String,
            default: 'on'
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            searchTerm: props.value
        })

        const onSearchInput = () => {
            emit('on-search', state.searchTerm)
        }

        const onInput = () => {
            emit('update:modelValue', state.searchTerm)
        }

        return {
            state,
            onSearchInput,
            onInput
        }
    }
}
</script>

<style lang="scss" src="./SearchInput.scss" />
