export const ICONS_OPTIONS = Object.freeze([
    'fa-house',
    'fa-coins',
    'fa-money-bill',
    'fa-money-check',
    'fa-money-bill-trend-up',
    'fa-credit-card',
    'fa-wallet',
    'fa-receipt',
    'fa-dollar-sign',
    'fa-notes-medical',
    'fa-suitcase-medical',
    'fa-shop',
    'fa-cart-shopping',
    'fa-store',
    'fa-car',
    'fa-motorcycle',
    'fa-ship',
    'fa-ferry',
    'fa-plane',
    'fa-phone',
    'fa-wifi',
    'fa-video',
    'fa-key',
    'fa-bolt',
    'fa-dog',
    'fa-cat',
    'fa-graduation-cap',
    'fa-school',
    'fa-carrot',
    'fa-burger',
    'fa-piggy-bank',
    'fa-hand-holding-dollar',
    'fa-briefcase',
    'fa-cash-register',
    'fa-gas-pump',
    'fa-utensils',
    'fa-bus',
    'fa-coffee',
    'fa-gift',
    'fa-ticket',
    'fa-building',
    'fa-gamepad',
    'fa-headset'
])