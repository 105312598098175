import { createApp } from "vue";
import { createPinia } from 'pinia';
import App from "./App.vue";
import router from "./router";
import Toast, { POSITION } from "vue-toastification";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vue3GoogleLogin from 'vue3-google-login'
import { createVfm } from 'vue-final-modal'
import VueDatePicker from '@vuepic/vue-datepicker';
import VueApexCharts from "vue3-apexcharts";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import money from 'v-money3'
import './plugins/faIcons.js';
import './registerServiceWorker';
import VueTheMask from 'vue-the-mask';
import VueTippy from 'vue-tippy';
import { blockIframe } from "./plugins/iframeBlocker";

import "driver.js/dist/driver.css";
import './assets/scss/main.scss';
import '@vuepic/vue-datepicker/dist/main.css';
import "vue-toastification/dist/index.css";
import 'vue-final-modal/style.css';
import 'tippy.js/dist/tippy.css';

blockIframe();

const toastOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
}

const pinia = createPinia()
const vfm = createVfm()

createApp(App)
    .use(pinia)
    .use(router)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SECRET })
    .use(vue3GoogleLogin, {
        clientId: process.env.VUE_APP_GOOGLE_LOGIN_SECRET
    })
    .use(Toast, toastOptions)
    .use(vfm)
    .use(money)
    .use(VueTheMask)
    .use(
        VueTippy,
        {
          directive: 'tooltip',
          defaultProps: {
            placement: 'top',
            allowHTML: true,
          },
        }
      )
    .use(VueApexCharts)
    .component('fa-icon', FontAwesomeIcon)
    .component('VueDatePicker', VueDatePicker)
    .mount("#app");
