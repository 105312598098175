<template>
  <div class="color-picker" @close.prevent.stop>
    <input class="color-picker__input" type="text" ref="colorisInput"
      @input="onInputColor" v-model="state.modelValue" />
    <div class="color-picker__button" @click="openColorPicker" :style="{ backgroundColor: state.modelValue }"></div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue';
import { coloris, init } from '@melloware/coloris';
import '@melloware/coloris/dist/coloris.css';

export default {
  name: 'ColorPicker',
  props: {
    color: {
      type: String,
      default: '#705cf2'
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      modelValue: props.color
    });
    const colorisInput = ref(null);

    watch(() => props.color, (newValue) => {
      state.modelValue = newValue;
    });

    watch(() => state.modelValue, (newColor) => {
      emit('update:modelValue', newColor);
    });

    onMounted(() => {
      init();
      coloris({
        el: colorisInput.value,
        margin: 10,
        focusInput: false,
        swatches: [
          "#4a30f2",
          "#705cf2",
          "#00bd8e",
          "#d96055",
          "#ffb74d",
          "#8d6e63",
          "#e57373",
          "#64b5f6",
          "#4caf50",
          "#ffd54f",
          "#ba68c8",
          "#4dd0e1",
          "#f06292",
          "#a1887f"
        ],
        wrap: false,
        theme: 'polaroid',
        alpha: false
      });
    });

    const openColorPicker = () => {
      colorisInput.value.click();
    }

    const onInputColor = (event) => {
      state.modelValue = event.target.value;
    }

    return {
      state,
      colorisInput,
      openColorPicker,
      onInputColor
    };
  },
};
</script>

<style lang="scss" src="./ColorPicker.scss" />
