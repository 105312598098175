import requestService from '@services/requestService'

const BILLING_URL = '/billing'

export function getSubscriptions() {
    return requestService.get(`${BILLING_URL}/subscriptions`);
}

export function cancelSubscription(params) {
    return requestService.post(`${BILLING_URL}/cancel`, params);
}

export function renewalSubscription(params) {
    return requestService.post(`${BILLING_URL}/renewal`, params);
}
