<template>
  <custom-select
    label="Período"
    :options="state.frequencyOptions"
    @change="onChange"
    :value="state.frequencySelected"
    :disabled="disabled"
  />
</template>

<script>
import CustomSelect from "@/components/CustomSelect/CustomSelect.vue";
import { reactive, watch } from "vue";
import { TRANSACTION_FREQUENCY } from '../../constants/transactionConstants';

export default {
  components: { CustomSelect },
  props: {
    value: Number,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      frequencyOptions: TRANSACTION_FREQUENCY,
      frequencySelected: props.value
    });

    watch(
      () => props.value,
      (value) => {
        state.frequencySelected = value;
      }
    );

    const onChange = (option) => {
      const frequency = state.frequencyOptions.find(frequency => frequency.value === option);
      state.frequencySelected = frequency?.value;
      emit("change-frequency", frequency?.value);
    };

    return {
      state,
      onChange,
    };
  },
};
</script>
