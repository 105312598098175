import { defineStore } from 'pinia';
import { formatStartAndEndDate } from "@/services/formatService";

const currentDate = new Date();

export const useTransactionsFiltersStore = defineStore({
  id: 'filters',
  state: () => ({
    dataFilters: {
      StartDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      EndDate: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      Category: '',
      SearchTerm: '',
    },
    periodOption: null,
  }),
  getters: {
    getTransactionsFilters: (state) => {
      const filters = { 
        ...state.dataFilters,
        CategoryId: state.dataFilters.Category?.id
      };
      delete filters.Category;

      return filters;
    },
    getCategoryFilter: (state) => {
      return state.dataFilters.Category;
    },
    getTextualFilter: (state) => {
      return state.dataFilters.SearchTerm
    },
    getPeriodFormatted: (state) => {
      if (state.periodOption === 5) {
        return 'Todo o período'
      }

      if (state.dataFilters.StartDate === state.dataFilters.EndDate) {
        return 'Hoje'
      }

      return formatStartAndEndDate(state.dataFilters.StartDate, state.dataFilters.EndDate)
    },
    getMonthFilter: (state) => {
      const date = state.dataFilters.StartDate;
      return { month: date.getMonth(), year: date.getFullYear() };
    }
  },
  actions: {
    updatePeriod(startDate, endDate) {
      if (!startDate || !endDate) {
        return;
      }

      this.dataFilters.StartDate = startDate;
      this.dataFilters.EndDate = endDate;
    },
    updateAdvancedFilters(filters) {
      this.dataFilters = {
        ...this.dataFilters,
        ...filters
      }
    },
    updatePeriodOption(option) {
      this.periodOption = option
    },
    resetFilters() {
      this.dataFilters.Category = null;
      this.dataFilters.SearchTerm = null;
    },
    resetCategoryFilter() {
      this.dataFilters.Category = null;
    },
    resetSearchTermFilter() {
      this.dataFilters.SearchTerm = null;
    },
    resetStartDate() {
      this.dataFilters.StartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    },
    resetEndDate() {
      this.dataFilters.EndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
  },
});