<template>
    <div class="planning-category-card">
        <div class="planning-category-card__header">
            <div class="planning-category-card__icon" :style="`background-color: ${state.budget.categoryData.color}`">
                <fa-icon :icon="`fa-solid ${state.budget.categoryData.icon}`" />
            </div>
        </div>
        <div class="planning-category-card__content">
            <div class="planning-category-card__title">
                <h3 class="planning-category-card__name" :style="`color: ${state.budget.categoryData.color}`">
                    {{ state.budget.categoryData.name }}
                </h3>
                <div v-if="showActions" class="planning-category-card__actions">
                    <button @click="openCreatePlanningCategoryModal" v-tooltip="'Editar orçamento'">
                        <fa-icon icon="fa-solid fa-pen-to-square" />
                    </button>
                    <button @click="openDeletionConfirmModal" v-tooltip="'Exluir orçamento'">
                        <fa-icon icon="fa-solid fa-trash" />
                    </button>
                </div>
            </div>
            <planning-budget-goal :budget-data="state.budget" />
            <div v-if="shouldShowTransactionsList" class="planning-category-card__list">
                <collapse title="Ver transações" @toggle-collapse="onToggleCollapse">
                    <planning-transactions-list v-if="state.budgetTransactions"
                        :transactions="state.budgetTransactions"
                        :is-loading="state.isLoadingTransactions"/>
                </collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { getBudgetTransactions } from '../../services/planning/planningService';
import { showErrorToast } from '@/services/toastService';
import PlanningBudgetGoal from '../PlanningBudgetGoal/PlanningBudgetGoal.vue';
import CreatePlanningCategoryModal from '../CreatePlanningCategoryModal/CreatePlanningCategoryModal.vue';
import ConfirmationModal from '@/modules/common/components/ConfirmationModal/ConfirmationModal.vue';
import Collapse from '@/components/Collapse/Collapse.vue';
import PlanningTransactionsList from '../PlanningTransactionsList/PlanningTransactionsList.vue';

export default {
    components: { PlanningBudgetGoal, Collapse, PlanningTransactionsList },
    props: {
        budgetData: {
            type: Object,
            default: () => { }
        },
        showActions: {
            type: Boolean,
            default: true
        },
        showTransactions: {
            type: Boolean,
            default: true
        },
        categoryOptions: Array,
        availableValue: Number,
    },
    setup(props, { emit }) {
        const state = reactive({
            value: 0,
            budget: {
                ...props.budgetData,
                categoryData: {
                    ...props.budgetData?.categoryData
                }
            },
            budgetTransactions: null,
            isLoadingTransactions: true,
        })

        watch(() => props.budgetData, (value) => state.budget = value, { deep: true })

        const shouldShowTransactionsList = computed({
            get() {
                return props.showTransactions && state.budget.id && state.budget.currentValue > 0;
            }
        })

        const onEditBudget = (data) => {
            emit('edit-budget', data)
        }
        const onDeleteBudget = () => {
            emit('delete-budget', state.budget.categoryId)
        }

        const { open: openCreatePlanningCategoryModal, close: closeCreatePlanningCategoryModal } = useModal({
            component: CreatePlanningCategoryModal,
            attrs: {
                budgetData: computed(() => state.budget),
                categoryOptions: computed(() => props.categoryOptions),
                availableValue: computed(() => props.availableValue),
                onClose() {
                    closeCreatePlanningCategoryModal()
                },
                onAddBudget(data) {
                    closeCreatePlanningCategoryModal()
                    onEditBudget(data)
                },
                onCancel() {
                    closeCreatePlanningCategoryModal()
                }
            }
        });

        const { open: openDeletionConfirmModal, close: closeDeletionConfirmModal } = useModal({
            component: ConfirmationModal,
            attrs: {
                buttonType: 'alert',
                title: 'Excluir orçamento',
                description: computed(() => `Tem certeza de que deseja exluir o orçamento da categoria ${state.budget.categoryData?.name}?`),
                confirmLabel: 'Excluir',
                onClose() {
                    closeDeletionConfirmModal()
                },
                onConfirmAction() {
                    closeDeletionConfirmModal()
                    onDeleteBudget()
                },
                onCancel() {
                    closeDeletionConfirmModal()
                },
            }
        });

        const onToggleCollapse = (isOpen) => {
            if (isOpen && !state.budgetTransactions) {
                state.isLoadingTransactions = true;
                getBudgetTransactions(state.budget.id)
                    .then(onGetBudgetTransactionsSuccess)
                    .catch(onGetBudgetTransactionsError)
            }
        }

        const onGetBudgetTransactionsSuccess = (data) => {
            state.budgetTransactions = data?.budgetTransactions;
            state.isLoadingTransactions = false;
        }
        const onGetBudgetTransactionsError = () => {
            showErrorToast('Não foi possível buscar as transações. Tente novamente.')
            state.isLoadingTransactions = false;
        }

        return {
            state,
            shouldShowTransactionsList,
            onToggleCollapse,
            openCreatePlanningCategoryModal,
            openDeletionConfirmModal
        }
    }
}
</script>

<style lang="scss" src="./PlanningCategoryCard.scss" />