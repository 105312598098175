import requestService from '@services/requestService'

const AUTH_URL = '/auth'

export function login(loginData) {
    return requestService.post(`${AUTH_URL}/login`, loginData);
}

export function refreshLogin(headers) {
    return requestService.post(`${AUTH_URL}/refresh-login`, null, { headers });
}

export function googleLogin(loginData) {
    return requestService.post(`${AUTH_URL}/google-login`, loginData);
}

export function logout() {
    return requestService.post(`${AUTH_URL}/logout`)
}