<template>
  <div class="data-table-filters">
    <div
      v-if="showPeriodFilter"
      class="form-group data-table-filters__filter data-table__period-filter"
    >
      <label for="period">Período</label>
      <datepicker
        id="period"
        v-model="state.periodRange"
        :value="state.periodRange"
        name="period"
        clearable
        range
        @change="onChangePeriod"
        :max-date="new Date()"
      />
    </div>
    <div v-else class="form-group data-table-filters__filter data-table__monthly-filter">
      <label for="period">Mês</label>
      <VueDatePicker
        v-model="state.monthFilter"
        locale="pt"
        :max-date="new Date()"
        auto-apply
        month-picker
        @change="onChangeMonthPeriod"
      />
    </div>
    <div
      v-if="showSearchFilter"
      class="form-group data-table-filters__filter search-filter form-group"
    >
    <label for="period">Buscar</label>
      <div class="search-filter__group">
        <input
          type="text"
          v-model="state.textualSearch"
          @change="onChangeTextualSearch"
          class="form-control search-filter__input"
        />
        <div class="search-filter__icon">
          <fa-icon icon="fa-solid fa-magnifying-glass" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";

export default {
  components: { Datepicker },
  props: {
    monthlyDate: String,
    periodDate: String,
    showPeriodFilter: Boolean,
    showSearchFilter: Boolean,
  },
  setup(props, { emit }) {
    const state = reactive({
      periodRange: null,
      monthFilter: null,
      textualSearch: null,
    });

    onMounted(() => {
      const date = new Date();
      const month = date.getMonth();
      const year = date.getFullYear();

      if (props.showPeriodFilter) {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        state.periodRange = [firstDay, lastDay];
      } else {
        state.monthFilter = { month, year };
      }
    });

    const onChangePeriod = () => {
      emit('period-updated', state.periodRange)
    };

    const onChangeMonthPeriod = () => {
      emit('month-filter-updated', state.monthFilter)
    };

    const onChangeTextualSearch = () => {
      emit('textual-search-updated', state.textualSearch)
    };

    return {
      state,
      onChangePeriod,
      onChangeMonthPeriod,
      onChangeTextualSearch
    };
  },
};
</script>

<style lang="scss" src="./DataTableFilters.scss" />
