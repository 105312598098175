<template>
  <div class="dashboard-view">
    <page-header title="Visão geral">
      <VueDatePicker class="dashboard-view__period-picker" ref="transactionPeriodRef" v-model="state.yearFilter"
        locale="pt" :clearable="false" auto-apply year-picker @update:model-value="onChangePeriod" />
    </page-header>
    <premium-box-information />
    <box-page title="Balanço anual" id="dashboard-balance">
      <template #title>
        <badge :value="state.filterBadge" id="current-filters" />
      </template>
      <cards-resume :filters="state.filters" />
    </box-page>
    <div class="dashboard-view__widgets">
      <plannings-widget />
      <goals-widget />
    </div>
    <box-page title="Histórico financeiro anual" id="dashboard-transactions-chart">
      <template #title>
        <badge :value="state.filterBadge" />
      </template>
      <totals-transactions-chart :filters="state.filters" />
    </box-page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import { formatStartAndEndDate } from "@/services/formatService";
import { getTourData, initTour } from "@/modules/financial/services/tourService";
import { getLocalStorageItem } from "@/services/localStorageService";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import Badge from '@/components/Badge/Badge.vue';
import CardsResume from "../../financial/components/CardsResume/CardsResume.vue";
import TotalsTransactionsChart from "../../financial/components/TotalsTransactionsChart/TotalsTransactionsChart.vue";
import PremiumBoxInformation from "@/modules/common/components/PremiumBoxInformation/PremiumBoxInformation.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import PlanningsWidget from '@/modules/financial/components/PlanningsWidget/PlanningsWidget.vue';
import GoalsWidget from '@/modules/financial/components/GoalsWidget/GoalsWidget.vue';

export default {
  components: {
    BoxPage,
    TotalsTransactionsChart,
    CardsResume,
    Badge,
    PremiumBoxInformation,
    PageHeader,
    PlanningsWidget,
    GoalsWidget
  },
  setup() {
    const currentDate = new Date();
    const state = reactive({
      yearFilter: currentDate.getFullYear(),
      filters: {
        StartDate: new Date(currentDate.getFullYear(), 0, 1),
        EndDate: new Date(currentDate.getFullYear(), 11, 31),
      },
      filterBadge: null
    })

    onMounted(() => {
      state.filterBadge = formatStartAndEndDate(state.filters.StartDate, state.filters.EndDate);
    })

    const onChangePeriod = (year) => {
      const startDate = new Date(year, 0, 1);
      const endDate = new Date(year, 11, 31);

      state.filters.StartDate = startDate
      state.filters.EndDate = endDate
      state.filterBadge = formatStartAndEndDate(startDate, endDate);
    }

    onMounted(() => {
      sendPixelTrack('DashboardView');

      startTour();
    })

    const startTour = () => {
      const finishedWelcomeTour = getLocalStorageItem('finishedWelcomeTour')

      if (finishedWelcomeTour) {
        return;
      }

      const tour = initTour(getTourData('/'), 'finishedWelcomeTour')
      tour.drive()
    }

    return {
      state,
      onChangePeriod
    }
  }
};
</script>

<style lang="scss" src="./styles/DashboardView.scss" />
