<template>
  <div class="plan-include-icon" :class="{'plan-include-icon--not-included': !included}">
        <fa-icon v-if="!included" icon="fa-solid fa-xmark" />
        <fa-icon v-else icon="fa-solid fa-check" />
  </div>
</template>

<script>
export default {
    props: {
        included: Boolean
    }
}
</script>

<style lang="scss" src="./PlanIncludeIcon.scss" />
