<template>
    <loader v-if="state.isLoading" />
    <div v-else class="plan-card">
        <h2 class="plan-card__title">Plano Premium</h2>
        <div class="plan-card__price">
            <div class="price-box">
                <small>De</small>
                <h4>R$ {{ plansPrices.ANNUAL.FROM }}</h4>
            </div>
            <div class="price-box">
                <small>por</small>
                <h2>{{ plansPrices.ANNUAL.FOR }}<small> /anual</small></h2>
            </div>
        </div>
        <template v-if="state.hasError">
            <p class="plan-card__error">
                Ocorreu um erro ao carregar os planos. Tente novamente ou entre em contato com nossa equipe pelo suporte.
            </p>
            <button class="btn button-outline" @click="goToSupport">Acessar suporte</button>
        </template>
        <template v-else>
            <button class="btn button-primary" @click="goToCheckout(getAnnualPriceId, getAnnualPriceValue)">Assinar plano anual</button>
            <div class="plan-card__installments">
                <span>ou</span>
            </div>
            <button class="btn button-outline" @click="goToCheckout(getMonthyPriceId, getMonthyPriceValue)">{{ toCurrency(plansPrices.MONTHLY) }} mensal</button>
        </template>
    </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import { getProducts } from '../services/checkoutService';
import { useRouter } from 'vue-router';
import { setSessionItem } from '@/services/sessionStorageService';
import { showErrorToast } from "@/services/toastService";
import { PLANS_PRICES } from '@/modules/common/constants/priceConstants';
import { formatCurrency } from '@/services/currencyService';
import Loader from '@/components/Loader/Loader.vue';

export default {
    components: { Loader },
    setup() {
        const plansPrices = PLANS_PRICES;
        const router = useRouter();
        const state = reactive({
            planData: null,
            reloadRequest: 0,
            isLoading: true,
            hasError: false
        })

        onMounted(() => {
            getProductsData();
        })

        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        const getProductsData = () => {
            state.isLoading = true;
            getProducts().then(onGetProductsSuccess).catch(onGetProductsError)
        }

        const onGetProductsSuccess = (data) => {
            state.planData = data.products[0];
            state.isLoading = false;
        }

        const onGetProductsError = () => {
            if (state.reloadRequest >= 2) {
                showErrorToast('Ocorreu um erro. Tente novamente.');
                state.isLoading = false;
                state.hasError = true;
                return;
            }

            getProductsData();
            state.reloadRequest++;
        }

        const goToCheckout = (priceId, priceValue) => {
            setSessionItem('productId', btoa(priceId));
            setSessionItem('productPrice', btoa(priceValue));
            router.push("/checkout");
        }

        const goToSupport = () => {
            router.push("/support");
        }

        const getMonthyPriceId = computed({
            get() {
                return state.planData?.prices?.month[0]?.id
            }
        })

        const getAnnualPriceId = computed({
            get() {
                return state.planData?.prices?.year[0]?.id
            }
        })

        const getMonthyPriceValue = computed({
            get() {
                const price = state.planData?.prices?.month[0]?.price
                return price || PLANS_PRICES.MONTHLY
            }
        })

        const getAnnualPriceValue = computed({
            get() {
                const price = state.planData?.prices?.year[0]?.price
                return price || PLANS_PRICES.ANNUAL.FOR
            }
        })

        return {
            state,
            plansPrices,
            goToCheckout,
            goToSupport,
            toCurrency,
            getMonthyPriceId,
            getAnnualPriceId,
            getMonthyPriceValue,
            getAnnualPriceValue
        }
    }
}
</script>

<style lang="scss" src="./PlanCard.scss" />
