export function setSessionItem(name, value) {
    sessionStorage.setItem(name, value)
}

export function getSessionItem(name) {
    return sessionStorage.getItem(name)
}

export function deleteSessionItem(name) {
    sessionStorage.removeItem(name)
}