<template>
  <div class="plans-details-carrousel">
    <swiper :slides-per-view="1" :space-between="24" :pagination="{ clickable: true }">
      <swiper-slide>
        <div class="plans-details-carrousel__item">
          <div class="plans-details-carrousel__card">
            <div class="plan-card">
              <h3 class="plan-card__title">
                Free
              </h3>
              <p class="plan-card__description">
                Plano gratuito
              </p>
              <plan-details-list-mobile :items="plansDetails.FREE" />
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="plans-details-carrousel__item">
          <div class="plans-details-carrousel__card">
            <div class="plan-card">
              <h3 class="plan-card__title">
                Premium
              </h3>
              <div class="plan-card__price">
                <div class="price-box">
                  <small>De</small>
                  <p>R$ {{ plansPrices.ANNUAL.FROM }}</p>
                  <small class="margin-left">por</small>
                </div>
                <div class="price-box">
                  <h3>R$ {{ plansPrices.ANNUAL.FOR }}<small> /anual</small></h3>
                </div>
                <div class="price-installments">
                  <span>ou</span>
                </div>
                <div class="price-installments__value">
                  <p>{{ toCurrency(plansPrices.MONTHLY) }} mensal</p>
                </div>
              </div>
              <plan-details-list-mobile :items="plansDetails.PREMIUM" />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { PLANS_DETAILS_MOBILE } from '../constants/plansDetailsConstants'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import { PLANS_PRICES } from '@/modules/common/constants/priceConstants';
import { formatCurrency } from '@/services/currencyService';
import SwiperCore from 'swiper/core';
import PlanDetailsListMobile from './PlanDetailsListMobile.vue'

SwiperCore.use([Pagination]);

import 'swiper/swiper-bundle.css';

export default {
  name: 'PlansDetailsCarrousel',
  components: { PlanDetailsListMobile, Swiper, SwiperSlide },
  setup() {
    const plansDetails = PLANS_DETAILS_MOBILE;
    const plansPrices = PLANS_PRICES;

    const toCurrency = (value) => {
      return formatCurrency(value)
    }

    return {
      plansDetails,
      plansPrices,
      toCurrency
    }
  }
}
</script>

<style lang="scss" src="./PlansDetailsCarrousel.scss" />
