<template>
  <Modal
    :title="modalTitle"
    @close="onClose"
    @cancel="onCancel"
    :fetch-action="fetchAction"
    @fetch-success="onSaveTransactionSuccess"
    @fetch-error="onSaveTransactionError"
    :confirm-label="confirmLabel"
    button-submit
    class="create-transaction-modal">
    <template #default>
      <form @submit.prevent>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col">
                  <custom-input label="Descrição" :value="state.transaction.description"
                    v-model="state.transaction.description" required name="description" maxlength="150" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <currency-input label="Valor (R$)" :value="state.transaction.value" v-model="state.transaction.value"
                    required name="totalValue" />
                </div>
                <div class="col-lg-6">
                  <toggle-switch :label="getSituationLabel" :value="state.transaction.wasPaid" name="hasPaid"
                    @toggle="onChangeSituation" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <transaction-category-select :type="getTransactionType" required
                    @change-category="onChangeCategory" :value="state.transaction.categoryId" />
                </div>
                <div class="col-lg-6">
                  <div class="form-group date-select">
                    <label for="data-input">Data</label>
                    <datepicker
                      v-model="state.transaction.transactionDate"
                      :value="state.transaction.transactionDate"
                      name="data-input"
                      required
                      :disabled="shouldDisableDateField"
                    />
                    <div v-if="shouldDisableDateField">
                      <small class="alert">Não é possível editar data em transação recorrente</small>
                    </div>
                  </div>
                </div>
              </div>
              <collapse title="Informações de recorrência">
                <template #default>
                  <div class="row">
                    <div v-if="shouldDisableRecurrenceFields" class="col-lg-12 col-12">
                      <small class="alert">Não é possível editar informações de recorrência</small>
                    </div>
                    <div class="col-lg-2 col-3">
                      <div class="recurrence-types">
                        <div class="recurrence-types__group" v-for="recurrenceType in state.recurrenceTypes"
                          :key="recurrenceType.key">
                          <input :disabled="shouldDisableRecurrenceFields" type="radio" :id="recurrenceType.key" :name="recurrenceType.name"
                            :value="recurrenceType.value" v-model="state.transaction.recurrenceType" @change="onChangeRecurrenceType">
                          <label :for="recurrenceType.key">{{ recurrenceType.name }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-12">
                      <transaction-frequency-select :disabled="shouldDisableRepeatPeriodField || shouldDisableRecurrenceFields"
                        :value="state.transaction.recurrenceFrequency" @change-frequency="onChangeFrequency" 
                        :required="isRequiredRecurrenceFrequency"/>
                    </div>
                    <div class="col-lg-5 col-12">
                      <custom-input :disabled="shouldDisableRepeatTimesField || shouldDisableRecurrenceFields" label="Número de vezes"
                        :value="state.transaction.recurrenceAmount" v-model="state.transaction.recurrenceAmount"
                        :required="isRequiredRecurrenceAmount"
                        name="repeat-times" />
                    </div>
                  </div>
                </template>
              </collapse>
              <collapse title="Informações adicionais">
                <template #default>
                  <div class="row">
                    <div class="col-lg-12 form-group">
                      <label for="comment">Observações</label>
                      <textarea v-model="state.transaction.observation" id="comment" name="comment" rows="4"></textarea>
                    </div>
                  </div>
                </template>
              </collapse>
            </div>
          </div>
        </div>
      </form>
      <recurrence-edition-confirmation-modal v-if="state.showRecurrenceConfirmEdition"
        @onConfirm="onConfirmEditRecurrenceTransaction" @onCancel="onCancelEditRecurrenceTransaction" />
    </template>
  </Modal>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import {
  getTransactionById,
  saveTransaction,
  updateTransaction
} from "../../services/transactionsService";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { TRANSACTION_TYPES, TRANSACTION_RECURRENCE_TYPES } from '../../constants/transactionConstants';
import { useModal } from "vue-final-modal";
import { getTracerByName } from '@modules/common/services/tracer/tracerService';
import { getCurrentDate } from "@/modules/common/services/date/dateService";
import Modal from "@/components/Modal/Modal.vue";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import CurrencyInput from "@/components/CurrencyInput/CurrencyInput.vue";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch.vue";
import Collapse from "@/components/Collapse/Collapse.vue";
import TransactionCategorySelect from "../TransactionCategorySelect/TransactionCategorySelect.vue";
import TransactionFrequencySelect from "../TransactionFrequencySelect/TransactionFrequencySelect.vue";
import RecurrenceEditionConfirmationModal from '../RecurrenceEditionConfirmationModal/RecurrenceEditionConfirmationModal.vue';
import FeedbackModal from "@modules/common/components/FeedbackModal/FeedbackModal.vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";

const RECURRENCE_ONLY = 0;
const RECURRENCE_REPEAT = 1;
const RECURRENCE_FIXED = 2;
const FEEDBACK_TRACER = 'FIRST_TRANSACTION';
const CURRENT_EDITION_TYPE = 0;

export default {
  components: {
    Modal,
    CustomInput,
    CurrencyInput,
    ToggleSwitch,
    Collapse,
    TransactionCategorySelect,
    TransactionFrequencySelect,
    RecurrenceEditionConfirmationModal,
    Datepicker
  },
  props: {
    transactionId: String,
    type: [String, Number],
    modalTitle: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      transaction: {
        id: null,
        value: 0,
        description: null,
        categoryId: null,
        transactionDate: getCurrentDate(),
        transactionType: props.type,
        recurrenceType: RECURRENCE_ONLY,
        recurrenceFrequency: null,
        recurrenceAmount: null,
        isFixed: false,
        ignoreTransaction: false,
        wasPaid: false,
        observation: null,
      },
      recurrenceTypes: TRANSACTION_RECURRENCE_TYPES,
      editionType: CURRENT_EDITION_TYPE,
      showRecurrenceConfirmEdition: false,
    });

    onMounted(() => {
      if (props.transactionId) {
        getTransactionById(props.transactionId)
          .then(getTransactionSuccess)
          .catch(getTransactionError);
      }
    });

    const isFormValid = computed({
      get() {
        return state.transaction.value 
          && state.transaction.description 
          && state.transaction.categoryId
          && state.transaction.transactionDate
          && isRecurrenceValid.value;
      },
    });

    const isRequiredRecurrenceFrequency = computed({
      get() {
        return state.transaction.recurrenceType === RECURRENCE_REPEAT 
          || state.transaction.recurrenceType === RECURRENCE_FIXED
      }
    })

    const isRequiredRecurrenceAmount = computed({
      get() {
        return state.transaction.recurrenceType === RECURRENCE_REPEAT
      }
    })

    const isRecurrenceValid = computed({
      get() {
        if (state.transaction.recurrenceType === RECURRENCE_REPEAT) {
          return state.transaction.recurrenceFrequency !== null
            && state.transaction.recurrenceAmount
        }

        if (state.transaction.recurrenceType === RECURRENCE_FIXED) {
          return state.transaction.recurrenceFrequency !== null
        }

        return true;
      }
    })

    const getSituationLabel = computed({
      get() {
        return props.type === TRANSACTION_TYPES.REVENUE.code ? 'Foi recebida' : 'Foi paga'
      }
    })

    const confirmLabel = computed({
      get() {
        return props.transactionId ? 'Editar' : 'Salvar'
      }
    })

    const getTransactionType = computed({
      get() {
        return state.transaction.type >= 0 ?
          state.transaction.type :
          props.type;
      }
    })

    const shouldDisableRepeatTimesField = computed({
      get() {
        return state.transaction.recurrenceType === RECURRENCE_ONLY
          || state.transaction.recurrenceType === RECURRENCE_FIXED;
      }
    })

    const shouldDisableRepeatPeriodField = computed({
      get() {
        return state.transaction.recurrenceType === RECURRENCE_ONLY;
      }
    })

    const shouldDisableRecurrenceFields = computed({
      get() {
        return !!props.transactionId
      }
    })

    const shouldDisableDateField = computed({
      get() {
        return !!props.transactionId && 
          (state.transaction.recurrenceType === RECURRENCE_REPEAT 
          || state.transaction.recurrenceType === RECURRENCE_FIXED)
      }
    })

    const getTransactionSuccess = (data) => {
      state.transaction = { ...data };
    };

    const getTransactionError = () => {
      showErrorToast('Ocorreu um erro ao buscar a transação. Tente novamente.')
    };

    const fetchAction = () => {
      if (!isFormValid.value) {
        return;
      }

      const { id, recurrenceType } = state.transaction;

      if (id && recurrenceType) {
        state.showRecurrenceConfirmEdition = true;
        return;
      }

      return handleSaveTransaction();
    }

    const onConfirmEditRecurrenceTransaction = (editionType) => {
      state.editionType = editionType;

      handleSaveTransaction().then(onSaveTransactionSuccess).catch(onSaveTransactionError);
      state.showRecurrenceConfirmEdition = false;
    }
    const onCancelEditRecurrenceTransaction = () => {
      state.showRecurrenceConfirmEdition = false;
    }

    const handleSaveTransaction = () => {
      const { id } = state.transaction;

      const transactionData = {
        ...state.transaction,
        type: getTransactionType.value,
        recurrenceFrequency: Number(state.transaction.recurrenceFrequency),
      };

      if (id) {
        transactionData.id = id;
        transactionData.updatesSubsequent = state.editionType;

        return updateTransaction(id, { ...transactionData });
      }

      return saveTransaction({ ...transactionData });
    };

    const onSaveTransactionSuccess = () => {
      const { id } = state.transaction;
      showSuccessToast("Transação salva com sucesso!");
      emit('success');
      onClose();

      if (!id && !getTracerByName(FEEDBACK_TRACER)) {
        setTimeout(() => {
          openFeedbackModal();
        }, 600)
      }
    };

    const onSaveTransactionError = () => {
      showErrorToast("Ocorreu um erro ao salvar a transação. Tente novamente.");
    };

    const onChangeCategory = (category) => {
      state.transaction.categoryId = category.id;
    }

    const onChangeSituation = (situation) => {
      state.transaction.wasPaid = situation;
    }

    const onChangeFixed = (isFixed) => {
      state.transaction.isFixed = isFixed;
    }

    const onChangeIgnore = (ignore) => {
      state.transaction.ignoreTransaction = ignore;
    }

    const onChangeFrequency = (frequency) => {
      state.transaction.recurrenceFrequency = frequency;
    }

    const onChangeRecurrenceType = () => {
      state.transaction.recurrenceFrequency = null;
      state.transaction.recurrenceAmount = null;
    }

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const { open: openFeedbackModal, close: closeFeedbackModal } = useModal({
      component: FeedbackModal,
      attrs: {
        tracer: FEEDBACK_TRACER,
        description: 'cadastrar uma nova transação e começar seu controle financeiro.',
        onClose() {
          closeFeedbackModal()
        },
        onConfirm() {
          closeFeedbackModal()
        },
        onCancel() {
          closeFeedbackModal()
        }
      }
    });

    return {
      state,
      getSituationLabel,
      confirmLabel,
      getTransactionType,
      shouldDisableRepeatTimesField,
      shouldDisableRepeatPeriodField,
      shouldDisableRecurrenceFields,
      isRequiredRecurrenceAmount,
      isRequiredRecurrenceFrequency,
      shouldDisableDateField,
      onConfirmEditRecurrenceTransaction,
      onCancelEditRecurrenceTransaction,
      onChangeCategory,
      onChangeSituation,
      onChangeFixed,
      onChangeIgnore,
      onChangeFrequency,
      onChangeRecurrenceType,
      onCancel,
      onClose,
      fetchAction,
      onSaveTransactionSuccess,
      onSaveTransactionError
    };
  },
};
</script>

<style lang="scss" src="./CreateTransactionModal.scss" />
