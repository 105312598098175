import Cookies from 'js-cookie';

export function setCookie(name, value, expiration) {
    Cookies.set(name, value, { expires: expiration })
}

export function getCookie(name) {
    return Cookies.get(name)
}

export function deleteCookie(name) {
    Cookies.remove(name)
}