<template>
    <div class="premium-overlay">
        <a @click="closeOverlay" class="premium-overlay__close"><fa-icon icon="fa-solid fa-xmark" /></a>
        <div class="premium-overlay__content">
            <h1 class="premium-overlay__title">{{ state.data.title }}</h1>
            <img src="../../../../assets/images/illustrations/premium-overlay.webp" class="premium-overlay__img" />
            <p class="premium-overlay__description">{{ state.data.description }}</p>
            <div class="premium-overlay__plan">
                <button @click="goToPlans"
                    class="btn button-primary button-primary--white premium-overlay__buy-plan">Quero ser
                    premium</button>
                <a class="premium-overlay__link-close" @click="closeOverlay">Não, obrigado</a>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from "@/services/eventBusService";
import { reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';

export default {
    props: {
        title: String,
        description: String
    },
    setup(props) {
        const router = useRouter();
        const state = reactive({
            data: {
                title: props.title || 'Esta é uma funcionalidade exclusiva para usuários Premium!',
                description: props.description || 'Adquira nosso plano Premium e obtenha essa e outras vantagens exclusivas.',
            }
        })

        const closeOverlay = () => {
            eventBus.emit('hidePremiumOverlay')
        }

        watch(() => router.currentRoute.value.fullPath, () => {
            eventBus.emit('hidePremiumOverlay')
        })

        const goToPlans = () => {
            sendPixelTrack('PremiumOverlayToPlans')
            closeOverlay();
            router.push("/plans");
        }

        return {
            state,
            closeOverlay,
            goToPlans
        }
    }
}
</script>

<style lang="scss" src="./PremiumOverlay.scss" />
