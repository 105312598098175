<template>
    <data-table :is-loading="isLoading" :show-pagination="false" :columns="transactionsColumns"
        :data="state.transactionsData" hide-filters class="planning-transactions-list" sorted-by="date">
        <template #row="{ row }">
            <planning-transactions-list-row-mobile v-if="isMobileDevice" :transaction-data="row" />
            <template v-else>
                <td id="description">
                    {{ row.description }}
                </td>
                <td id="date">{{ formatDate(row.date) }}</td>
                <td id="value">
                    {{ formatCurrencyValue(Number(row.value)) }}
                </td>
            </template>

        </template>
    </data-table>
</template>

<script>
import { computed, reactive } from "vue";
import { formatCurrency } from "@/services/currencyService";
import { formatFullDate } from "@/services/formatService";
import { isMobile } from "@/services/deviceService";
import {
    PLANNING_TRANSACTIONS_LIST_COLUMNS,
    PLANNING_TRANSACTIONS_LIST_COLUMNS_MOBILE
} from "../../services/planning/planningConstants";
import DataTable from "@/components/DataTable/DataTable.vue";
import PlanningTransactionsListRowMobile from './PlanningTransactionsListRowMobile.vue';


export default {
    components: { DataTable, PlanningTransactionsListRowMobile },
    props: {
        transactions: Array,
        isLoading: Boolean
    },
    setup(props) {
        const transactionsColumns =
            isMobile() ? PLANNING_TRANSACTIONS_LIST_COLUMNS_MOBILE : PLANNING_TRANSACTIONS_LIST_COLUMNS;
        const state = reactive({
            transactionsData: {
                items: [...props.transactions],
                totalItems: 0,
            },
        });

        const formatDate = (date) => {
            return formatFullDate(new Date(date))
        }

        const formatCurrencyValue = (value) => {
            return formatCurrency(value);
        };

        const isMobileDevice = computed({
            get() {
                return isMobile();
            }
        })

        return {
            state,
            transactionsColumns,
            isMobileDevice,
            formatDate,
            formatCurrencyValue
        };
    },
};
</script>

<style lang="scss" src="./PlanningTransactionsList.scss" />