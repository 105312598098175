<template>
    <div class="recovery-password-email-form">
        <p class="recovery-password-email-form__description">Insira seu e-mail abaixo para receber um código de
            verificação</p>
        <div class="recovery-password-email-form__content">
            <form autocomplete="off" method="post" @submit.prevent>
                <custom-input label="E-mail" :value="state.email" v-model="state.email" required name="email" />
                <div class="recovery-password-email-form__actions">
                    <request-button type="submit" class="account-data-form__btn-update" :fetch-action="submitForm"
                        @fetch-success="onGetRecoveryPasswordCodeSuccess" @fetch-error="onGetRecoveryPasswordCodeError"
                        label="Recuperar senha" />
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { getRecoveryPasswordCode } from '../../services/userService';
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { validateRecaptcha } from "@/modules/common/services/recaptcha/recaptchaService";
import CustomInput from '@/components/CustomInput/CustomInput.vue'
import RequestButton from "@/components/RequestButton/RequestButton.vue";

export default {
    components: {
        CustomInput,
        RequestButton
    },
    setup(props, { emit }) {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

        const state = reactive({
            email: null
        })

        const submitForm = () => {
            if (!state.email) {
                return;
            }

            return submitRecaptcha();
        }

        const submitRecaptcha = async () => {
            if (!state.email) {
                return;
            }

            await recaptchaLoaded()
            const recaptchaAction = 'recovery_password';
            const token = await executeRecaptcha(recaptchaAction);
            const isValid = await validateRecaptcha({ token, recaptchaAction });

            if (isValid) {
                return onClickGetCode();
            }

            return Promise.reject();
        }

        const onClickGetCode = () => {
            if (!state.email) {
                return;
            }

            return getRecoveryPasswordCode(state.email);
        }

        const onGetRecoveryPasswordCodeSuccess = () => {
            showSuccessToast('Código enviado com sucesso para o seu e-mail.')
            emit('set-step', { step: 1, email: state.email, code: null })
        }

        const onGetRecoveryPasswordCodeError = () => {
            showErrorToast('Algo deu errado. Tente novamente mais tarde.')
        }

        return {
            state,
            submitForm,
            onGetRecoveryPasswordCodeSuccess,
            onGetRecoveryPasswordCodeError
        }
    }
}
</script>

<style lang="scss" src="./RecoveryPasswordEmailForm.scss" />
