<template>
  <li class="dropdown-item">
    <a v-if="href" @click="onClick" :href="href">{{ label }}</a>
    <span v-else @click="onClick" class="">{{ label }}</span>
  </li>
</template>

<script>
export default {
  props: {
    label: String,
    href: String,
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit("click");
    };

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss" src="./DropdownItem.scss" />
