<template>
  <section class="login-view">
    <div class="login-container">
      <h1 class="login-view__title">Seu controle financeiro em um só lugar!</h1>
      <p class="login-view__subtitle">
        Controle suas receitas e despesas, defina objetivos financeiros e tenha uma visão clara das suas finanças
        tudo em um só lugar. Gerencie seu dinheiro de forma simples e eficiente.
      </p>
    </div>
    <div class="login-box" :class="{ 'register-form': state.componentIs === 'RegisterForm' }">
      <img src="../../../assets/images/logo.png" alt="Calculando Riquezas" class="login-view__logo" />
      <component :is="state.componentIs" @show-login="showLoginForm" />
      <button v-if="state.componentIs === 'LoginForm'" class="login-view__action btn button-outline" @click="changeAccountComponent">
        {{ accountActionText }}
      </button>
      <a class="login-view__return-login" v-else @click="changeAccountComponent">{{ accountActionText }}</a>

      <div class="login-view__login-with">
        <span>ou</span>
      </div>
      <div class="login-view__google-login">
        <GoogleLogin :callback="onLoginGoogleCallback" />
      </div>
      <div class="login-view__recaptcha">
        This site is protected by reCAPTCHA and the Google
        <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a>
        apply.
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onBeforeMount, reactive, watch } from "vue";
import { sendPixelTrack } from '@/modules/common/services/pixelFacebook/pixelFacebookService';
import { googleLogin, setNewUserToken } from "../services/authService";
import { useRouter } from "vue-router";
import { deleteCookie } from "@/services/cookiesService";
import LoginForm from "../components/LoginForm/LoginForm.vue";
import RegisterForm from "../components/RegisterForm/RegisterForm.vue";

export default {
  components: {
    LoginForm,
    RegisterForm,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      componentIs: null,
    });

    onBeforeMount(() => {
      deleteCookie("auth-token");
      deleteCookie("refresh-token");

      const isRegister = router.currentRoute.value.query['REGISTER'];

      if (isRegister) {
        state.componentIs = "RegisterForm"
      } else {
        state.componentIs = "LoginForm"
      }
    })

    watch(() => state.componentIs, () => {
      handlePixelTrack();
    })

    const handlePixelTrack = () => {
      if (state.componentIs === "RegisterForm") {
        sendPixelTrack('RegisterPageView');
        return;
      }

      sendPixelTrack('LoginPageView');
    }

    const accountActionText = computed({
      get() {
        if (state.componentIs === "RegisterForm") {
          return "Já tem uma conta? Fazer login";
        }

        return "Criar conta grátis";
      },
    });

    const changeAccountComponent = () => {
      state.componentIs =
        state.componentIs === "LoginForm" ? "RegisterForm" : "LoginForm";
    };

    const showLoginForm = () => {
      state.componentIs = "LoginForm";
    };

    const onLoginGoogleCallback = (response) => {
      const { clientId, credential } = response;
      googleLogin({ clientId, credential })
        .then(onGoogleLoginSucess)
        .catch(onGoogleLoginError);
    };

    const onGoogleLoginSucess = (data) => {
      if(data.isRegister) {
        sendPixelTrack("GoogleRegisterCompleted");
      }

      setNewUserToken(data);

      router.push("/");
    };
    const onGoogleLoginError = () => {
      // TODO: show backend error
    };

    return {
      state,
      accountActionText,
      showLoginForm,
      changeAccountComponent,
      onLoginGoogleCallback,
    };
  },
};
</script>

<style lang="scss" src="./styles/LoginView.scss" />
