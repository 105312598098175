<template>
    <div class="planning-infos-card">
        <form action="#" @submit.prevent>
            <div class="planning-infos-card__infos">
                <custom-input v-if="shouldShowCustomFields" label="Nome" :value="state.planning.name"
                    v-model="state.planning.name" name="planning-name" required />
                <div class="planning-infos-card__period">
                    <div class="form-group">
                        <label for="from-date">De</label>
                        <datepicker v-model="state.planning.startDate" :value="state.planning.startDate"
                            name="from-date" :max-date="state.planning.endDate" :disabled="!state.planning.customPeriod"
                            required />
                    </div>
                    <div class="form-group">
                        <label for="to-date">Até</label>
                        <datepicker v-model="state.planning.endDate" :value="state.planning.endDate" name="to-date"
                            :min-date="state.planning.startDate" required :disabled="!state.planning.customPeriod" />
                    </div>
                </div>
            </div>
            <div class="planning-infos-card__infos">
                <currency-input label="Total do orçamento (R$)" :value="state.planning.value"
                    v-model="state.planning.value" required name="value" :custom-validations="totalValidations" />
                <currency-input label="Quanto economizar (%)" :precision="0" :value="state.planning.savingPercent"
                    v-model="state.planning.savingPercent" name="saving" placeholder="0"
                    :custom-validations="state.percentValidations" />
            </div>
            <div class="planning-infos-card__result" v-if="showSavingsValue">
                <p>Sua economia no período será:</p>
                <p>{{ savingsValue }}</p>
            </div>
            <div class="planning-infos-card__actions">
                <button class="btn button-outline" @click="onCancel">{{ backButtonLabel }}</button>
                <request-button class="btn button-primary" type="submit" label="Salvar"
                    :fetch-action="savePlanningAction" @fetch-success="onSavePlanningSuccess"
                    @fetch-error="onSavePlanningError" />
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useUserDataStore } from "@/store/userData";
import { savePlanning, updatePlanning, buildPlanningBudgets, planningValueValidator } from '../../services/planning/planningService';
import { showErrorToast, showSuccessToast } from '@/services/toastService';
import { formatCurrency } from '@/services/currencyService';
import { getTracerByName } from "@modules/common/services/tracer/tracerService";
import { useModal } from 'vue-final-modal';
import FeedbackModal from "@modules/common/components/FeedbackModal/FeedbackModal.vue";
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue'
import CustomInput from '@/components/CustomInput/CustomInput.vue';
import Datepicker from '@/modules/common/components/Datepicker/Datepicker.vue';
import RequestButton from '@/components/RequestButton/RequestButton.vue';

const FEEDBACK_TRACER = "FIRST_PLANNING";

export default {
    components: { CurrencyInput, CustomInput, Datepicker, RequestButton },
    props: {
        planningData: Object
    },
    setup(props, { emit }) {
        const userStore = useUserDataStore();
        const router = useRouter();
        const state = reactive({
            planning: {
                name: null,
                value: 0,
                savingPercent: 0,
                startDate: null,
                endDate: null,
                customPeriod: false,
                fixedValue: true,
                recurrenceInfo: {
                    frequency: 0,
                    amount: 0
                },
                budgets: [],
                ...props.planningData
            },
            totalValueValid: true,
            percentValidations: [
                {
                    message: 'O valor deve ser maior igual que 0 e menor igual a 100',
                    valid: (value) => value >= 0 && value <= 100
                }
            ]
        })

        watch(() => props.planningData,
            (value) => {
                state.planning = value
            },
            { deep: true })

        const shouldShowCustomFields = computed({
            get() {
                return userStore.isPremiumUser && state.planning.customPeriod
            }
        })

        const savingsValue = computed({
            get() {
                const { value, savingPercent } = state.planning
                const totalValue = value && savingPercent ? (value * savingPercent) / 100 : 0
                return formatCurrency(totalValue)
            }
        })

        const showSavingsValue = computed({
            get() {
                return Number(state.planning.savingPercent) > 0;
            }
        })

        const totalValidations = computed({
            get() {
                return [
                    {
                        message: "O Total não pode ser inferior a soma dos orçamentos das categorias",
                        valid: (value) => {
                            const isValid = planningValueValidator(state.planning.budgets, value)
                            state.totalValueValid = isValid
                            return isValid
                        },
                    },
                ]
            }
        })

        const backButtonLabel = computed({
            get() {
                const { id } = state.planning
                return id === "new" || !id ? 'Cancelar' : 'Voltar'
            }
        })

        const savePlanningAction = () => {
            if (!state.totalValueValid) {
                return;
            }

            const params = {
                ...state.planning,
                budgets: buildPlanningBudgets(state.planning.budgets)
            }

            if (params.id) {
                return handleUpdatePlanning(params.id, params);
            }

            return handleSavePlanning(params);
        }

        const handleSavePlanning = (params) => {
            savePlanning(params).then(onSavePlanningSuccess).catch(onSavePlanningError)
        }

        const onSavePlanningSuccess = (data) => {
            showSuccessToast('Planejamento criado com sucesso!');
            onPlanningUpdated(data)

            if (!getTracerByName(FEEDBACK_TRACER)) {
                openFeedbackModal();
            }
        }
        const onSavePlanningError = () => {
            showErrorToast('Ocorreu um erro ao criar o planejamento. Tente novamente.')
        }

        const handleUpdatePlanning = (id, params) => {
            updatePlanning(id, params).then(onUpdatePlanningSuccess).catch(onUpdatePlanningError)
        }

        const onUpdatePlanningSuccess = (data) => {
            showSuccessToast('Planejamento editado com sucesso!')
            onPlanningUpdated(data)
        }

        const onUpdatePlanningError = () => {
            showErrorToast('Ocorreu um erro ao editar o planejamento. Tente novamente.')
        }

        const onCancel = () => {
            router.push('/finance/plannings')
        }

        const onPlanningUpdated = (data) => {
            emit('planning-updated', data?.item)
        }

        const { open: openFeedbackModal, close: closeFeedbackModal } = useModal({
            component: FeedbackModal,
            attrs: {
                tracer: FEEDBACK_TRACER,
                description: "cadastrar um novo planejamento financeiro.",
                onClose() {
                    closeFeedbackModal();
                },
                onConfirm() {
                    closeFeedbackModal();
                },
                onCancel() {
                    closeFeedbackModal();
                },
            },
        });

        return {
            state,
            shouldShowCustomFields,
            savingsValue,
            showSavingsValue,
            totalValidations,
            backButtonLabel,
            savePlanningAction,
            onSavePlanningSuccess,
            onSavePlanningError,
            onCancel
        }
    }
}
</script>

<style lang="scss" src="./PlanningInfosCard.scss" />