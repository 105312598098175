<template>
    <Modal title="Novo orçamento" @close="onClose" @cancel="onCancel" @confirm="onConfirm"
        class="create-planning-category-modal" button-submit>
        <template #default>
            <form @submit.prevent>
                <div class="create-planning-category-modal__body">
                <transaction-category-select :options="state.categoriesList" :fetch-on-load="false" required
                    @change-category="onChangeCategory" :value="state.budget.categoryId" />
                <currency-input label="Orçamento" v-model="state.budget.value" :value="state.budget.value" required
                    :custom-validations="state.valueValidations" />
                <div class="create-planning-category-modal__footer">
                    <h4 class="create-planning-category-modal__available-value">
                        Valor disponível
                        <span>{{ toCurrency(totalAvailable) }}</span>
                    </h4>
                </div>
            </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import { reactive, watch, computed } from "vue";
import { formatCurrency } from "@/services/currencyService";
import Modal from "@/components/Modal/Modal.vue";
import TransactionCategorySelect from '../TransactionCategorySelect/TransactionCategorySelect.vue';
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';

export default {
    components: { Modal, TransactionCategorySelect, CurrencyInput },
    props: {
        budgetData: {
            type: Object,
            default: () => {}
        },
        categoryOptions: Array,
        availableValue: Number
    },
    setup(props, { emit }) {
        const state = reactive({
            budget: {
                value: 0,
                categoryId: null,
                ...props.budgetData
            },
            categoriesList: props.categoryOptions,
            valueValidations: [
                {
                    message: "O valor do orçamento deve ser maior que 0",
                    valid: (value) => Number(value) > 0,
                },
                {
                    message: "O orçamento não pode ser maior que o valor total disponível",
                    valid: (value) => Number(value) <= props.availableValue + (props.budgetData?.value || 0)
                },
            ],
        })

        watch(() => props.categoryOptions, (value) => {
            state.categoriesList = value
        })

        watch(() => props.budgetData, (value) => {
            state.budget = value
        })

        const totalAvailable = computed({
            get() {
                const budgetDataValue = props.budgetData?.value || 0;
                return (props.availableValue + budgetDataValue) - state.budget?.value
            }
        })

        const onChangeCategory = (category) => {
            state.budget.categoryId = category.id;
        }

        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const onConfirm = () => {
            const availableValue = props.availableValue && Number(props.availableValue)
            const valueFormatted = Number(state.budget.value)
            if (!state.budget.categoryId || !valueFormatted || valueFormatted > availableValue) {
                return;
            }

            emit("add-budget", {
                ...state.budget,
                value: valueFormatted,
            });
        };

        const toCurrency = (value) => {
            return formatCurrency(value)
        }

        return {
            state,
            totalAvailable,
            onChangeCategory,
            onCancel,
            onClose,
            onConfirm,
            toCurrency
        };
    },
};
</script>

<style lang="scss" src="./CreatePlanningCategoryModal.scss" />