<template>
    <Modal title="Novo planejamento" @close="onClose" @cancel="onCancel" @confirm="onConfirm" confirm-label="Continuar"
        class="create-planning-modal" button-submit>
        <template #default>
            <div class="create-planning-modal__content">
                <loader v-if="state.isLoading" />
                <form v-else @submit.prevent>
                    <toggle-button v-if="!onlyMontlhyPlanning && !onlyCustomPeriod" class="create-planning-modal__type" is-small left-label="Mensal"
                        rightLabel="Personalizado" v-model="state.planning.customPeriod" @change="onChangeType"/>
                    <template v-if="state.defaultData.hasLast && !state.planning.customPeriod">
                        <div class="create-planning-modal__body">
                            <div class="create-planning-modal__copy">
                                <p>Com um clique no botão abaixo, você pode copiar seus orçamentos por categorias do seu
                                    último
                                    planejamento mensal.</p>
                                <request-button @click.prevent.stop label="Copiar planejamento anterior"
                                    :fetch-action="getLastPlanningData" @fetch-success="onGetLastPlanningDataSuccess"
                                    @fetch-error="onGetLastPlanningDataError"
                                    class="btn button-outline"></request-button>
                            </div>
                        </div>
                        <div class="create-planning-modal__manually">
                            <span>ou preencha manualmente</span>
                        </div>
                    </template>
                    <custom-input v-if="state.planning.customPeriod" :value="state.planning.name" label="Nome"
                        v-model="state.planning.name" required name="name" autocomplete="off" maxlength="100" />
                    <div class="create-planning-modal__new-values">
                        <currency-input label="Total do orçamento (R$)" :value="state.planning.value"
                            v-model="state.planning.value" required name="value" />
                        <currency-input label="Quanto economizar (%)" :precision="0"
                            :value="state.planning.savingPercent" v-model="state.planning.savingPercent" name="value"
                            placeholder="0" :custom-validations="state.percentValidations"/>
                    </div>
                    <div v-if="state.planning.customPeriod" class="create-planning-modal__new-values">
                        <div class="form-group">
                            <label for="from-date">De</label>
                            <datepicker v-model="state.planning.startDate" :value="state.planning.startDate" required
                                name="from-date" :max-date="state.planning.endDate" />
                        </div>
                        <div class="form-group">
                            <label for="to-date">Até</label>
                            <datepicker v-model="state.planning.endDate" :value="state.planning.endDate" required
                                name="to-date" :min-date="state.planning.startDate" />
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </Modal>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import { getDefaultPlanning, getLastPlanning } from "../../services/planning/planningService";
import { usePlanningStore } from "@/store/planningData";
import { showErrorToast } from "@/services/toastService";
import { useUserDataStore } from "@/store/userData";
import Modal from "@/components/Modal/Modal.vue";
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput.vue';
import RequestButton from "@/components/RequestButton/RequestButton.vue";
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import Loader from '@/components/Loader/Loader.vue';
import ToggleButton from '@/components/ToggleButton/ToggleButton.vue';

export default {
    components: { Modal, CurrencyInput, RequestButton, Datepicker, CustomInput, Loader, ToggleButton },
    props: {
        onlyCustomPeriod: Boolean
    },
    setup(props, { emit }) {
        const userStore = useUserDataStore();
        const planningStore = usePlanningStore();
        const state = reactive({
            isLoading: true,
            planning: {
                name: null,
                value: 0,
                savingPercent: 0,
                startDate: null,
                endDate: null,
                customPeriod: props.onlyCustomPeriod,
                fixedValue: true,
                budgets: []
            },
            defaultData: {},
            percentValidations: [
                {
                    message: 'O valor deve ser maior igual que 0 e menor igual a 100',
                    valid: (value) => value >= 0 && value <= 100
                }
            ]
        });

        onMounted(() => {
            const { startDate, endDate } = planningStore.getPlanningData;

            state.planning.name = props.onlyCustomPeriod ? '' : 'Mensal'
            state.planning.startDate = startDate;
            state.planning.endDate = endDate;
            getDefaultPlanning({ startDate }).then(onGetDefaultPlanningSuccess).catch(onGetDefaultPlanningError)
        })

        const isFormValid = computed({
            get() {
                return state.planning.name && Number(state.planning.value) > 0 && state.planning.startDate && state.planning.endDate
            }
        })

        const onlyMontlhyPlanning = computed({
            get() {
                return !userStore.isPremiumUser
            }
        })

        const onGetDefaultPlanningSuccess = (data) => {
            state.defaultData = data;

            if (data.value) {
                state.planning.value = data.value;
            }

            state.isLoading = false;
        }

        const onGetDefaultPlanningError = () => {
            state.isLoading = false;
        }

        const getLastPlanningData = () => {
            state.isLoading = true;
            getLastPlanning(state.defaultData.lastPlanningId)
                .then(onGetLastPlanningDataSuccess)
                .catch(onGetLastPlanningDataError)
        }

        const onGetLastPlanningDataSuccess = (data) => {
            state.planning = {
                ...state.planning,
                ...data
            }
            createPlanning();
        }

        const onGetLastPlanningDataError = () => {
            showErrorToast("Ocorreu um erro ao solicitar dados do planejamento anterior. Tente novamente.")
            state.isLoading = false;
        }

        const onClose = () => {
            emit("close");
        };

        const onCancel = () => {
            emit("cancel");
        };

        const onConfirm = () => {
            if (!isFormValid.value) {
                return;
            }

            createPlanning();
        };

        const onChangeType = () => {
            const { startDate, endDate } = planningStore.getPlanningData;

            state.planning.startDate = startDate;
            state.planning.endDate = endDate;

            state.planning.name = state.planning.customPeriod ? '' : 'Mensal';
        }

        const createPlanning = () => {
            emit("create", { ...state.planning });
        }

        return {
            state,
            onlyMontlhyPlanning,
            onCancel,
            onClose,
            onConfirm,
            getLastPlanningData,
            onGetLastPlanningDataSuccess,
            onGetLastPlanningDataError,
            onChangeType
        };
    },
};
</script>

<style lang="scss" src="./CreatePlanningModal.scss" />