<template>
    <div class="side-menu-dropdown">
        <side-menu-item
        class="side-menu-dropdown__label"
        :class="{'open': state.showList}"
        :route-config="routeConfig"
        @click="onToggleDropdown"
        :show-label="state.showText">
            <fa-icon class="icon-toggle" icon="fa-solid fa-chevron-down" />
        </side-menu-item>
        <ul v-if="state.showList" class="side-menu-dropdown__list">
            <li v-for="route in routeConfig.child" :key="route.href">
                <side-menu-item v-if="route.href" :route-config="route" :show-label="state.showText" @click-menu="onClick"/>
                <side-menu-dropdown v-else :route-config="route" :show-label="state.showText" @click-menu="onClick"/>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, watch } from 'vue';
import SideMenuItem from './SideMenuItem.vue';

export default {
    components: { SideMenuItem },
    props: {
        routeConfig: Object,
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            showText: props.showLabel,
            showList: false,
        })

        watch(() => props.showLabel,
            (value) => {
                state.showText = value
            })

        const onClick = () => {
            emit('click-menu')
        }

        const onToggleDropdown = () => {
            state.showList = !state.showList;
        }

        return {
            state,
            onClick,
            onToggleDropdown
        }
    }
}
</script>

<style lang="scss" src="./SideMenuDropdown.scss" />
