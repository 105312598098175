export function formatCurrency(value) {
    if (typeof value !== 'number') {
        return value;
    }

    const formatter = new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL'
    });

    let formattedValue = formatter.format(Math.abs(value));
    if (value < 0) {
        formattedValue = `R$ -${formattedValue.slice(3)}`;
    }

    return formattedValue;
}

export function formatNumberToK(number) {
    const absNumber = Math.abs(number);

    if (absNumber >= 1000) {
        const formatted = (absNumber / 1000).toFixed(1);
        const result = formatted.endsWith('.0') ? formatted.slice(0, -2) + 'k' : formatted + 'k';

        return number < 0 ? '-' + result : result;
    } else {
        const result = (absNumber / 1000).toFixed(1).substring(1)
        return number < 0 ? '-0' + result + 'k' : '0' + result + 'k';
    }
}