import requestService from '@services/requestService'

const PLANNINGS_URL = '/planning'

export function getPlanningList(params) {
    return requestService.get(PLANNINGS_URL, { params });
}

export function getPlanningById(id) {
    return requestService.get(`${PLANNINGS_URL}/${id}`);
}

export function getLastPlanning(id) {
    return requestService.get(`${PLANNINGS_URL}/last/${id}`);
}

export function savePlanning(params) {
    return requestService.post(`${PLANNINGS_URL}`, params);
}

export function updatePlanning(id, params) {
    return requestService.put(`${PLANNINGS_URL}/${id}`, params);
}

export function removePlanning(id) {
    return requestService.post(`${PLANNINGS_URL}/delete/${id}`);
}

export function getDefaultPlanning(params) {
    return requestService.get(`${PLANNINGS_URL}/initial`, { params });
}

export function getBudgetTransactions(id) {
    return requestService.get(`${PLANNINGS_URL}/budget/transactions/${id}`);
}

