<template>
    <div class="goal-transaction-type-badge">
        <badge :value="getBadgeProps.text" :type="getBadgeProps.type" />
    </div>
</template>

<script>
import { computed } from 'vue';
import { GOAL_TRANSACTION_TYPE } from '../../constants/goalConstants';
import Badge from '@/components/Badge/Badge.vue'

export default {
    components: { Badge },
    props: {
        type: Number
    },
    setup(props) {
        const getBadgeProps = computed({
            get() {
                if (props.type === GOAL_TRANSACTION_TYPE.DEPOSIT.code) {
                    return {
                        text: GOAL_TRANSACTION_TYPE.DEPOSIT.name,
                        type: 'success'
                    }
                }

                return {
                    text: GOAL_TRANSACTION_TYPE.WITHDRAWAL.name,
                    type: 'alert'
                }
            }
        })

        return {
            getBadgeProps
        }
    }
}
</script>

