<template>
    <div class="select-icon-modal form-group"
        :class="{ 'select-icon-modal--disabled': state.isDisabled, 'select-icon-modal--required': required }">
        <label for="select-icon-modal">
            {{ label }}
        </label>
        <div class="select-icon-modal__selected-icon" ref="selectRef" >
            <fa-icon class="select-icon-modal__svg-selected" @click="toggleDropdown"
                :icon="`solid ${state.selectedOption}`"></fa-icon>
            <div class="select-icon-modal__modal options" v-if="state.isDropdownOpen" ref="selectOptionsRef"
                :style="floatingStyles">
                <h4 class="select-icon-modal__modal-title">Selecione um ícone</h4>
                <fa-icon class="select-icon-modal__icon" v-for="icon in iconsList" :icon="`fa-solid ${icon}`"
                    :key="icon" @click="selectIcon(icon)"></fa-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useFloating, autoUpdate } from '@floating-ui/vue';
import { size, autoPlacement } from '@floating-ui/dom';
import { ICONS_OPTIONS } from "@/modules/common/services/icons/iconsService";

export default {
    props: {
        label: {
            type: String,
            default: 'Ícone'
        },
        icon: String,
        required: Boolean,
        disabled: Boolean
    },
    setup(props, { emit }) {
        const iconsList = ICONS_OPTIONS;

        const state = reactive({
            isDropdownOpen: false,
            selectedOption: props.icon || 'fa-house',
            isDisabled: props.disabled
        });

        watch(
            () => props.disabled,
            (value) => {
                state.isDisabled = value
            }
        );

        const selectRef = ref(null);
        const selectOptionsRef = ref(null);
        onClickOutside(selectRef, () => close());

        const { floatingStyles } = useFloating(selectRef, selectOptionsRef, {
            strategy: 'fixed',
            whileElementsMounted: autoUpdate,
            placement: 'bottom',
            transform: true,
            middleware: [
                autoPlacement({
                    allowedPlacements: ['bottom', 'top']
                }),
                size({
                    apply({ rects, elements }) {
                        Object.assign(elements.floating.style, {
                            width: `${rects.reference.width}px`,
                        });
                    },
                })
            ]
        });

        const close = () => {
            state.isDropdownOpen = false;
        };

        const toggleDropdown = () => {
            if (state.isDisabled) {
                return;
            }

            state.isDropdownOpen = !state.isDropdownOpen;
        };

        const selectIcon = (icon) => {
            state.selectedOption = icon
            emit('select-icon', icon);
            close();
        };

        return {
            state,
            selectRef,
            selectOptionsRef,
            floatingStyles,
            iconsList,
            toggleDropdown,
            selectIcon
        };
    },
};
</script>

<style lang="scss" src="./SelectIconModal.scss" />