<template>
  <div class="goals-view">
    <page-header title="Objetivos financeiros">
      <div v-tooltip="createActionTooltip">
        <button :disabled="state.limitReached" @click="openGoalModal" class="btn button-primary">
          {{ addButtonLabel }}
        </button>
      </div>
    </page-header>
    <div class="goals-view__content">
      <box-page>
        <loader v-if="state.isLoading" />
        <goals-list v-else :goals-data="state.goalsData" @refresh-data="getGoalsList" />
      </box-page>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from "vue";
import { useModal } from "vue-final-modal";
import { getGoals } from "../services/goal/goalService";
import { showErrorToast } from "@/services/toastService";
import { getTracerByName } from "@modules/common/services/tracer/tracerService";
import { isMobile } from "@/services/deviceService";
import BoxPage from "@/components/BoxPage/BoxPage.vue";
import PageHeader from "@/components/PageHeader/PageHeader.vue";
import GoalsList from '../components/GoalsList/GoalsList.vue';
import CreateGoalModal from "../components/CreateGoalModal/CreateGoalModal.vue";
import Loader from "@/components/Loader/Loader.vue";
import FeedbackModal from "@modules/common/components/FeedbackModal/FeedbackModal.vue";

const FEEDBACK_TRACER = "FIRST_GOAL";

export default {
  components: { BoxPage, PageHeader, GoalsList, Loader },
  setup() {
    const state = reactive({
      isLoading: true,
      goalsData: [],
      limitReached: false
    })

    onMounted(() => {
      getGoalsList();
    })

    const createActionTooltip = computed({
      get() {
        return state.limitReached ? 'Você atingiu o limite de criação de objetivos da sua conta gratuita.' : ''
      }
    })

    const addButtonLabel = computed(() => {
      return isMobile() ? "Novo" : "Novo objetivo"
    })

    const getGoalsList = () => {
      state.isLoading = true;
      getGoals().then(onGetGoalsSuccess).catch(onGetGoalsError)
    }

    const onGetGoalsSuccess = (data) => {
      state.goalsData = data.items;
      state.limitReached = data.limitReached;
      state.isLoading = false;
    }

    const onGetGoalsError = () => {
      state.isLoading = false;
      showErrorToast('Ocorreu um erro ao buscar os objetivos. Tente novamente.')
    }

    const { open: openGoalModal, close: closeGoalModal } = useModal({
      component: CreateGoalModal,
      attrs: {
        title: "Novo objetivo",
        onClose() {
          closeGoalModal();
        },
        onConfirm() {
          closeGoalModal();
        },
        onSuccess() {
          getGoalsList();
          if (!getTracerByName(FEEDBACK_TRACER)) {
            setTimeout(() => {
              openFeedbackModal();
            }, 600);
          }
        },
        onCancel() {
          closeGoalModal();
        },
      },
    });

    const { open: openFeedbackModal, close: closeFeedbackModal } = useModal({
      component: FeedbackModal,
      attrs: {
        tracer: FEEDBACK_TRACER,
        description: "cadastrar um novo objetivo financeiro.",
        onClose() {
          closeFeedbackModal();
        },
        onConfirm() {
          closeFeedbackModal();
        },
        onCancel() {
          closeFeedbackModal();
        },
      },
    });

    return {
      state,
      addButtonLabel,
      createActionTooltip,
      openGoalModal,
      getGoalsList
    }
  }
}
</script>

<style lang="scss" src="./styles/GoalsView.scss" />
