import * as tracerResource from './tracerResource'
import { useUserTracerStore } from "@/store/userTracer";
import { storeToRefs } from 'pinia'

async function getUserTracers() {
    const { data } = await tracerResource.getUserTracers();

    return data;
}

export function getTracerByName(name) {
    const userTracer = useUserTracerStore()
    const { getTracer } = storeToRefs(userTracer)

    return getTracer.value(name);
}

export function getUserTracersList() {
    const userTracer = useUserTracerStore()

    getUserTracers().then((tracers) => {
        userTracer.setTracers(tracers)
    })
}