<template>
  <Modal title="Termos de uso e políticas de privacidade" confirm-label="Entendi" cancel-label="Fechar"
    :with-cancel-button="false" @close="onClose" @confirm="onConfirm">
    <template #default>
      <div class="use-terms-modal">
        <privacy-policy />
        <use-terms />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal/Modal.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import UseTerms from "./UseTerms.vue";

export default {
  components: { Modal, PrivacyPolicy, UseTerms },
  setup(props, { emit }) {

    const onClose = () => {
      emit("close");
    };

    const onConfirm = () => {
      emit("confirm");
    };

    return {
      onConfirm,
      onClose,
    };
  },
};
</script>

<style lang="scss" src="./UseTermsModal.scss" />
