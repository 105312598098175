export function getLocalStorageItem(key) {
    const item = localStorage.getItem(key);
    const numPatt = new RegExp(/^\d+$/);
    const jsonPatt = new RegExp(/[[{].*[}\]]/);

    if (item) {
        if (jsonPatt.test(item)) {
            return JSON.parse(item);
        }
        else if (numPatt.test(item)) {
            return parseFloat(item);
        }
        else {
            return item;
        }
    }
    else {
        return null;
    }
}

export function setLocalStorageItem(key, value) {
    if (typeof value === "object") {
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.setItem(key, value);
    }
}

export function removeLocalStorageItem(key) {
    localStorage.removeItem(key)
}