<template>
  <div class="planning-budget-goal">
    <div class="planning-budget-goal__budget">
      <div class="planning-budget-goal__budget-values">
        <div>
          <p>Total gasto</p>
          <h4>
            <toggle-currency-visibility :value="state.data.currentValue"/>
          </h4>
        </div>
        <div class="text-end">
          <p>Total planejado</p>
          <h4>
            <toggle-currency-visibility :value="state.data.value"/>
          </h4>
        </div>
      </div>
      <div class="planning-budget-goal__budget-progress">
        <progress-bar :current-value="state.data.currentValue" :total-value="state.data.value" />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/modules/common/components/ProgressBar/ProgressBar.vue';
import { formatCurrency } from '@/services/currencyService';
import { reactive, watch } from 'vue';
import ToggleCurrencyVisibility from '@/modules/common/components/ToggleCurrencyVisibility/ToggleCurrencyVisibility.vue';

export default {
    components: { ProgressBar, ToggleCurrencyVisibility },
    props: {
      budgetData: Object
    },
    setup(props) {
      const state = reactive({
        data: {
          currentValue: 0,
          value: 0,
          ...props.budgetData
        }
      });

      watch(() => props.budgetData, (value) => state.data = value)

      const toCurrency = value => {
        return formatCurrency(Number(value))
      }

      return { state, toCurrency }
    }
}
</script>

<style lang="scss" src="./PlanningBudgetGoal.scss" />
