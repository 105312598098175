<template>
    <div ref="childrenModal" v-if="state.showModal" class="children-modal">
        <div class="children-modal__header">
            <p class="children-modal__title">{{ title }}</p>
        </div>
        <div class="children-modal__content">
            <slot></slot>
        </div>
        <div class="children-modal__footer" v-if="showFooter">
            <button @click="onCancel" class="btn button-outline">
                {{ cancelLabel }}
            </button>
            <button @click="onConfirm" class="btn button-primary">
                {{ confirmLabel }}
            </button>
        </div>
    </div>
</template>

<script>
import { reactive, watch, ref } from 'vue';
import { onClickOutside } from "@vueuse/core";

export default {
    props: {
        title: String,
        show: Boolean,
        confirmLabel: {
            type: String,
            default: "Confirmar",
        },
        cancelLabel: {
            type: String,
            default: "Cancelar",
        },
        ignoreClick: Array,
        isClosable: Boolean,
        showFooter: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const state = reactive({
            showModal: props.show,
            enableToClose: true
        })

        const childrenModal = ref(null);
        onClickOutside(childrenModal, () => { close() }, { ignore: props.ignoreClick });

        watch(() => props.show, (value) => { state.showModal = value; });
        watch(() => props.isClosable, (value) => { state.enableToClose = value; });

        const close = () => {
            if (!state.enableToClose) {
                return;
            }
            
            state.showModal = false;
            emit('close')
        }

        const onCancel = () => {
            close()
        }

        const onConfirm = () => {
            emit('confirm');
            close();
        }

        return {
            state,
            childrenModal,
            onCancel,
            onConfirm,
        }
    }
}
</script>

<style lang="scss" src="./ChildrenModal.scss" />
