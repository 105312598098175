export const MOBILE_COLUMNS = Object.freeze([
    {
        name: "Nome",
        key: "name",
        sortable: true,
    },
    {
        name: "Detalhes",
        key: "icon",
        sortable: false,
    }
]);

export const DESKTOP_COLUMNS = Object.freeze([
    {
        name: "Nome",
        key: "name",
        sortable: true,
    },
    {
        name: "Tipo",
        key: "type",
        sortable: true,
    },
    {
        name: "Ícone",
        key: "icon",
        sortable: false,
    },
    {
        name: "Cor",
        key: "color",
        sortable: false,
    },
    {
        name: "",
        key: "actions",
        sortable: false,
    },
]);
