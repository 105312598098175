<template>
  <loader v-if="state.isLoading" />
  <div v-else class="cards-resume cards-resume-revenues">
    <CardResume title="Receitas pendentes" type="up" icon="fa-plus" :value="state.transactionsData.pendingRevenues"/>
    <CardResume title="Receitas recebidas" type="up" icon="fa-plus" :value="state.transactionsData.revenues"/>
    <CardResume title="Total do período" type="up" icon="fa-plus" :value="state.transactionsData.monthlyBalance"/>
  </div>
</template>

<script>
import { onMounted, reactive, watch, onUnmounted } from 'vue';
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import { getTransactionsSimpleBalance } from "../../services/transactionsService";
import { TRANSACTION_TYPES } from '../../constants/transactionConstants';
import { eventBus } from "@/services/eventBusService";
import CardResume from "../CardResume/CardResume.vue";
import Loader from '@/components/Loader/Loader.vue';

export default {
  components: { CardResume, Loader },
  setup() {
    const filtersStore = useTransactionsFiltersStore();

    const state = reactive({
      isLoading: true,
      transactionsData: {
        revenues: null,
        pendingRevenues: null,
        monthlyBalance: null
      }
    })

    const reloadDataHandler = () => {
      fetchData()
    }

    onMounted(() => {
      eventBus.on('reloadData', reloadDataHandler)
      fetchData()
    });

    onUnmounted(() => {
      eventBus.off('reloadData', reloadDataHandler)
    })

    const fetchData = () => {
      const filters = {
        ...filtersStore.getTransactionsFilters,
        Type: TRANSACTION_TYPES.REVENUE.code
      };

      state.isLoading = true;
      getTransactionsSimpleBalance(filters)
        .then(fetchDataSuccess)
        .catch(fetchDataError)
    }

    const fetchDataSuccess = (balance) => {
      state.transactionsData = { ...balance }

      state.isLoading = false;
    }

    const fetchDataError = () => {
      state.isLoading = false;
    }

    watch(() => filtersStore.getTransactionsFilters, () => fetchData(), { deep: true });

    return {
      state
    }
  }
};
</script>

