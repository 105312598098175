<template>
    <div v-tooltip="tooltip" class="input-file form-group" 
    :class="[disabled ? 'input-file__disabled' : '']">
        <label class="input-file__label" :for="name">{{ inputLabel }}
            <span v-if="required" class="input-file__required">*</span>
        </label>
        <input class="input-file__input" type="file" :id="name" :name="name" :required="required" :disabled="disabled"
            :accept="acceptFiles" @change="onChange">
    </div>
</template>
  
<script>
import { reactive, computed } from 'vue';

export default {
    props: {
        label: {
            type: String,
            default: "Selecionar arquivo"
        },
        name: String,
        required: Boolean,
        disabled: Boolean,
        customValidations: Array,
        acceptFiles: String,
        tooltip: String
    },
    setup(props, { emit }) {
        const state = reactive({
            fileName: null
        })

        const onChange = (event) => {
            state.fileName = event.target.files[0]?.name;
            emit('on-change', event.target.files[0]);
        };

        const inputLabel = computed({
            get() {
                return state.fileName ? state.fileName : props.label
            }
        })

        return {
            inputLabel,
            onChange,
        };
    },
};
</script>
  
<style lang="scss" src="./InputFile.scss" />
