<template>
    <div class="filters-badge-list">
        <Badge v-if="getPeriodFilter()" :value="getPeriodFilter()" />
        <Badge v-if="getCategoryFilter()" :value="getCategoryFilter()" :remove-callback="resetCategoryFilter"/>
        <Badge v-if="getTextualFilter()" :value="getTextualFilter()" :remove-callback="resetSearchTermFilter"/>
    </div>
</template>

<script>
import { useTransactionsFiltersStore } from "@/store/transactionsFilters";
import Badge from '@/components/Badge/Badge.vue';

export default {
    components: { Badge },
    setup() {
        const filtersStore = useTransactionsFiltersStore();

        const getPeriodFilter = () => {
            return filtersStore.getPeriodFormatted;
        }

        const getCategoryFilter = () => {
            return filtersStore.getCategoryFilter?.name;
        }

        const getTextualFilter = () => {
            return filtersStore.getTextualFilter;
        }

        const resetCategoryFilter = () => {
            return filtersStore.resetCategoryFilter();
        }

        const resetSearchTermFilter = () => {
            return filtersStore.resetSearchTermFilter();
        }

        return {
            getPeriodFilter,
            getCategoryFilter,
            getTextualFilter,
            resetCategoryFilter,
            resetSearchTermFilter
        }
    }
}
</script>

<style lang="scss" src="./FiltersBadgeList.scss" />
