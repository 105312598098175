<template>
  <custom-select class="transaction-type-select" :label="label" :options="state.typeOptions" @change="onChange" :value="state.typeSelected" />
</template>

<script>
import CustomSelect from "@/components/CustomSelect/CustomSelect.vue";
import { onMounted, reactive, watch } from "vue";
import { TRANSACTION_TYPES_SELECT_ALL, TRANSACTION_TYPES_SELECT } from '../../constants/transactionConstants';

export default {
  components: { CustomSelect },
  props: {
    label: {
      type: String,
      default: "Tipo"
    },
    value: [Number, String],
    showAll: Boolean
  },
  setup(props, { emit }) {
    const state = reactive({
      typeOptions: props.showAll ? TRANSACTION_TYPES_SELECT_ALL : TRANSACTION_TYPES_SELECT,
      typeSelected: props.value
    });

    onMounted(() => {
      const value = props.value
      if(value !== null && value !== undefined) {
        state.typeSelected = value
        return;
      }

      state.typeSelected = props.showAll ? 'ALL' : props.value
    })

    watch(
      () => props.value,
      (value) => {
        state.typeSelected = props.showAll && value === null ? 'ALL' : props.value;
      }
    );

    const onChange = (option) => {
      const type = state.typeOptions.find(type => type.value === option);
      state.typeSelected = type?.value;
      const valueToEmmit = type?.value === "ALL" ? null : type.value;
      emit("change-type", valueToEmmit);
    };

    return {
      state,
      onChange,
    };
  },
};
</script>
