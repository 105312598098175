<template>
  <Modal :title="title" class="create-goal-modal" confirm-label="Criar objetivo" :fetch-action="handleSaveGoal"
    @fetch-success="onSaveGoalSuccess" @fetch-error="onSaveGoalError" @close="onClose" @cancel="onCancel"
    :errorTitle="state.errorData.title"
    :errorMessages="state.errorData.messages" button-submit>
    <template #default>
      <form action="#" @submit.prevent>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col">
                  <custom-input :value="state.goal.name" label="Nome" v-model="state.goal.name" required name="name"
                    autocomplete="off" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <currency-input label="Valor inicial (R$)" :value="state.goal.initialValue"
                    v-model="state.goal.initialValue" name="initialValue" />
                </div>
                <div class="col-lg-6">
                  <currency-input label="Total do objetivo (R$)" :value="state.goal.value"
                    v-model="state.goal.value" required name="value" :message-error="state.goalValueError" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <label for="data-input">Data final</label>
                    <datepicker v-model="state.goal.date" :value="state.goal.date" name="data-input"/>
                  </div>
                </div>
                <div class="col-lg-3 col-6">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="color-picker">Cor</label>
                      <color-picker name="color-picker" :color="state.goal.color" v-model="state.goal.color"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-6">
                  <select-icon-modal @select-icon="onSelectIcon" :icon="state.goal.icon"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive, computed } from "vue";
import { showErrorToast, showSuccessToast } from "@/services/toastService";
import { saveGoal } from "../../services/goal/goalService";
import { getCurrentDate } from "@/modules/common/services/date/dateService";
import Modal from "@/components/Modal/Modal.vue";
import CustomInput from "@/components/CustomInput/CustomInput.vue";
import CurrencyInput from "@/components/CurrencyInput/CurrencyInput.vue";
import SelectIconModal from '@/components/SelectIconModal/SelectIconModal.vue';
import Datepicker from "@/modules/common/components/Datepicker/Datepicker.vue";
import ColorPicker from "@/components/ColorPicker/ColorPicker.vue";

export default {
  components: { Modal, CustomInput, CurrencyInput, SelectIconModal, Datepicker, ColorPicker },
  props: {
    title: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      goal: {
        name: null,
        initialValue: 0,
        value: 0,
        date: getCurrentDate(),
        icon: "fa-house",
        color: "#705cf2",
      },
      goalValueError: null,
      errorData: {
        title: 'Ocorreu um erro ao criar o objetivo',
        messages: []
    }
    });

    const isFormValid = computed({
      get() {
        return state.goal.name && state.goal.value > 0 && state.goal.date;
      },
    });

    const handleSaveGoal = () => {
      if (!isFormValid.value) {
        if(state.goal.value <= 0) {
          state.goalValueError = "O valor total do objetivo deve ser maior que 0."
        }
        
        return;
      }

      state.errorData.messages = []

      state.goalValueError = null;

      return saveGoal(state.goal);
    };

    const onSaveGoalSuccess = () => {
      showSuccessToast(`Objetivo criado com sucesso!`);
      emit("success");
      onClose();
    };

    const onSaveGoalError = (error) => {
      state.errorData.messages = [...error.errors]
      showErrorToast("Ocorreu um erro ao salvar o objetivo. Tente novamente.");
    };

    const onClose = () => {
      emit("close");
    };

    const onCancel = () => {
      emit("cancel");
    };

    const onSelectIcon = (icon) => {
      state.goal.icon = icon;
    };

    return {
      state,
      isFormValid,
      onCancel,
      onClose,
      onSelectIcon,
      handleSaveGoal,
      onSaveGoalSuccess,
      onSaveGoalError,
    };
  },
};
</script>

<style lang="scss" src="./CreateGoalModal.scss" />
