import { getCookie } from '@/services/cookiesService';
import { refreshLogin, getIsAuthenticated } from "@/modules/user/services/authService";
import { reactive } from 'vue';

const state = reactive({
  userRole: null,
  initialCheckDone: false,
});

export const checkAuthentication = async () => {
  if (getIsAuthenticated()) {
    return true;
  }

  const refreshToken = getCookie('refresh-token');

  if (!refreshToken) {
    return false;
  }

  try {
    await refreshLogin();
    return true;
  } catch {
    return false;
  }
};

export const checkPermission = (requiredRole) => {
  const userRole = state.userRole;

  return userRole && userRole === requiredRole;
};

