<template>
  <div class="plans-details-table">
    <div class="plans-details-table__items">
      <div class="plans-details-table__items-header">
        <div class="plans-details-table__items-description" />
        <div class="plans-details-table__plan-name">
          <div class="plan-card-table">
            <h3 class="plan-card-table__title">
              Free
            </h3>
            <p class="plan-card-table__description">
              Plano gratuito
            </p>
          </div>
        </div>
        <div class="plans-details-table__plan-name plans-details-table__plan-name--premium">
          <div class="plan-card-table">
            <h3 class="plan-card-table__title">
              Premium
            </h3>
            <p class="plan-card-table__description">
              Funcionalidades ilimitadas
            </p>
          </div>
        </div>
      </div>
      <div v-for="line in plansDetails" :key="line.name" class="plans-details-table__line">
        <div class="plans-details-table__item plans-details-table__item-name">
          {{ line.name }}
        </div>
        <div class="plans-details-table__item plans-details-table__item-free">
          <plan-include-icon v-if="!line.free" :included="line.includedFree" />
          {{ line.free }}
        </div>
        <div class="plans-details-table__item plans-details-table__item-premium">
          <plan-include-icon v-if="!line.premium" :included="line.includedPremium" />
          {{ line.premium }}
        </div>
      </div>
      <div class="plans-details-table__footer">
        <div class="plans-details-table__footer-name" />
        <div class="plans-details-table__footer-free" />
        <div class="plans-details-table__footer-premium" />
      </div>
    </div>
  </div>
</template>

<script>
import { PLANS_DETAILS } from '../constants/plansDetailsConstants'
import PlanIncludeIcon from './PlanIncludeIcon.vue'

export default {
  components: {
    PlanIncludeIcon,
  },
  data () {
    return {
      plansDetails: PLANS_DETAILS
    }
  }
}
</script>

<style lang="scss" src="./PlansDetailsTable.scss" />
