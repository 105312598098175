<template>
    <button @click="onClick" class="request-button btn button-primary"
        :class="{ 'request-button--loading': state.isLoading }" :disabled="isDisabled">
        {{ labelFormatted }}
        <div v-if="state.isLoading" class="request-button__loader">
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                <radialGradient id='a11' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'>
                    <stop offset='0' stop-color='#FFFFFF'></stop>
                    <stop offset='.3' stop-color='#FFFFFF' stop-opacity='.9'></stop>
                    <stop offset='.6' stop-color='#FFFFFF' stop-opacity='.6'></stop>
                    <stop offset='.8' stop-color='#FFFFFF' stop-opacity='.3'></stop>
                    <stop offset='1' stop-color='#FFFFFF' stop-opacity='0'></stop>
                </radialGradient>
                <circle transform-origin='center' fill='none' stroke='url(#a11)' stroke-width='15' stroke-linecap='round'
                    stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70'>
                    <animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='2' values='360;0'
                        keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'></animateTransform>
                </circle>
                <circle transform-origin='center' fill='none' opacity='.2' stroke='#FFFFFF' stroke-width='15'
                    stroke-linecap='round' cx='100' cy='100' r='70'></circle>
            </svg>
        </div>
    </button>
</template>

<script>
import { computed, reactive } from 'vue';

export default {
    props: {
        label: String,
        fetchAction: Function,
        disabled: Boolean
    },
    setup(props, { emit }) {
        const state = reactive({
            isLoading: false
        });

        const labelFormatted = computed({
            get() {
                return state.isLoading ? '' : props.label
            }
        })

        const isDisabled = computed({
            get() {
                return state.isLoading || props.disabled
            }
        })

        const onClick = () => {
            if (!props.fetchAction || state.isLoading) {
                return;
            }

            const fetchPromise = props.fetchAction();

            if (!(fetchPromise instanceof Promise)) {
                state.isLoading = false;
                return fetchPromise;
            }

            state.isLoading = true;
            fetchPromise.then(onFetchSuccess).catch(onFetchError);
        }

        const onFetchSuccess = (data) => {
            emit('fetch-success', data);
            state.isLoading = false;
        }

        const onFetchError = (data) => {
            emit('fetch-error', data);
            state.isLoading = false;
        }

        return {
            state,
            labelFormatted,
            isDisabled,
            onClick
        }
    }
}
</script>

<style lang="scss" src="./RequestButton.scss" />
